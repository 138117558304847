import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Container, Link, Theme, Typography } from "@mui/material";
import { colors } from "theme/theme";
import useTranslation from "translations/useTranslation";

const Styles = withTheme(styled.div`
  .header-title {
    text-align: start;
    margin-bottom: 0;
  }

  .map-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};

    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    width: 60%;
    color: #7d7986;
  }

  .map-link {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    color: ${colors.secondary};
    text-decoration: underline rgb(241, 191, 223) 2px;
    text-underline-position: under;

    &:hover {
      text-decoration: none;
    }
  }
`);

const Map = withTheme(styled.iframe`
  width: 100%;
  min-height: 680px;
  flex-grow: 1;
  border: 0;
`);

export const LockerMap = () => (
  <Map
    src="https://widget-v5.boxnow.gr/navigate.html?partnerId=123&amp;gps=yes&amp;autoselect=yes&amp;autoclose=no"
    allow="geolocation"
  />
);

const LockerMapSection = () => {
  const { t } = useTranslation();

  return (
    <Styles>
      <Container>
        <Typography className="header-title">
          {t("map.section.header")}
        </Typography>
        <Typography className="map-text" sx={{ textAlign: "start" }}>
          {t("map.section.text")}{" "}
          <Link className="map-link" href="https://boxnow.gr/locker-info">
            {t("map.section.link")}
          </Link>
        </Typography>
        <LockerMap />
      </Container>
    </Styles>
  );
};

export default LockerMapSection;
