import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Typography, Theme, Container } from '@mui/material';
import magento21 from '../../assets/magento2/magento21.png';
import magento22 from '../../assets/magento2/magento22.png';
import magento23 from '../../assets/magento2/magento23.png';
import magento24 from '../../assets/magento2/magento24.png';
import magento25 from '../../assets/magento2/magento25.png';
import magento26 from '../../assets/magento2/magento26.png';
import magento27 from '../../assets/magento2/magento27.png';
import magento28 from '../../assets/magento2/magento28.png';
import magento29 from '../../assets/magento2/magento29.png';
import magento29en from '../../assets/magento2/magento29en.png';
import magento210 from '../../assets/magento2/magento210.png';
import magento211 from '../../assets/magento2/magento211.png';
import magento212 from '../../assets/magento2/magento212.png';
import { colors } from '../../theme/theme';

export const Magento2en = () => {
  const navigate = useNavigate();

  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='container d-flex flex-row'>
        <div className='w-50 position-relative'>
          <aside className='sidebar hidden'>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>Magento</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Setup</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Admin Page / Dashboard</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Using plugin</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Magento Dashboard</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Support</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <span className='h5 d-flex align-items-center'>
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/eshops');
                        }}
                      >
                        Πίσω
                      </a>
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
        </div>
        <div>
          <h2>Magento</h2>
          <div className='mt-5'>
            <span>For downloading and plugin support, contact: </span>
            <a href='https://www.design-solutions.gr/' target='_blank'>
              design-solutions.gr
            </a>
          </div>
          <div className='mt-5'>
            <span>
              For further information please contact the email address:
              <a href='mailto: info@design-solutions.gr'>info@design-solutions.gr</a>
            </span>
          </div>

          <h3 className='mt-5' id='1'>
            Setup instructions
          </h3>

          <h5 className='mt-5'>Step 1: Plugin Frontend Features</h5>

          <ol>
            <li>Show BOX NOW shipping method in the Magento checkout.</li>
            <li>
              Hide the BOX NOW shipping method if the consumer chooses a country other than Greece.
            </li>
            <li>
              By selecting BOX NOW is displayed iframe with map, points, search based on address,
              postal code etc.
            </li>
            <li>When consumer selecting a locker the address is displayed at a relevant point.</li>
            <li>
              Locker id and address are stored in the default field of Magento: Shipping Title and
              in a custom field in the shipping address.
            </li>
            <li>
              Calculate and add costs to the total order at checkout (flat cost set by
              administrator).
            </li>
            <li>Hide Cash On Delivery (COD) payment method (concerns native Magento COD).</li>
            <li>Appears BOX NOW (COD) wording when shipping method is selected.</li>
            <li>Shipping Title follows Magento on the backend and in the transactional emails.</li>
            <li>
              Custom field is created in the customer's custom address (custom address attribute),
              in which BOX NOW delivery id is stored (boxnow_delivery_id).
            </li>
          </ol>

          <h5 className='mt-5'>Step 2: Plugin Backend Features</h5>
          <p>
            Administrator processes each order/shipment separately, gives him the opportunity to
            create multiple vouchers per order.{' '}
          </p>
          <p>Follow these steps:</p>
          <ol>
            <li>Selects Magento default Shipment button.</li>
            <li>Selects the products and the quantity per product.</li>
            <li>
              Selects save button performs the following:
              <ul className='list-inside'>
                <li>
                  a request is automatically sent to the BOX NOW API for the creation of a voucher.
                </li>
                <li>customer is updated for shipping via email from Magento.</li>
                <li>Sending vouchers automatically to the e-shopvia email.</li>
                <li>Consumer will be informed automatically by BOX NOW.</li>
              </ul>
            </li>
          </ol>

          <h5 className='mt-5'>Step 3: BOX NOW COD</h5>
          <ol>
            <li>New payment method for Cash On Delivery.</li>
            <li>Locked wording for the COD.</li>
            <li>Ability to activate/deactivate.</li>
            <li>COD service only for customers with country of delivery Greece.</li>
            <li>Ability to determine the order of display of the payment method.</li>
            <li>
              Custom field that the administrator can note instructions for the payment method.
            </li>
          </ol>

          <h5 className='mt-5'>Step 4: Install</h5>
          <ol>
            <li>Unzip zip file Magento 2 directory, στο: app/code/</li>
            <li>
              Activate the module by running{' '}
              <span className='bolder'>php bin/magento module:enable Elegento_BoxNow</span>
            </li>
            <li>
              Apply the database updates running it{' '}
              <span className='bolder'>php bin/magento setup:upgrade</span>
            </li>
            <li>
              Flush cache running it <span className='bolder'>php bin/magento cache:flush</span>
            </li>
          </ol>

          <p>The module is now ready to be activated by the admin page.</p>

          <h5 className='mt-5 mb-5' id='2'>
            Step 5: Admin Page / Dashboard
          </h5>

          <p className='bolder'>Delivery Method</p>

          <p>First of all, BoxNowmust be activated as a shipping method to store configuration.</p>

          <p className='bolder'>Stores → Settings → Configuration</p>

          <img className='mt-5 mb-5' src={magento21} />

          <p className='bolder'>Sales → Delivery Methods → BoxNow</p>

          <img className='mt-5 mb-5' src={magento22} />

          <p>
            As this point you need to change the Boxnow shipping method to{' '}
            <span className='bolder'>Enable: “Yes”</span> and confirm that it is enabled for the
            correctStore View or Website.
          </p>

          <p>
            You will need to fill in the shipping method settings to display the method as an option
            in checkout.
          </p>

          <img className='mt-5 mb-5' src={magento23} />

          <p>
            Finally, you will need to fill in the customer information and the Api details to
            complete installation.
          </p>

          <img className='mt-5 mb-5' src={magento24} />

          <p className='bolder'>Payment Method</p>

          <p>BOX NOW should be activated as a delivery method at store configuration.</p>

          <p className='bolder'>Stores → Settings → Configuration</p>

          <img className='mt-5 mb-5' src={magento25} />

          <p>
            Then go: <span className='bolder'>Sales → Payment Methods → BoxNow</span>
          </p>

          <p>
            At the checkout the consumer can choose the BoxNow shipping method from the available
            shipping methods.
          </p>
          <img className='mt-5 mb-5' src={magento26} />
          <p>
            At this point you need to change the BOX NOW payment method to Enable: “Yes” and confirm
            it. Enabling BOX NOW Payment replaces Magento's desult COD.
          </p>

          <img className='mt-5 mb-5' src={magento27} />

          <h5 className='mt-5 mb-5' id='3'>
            Step 6: Using plugin
          </h5>

          <p>At checkout the consumer can choose the BOX NOW shipping method.</p>

          <img className='mt-5 mb-5' src={magento28} />

          <p>
            After selecting BoxNow Express, the map will appear and the customer will be able to
            choose the desired pick up locker.
          </p>

          <img className='mt-5 mb-5' src={magento29en} />

          <p>
            After selecting BOX NOW locker can proceed normally to completion of the checkout
            process. Customer will be able to select BOX NOW COD as payment method.{' '}
          </p>

          <img className='mt-5 mb-5' src={magento210} />

          <h5 className='mt-5 mb-5' id='4'>
            Step 7: Magento Dashboard
          </h5>

          <p>
            From Magento administrator can create the voucher, following the steps of shipping
            without need to fill in some information. The administrator will must complete the
            process of “Invoice” and then the process of “Ship”.
          </p>

          <p>
            After the administrator completes the previous two steps, he can download the voucher of
            receipt from the order page by pressing
            <span className='bolder'>“Download BoxNow Voucher”</span> button.
          </p>

          <img className='mt-5 mb-5' src={magento211} />

          <p>
            or <span className='bolder'>Shipments → View </span>
          </p>

          <img className='mt-5 mb-5' src={magento212} />

          <h5 id='5'>Notes:</h5>
          <ol>
            <li>Testing plugin with stage Api keys.</li>
            <li>
              Select stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
              10447
            </li>
            <li>
              When a new order is completed we will automatically send you a PDF shipping label.
            </li>
          </ol>

          <h5 className='mt-5'>Get Support</h5>
          <p>
            If you have any questions about our solutions, or questions about how to integrate with
            our solutions, please refer to our email{' '}
            <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
