import React from 'react';
import { Button, Grid, Paper, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../theme/theme';
import BoxNowContainer from 'components/BoxNowContainer';
import StepsForConnection from 'components/StepsForConnection';
import { Styles as StylesFromMainPage } from './MainPage';

export const Guide: React.FC = () => {
  const GuideCss = withTheme(styled.div`
    .next-typo {
      font-weight: 700;
      font-size: 1.25rem;
    }
  `);
  return (
    <BoxNowContainer>
      <StylesFromMainPage>
        <GuideCss>
          <StepsForConnection />
        </GuideCss>
      </StylesFromMainPage>
    </BoxNowContainer>
  );
};
