import {
  Grid,
  Theme,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useLanguageNavigate } from "app/hooks";
import useTranslation from "translations/useTranslation";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

import green from "../assets/green.png";
import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "theme/theme";

const Styles = withTheme(styled.div`
  background-color: #f3f3ec;
  min-height: 400px;
  margin-top: ${({ theme }: { theme: Theme }) => theme.spacing(20)};

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
    margin-top: ${({ theme }: { theme: Theme }) => theme.spacing(5)};
    padding-top: ${({ theme }: { theme: Theme }) => theme.spacing(5)};
  }

  .under-outlet-out {
    position: relative;
    margin-top: 1em;
    margin-bottom: 0;
  }

  .section-div-desk {
    min-width: 1200px;
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  .section-count-below {
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
  }

  .div-lower-md {
    display: flex;
    flex-direction: column;
    margin-top: 2em;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      flex-direction: row;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  }

  .div-count-flex {
    display: flex;
  }

  .count-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2rem;
    color: ${colors.secondary};
  }

  .count-main-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    text-align: start;
    color: #7d7986;
  }

  .text-dec {
    text-decoration: none;

    @media (max-width: 380px) {
      width: 100%;
    }
  }

  .locker-image {
    margin-top: -100px;
    width: 530px;
    height: 375px;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      margin-top: 1em;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      width: 340px;
      height: 240px;
    }

    @media (max-width: 380px) {
      width: 90%;
      margin-left: 5%;
    }
  }

  .button-container {
    display: flex;
    gap: 0.5rem;

    @media (max-width: 380px) {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }

  .locker-button {
    border-radius: 0;
    background-color: #f1bfdf;
    padding: 1em;
    margin-top: 1em;
    height: 53px;
    &:hover {
      background-color: #f1bfdf;
    }

    @media (max-width: 380px) {
      width: 90%;
    }
  }

  .track-button-second {
    border-radius: 0;
    background-color: rgb(68, 214, 45);
    padding: 1em;
    margin-top: 1em;
    height: 53px;
    &:hover {
      background-color: rgb(62, 204, 40);
    }

    @media (max-width: 380px) {
      width: 90%;
    }
  }

  .locker-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.125rem;
    color: ${colors.secondary};
  }

  .locker-typo-second {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.125rem;
    color: white;
  }
`);

const LockerCount = () => {
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();
  const { ref, inView } = useInView({ threshold: 0.3, triggerOnce: true });

  const theme = useTheme();
  const isLowerLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Styles>
      {isLowerLG ? (
        <Container maxWidth="lg">
          <div className="section-count">
            <Grid item xs={12} container className="under-outlet-out">
              <div className="section-count-below">
                <div ref={ref} className="div-count">
                  <CountUp
                    start={0}
                    end={inView ? Number(t("lockers.count")) : 0}
                    duration={3}
                    separator="."
                    useEasing={true}
                  >
                    {({ countUpRef }) => (
                      <div className="div-lower-md">
                        <div className="div-count-flex">
                          <Typography
                            className="count-main-title"
                            align="left"
                            ref={countUpRef}
                          ></Typography>
                          <Typography className="count-main-title" align="left">
                            {t("finding.locker1")}
                          </Typography>
                        </div>

                        <Typography className="count-main-title" align="left">
                          {t("finding.locker2")}
                        </Typography>
                      </div>
                    )}
                  </CountUp>
                  <Typography className="count-main-text">
                    {t("spread.lockers.text")}
                  </Typography>
                  <div className="button-container">
                    <a
                      href={
                        window.location.pathname.includes("/en") === true
                          ? "https://boxnow.gr/en/locker-finder"
                          : "https://boxnow.gr/locker-finder"
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="text-dec"
                    >
                      <Button className="locker-button">
                        <Typography className="locker-typo">
                          {t("button.find.locker")}
                        </Typography>
                      </Button>
                    </a>
                    <Button
                      className="track-button-second"
                      onClick={() => navigate("/form")}
                    >
                      <Typography className="locker-typo-second">
                        {t("onboard.becomePartner.button")}
                      </Typography>
                    </Button>
                  </div>
                </div>

                <img src={green} alt="" className="locker-image" />
              </div>
            </Grid>
          </div>
        </Container>
      ) : (
        <Grid item xs={12} container className="under-outlet-out">
          <div className="section-div-desk">
            <div ref={ref} className="div-lower-md">
              <CountUp
                start={0}
                end={inView ? Number(t("lockers.count")) : 0}
                duration={3}
                separator="."
                useEasing={true}
              >
                {({ countUpRef }) => (
                  <div className="div-count-flex">
                    <Typography
                      className="count-main-title"
                      ref={countUpRef}
                    ></Typography>
                    <Typography className="count-main-title">
                      {t("finding.locker1")} {t("finding.locker2")}
                    </Typography>
                  </div>
                )}
              </CountUp>
              <Typography className="count-main-text">
                {t("spread.lockers.text")}
              </Typography>
              <div className="button-container">
                <a
                  href={
                    window.location.pathname.includes("/en") === true
                      ? "https://boxnow.gr/en/locker-finder"
                      : "https://boxnow.gr/locker-finder"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="text-dec"
                >
                  <Button className="locker-button">
                    <Typography className="locker-typo">
                      {t("button.find.locker")}
                    </Typography>
                  </Button>
                </a>
                <Button
                  className="track-button-second"
                  onClick={() => navigate("/form")}
                >
                  <Typography className="locker-typo-second">
                    {t("onboard.becomePartner.button")}
                  </Typography>
                </Button>
              </div>
            </div>
            <img src={green} alt="" className="locker-image" />
          </div>
        </Grid>
      )}
    </Styles>
  );
};
export default LockerCount;
