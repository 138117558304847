import { Button, Grid, Paper, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../translations/useTranslation';

import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../theme/theme';

export const StepPaper = withTheme(styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 0;
  background-color: ${colors.onBackground};
  border: 1px solid rgba(38, 5, 99, 0.3);
  width: 20%;
  height: 400px;
  padding: 20px;

  margin-bottom: 0;

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down('lg')} {
    width: calc(100vw - 450px);
    margin-bottom: 2em;
    padding: 40px;
    height: 550px;
  }

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down('md')} {
    width: calc(100vw - 200px);
    height: 500px;
  }

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down('sm')} {
    width: 300px;
    height: 450px;
  }

  .image-papers {
    width: 60px;
    height: 60px;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.between('sm', 'lg')} {
      width: 80px;
      height: 80px;
    }
  }

  .step-main-title {
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.4rem;
    color: ${colors.secondary};
    text-align: center;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.between('sm', 'lg')} {
      font-size: 2rem;
    }
  }

  .step-secondary-title {
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
    font-weight: 400;
    font-size: 0.875rem;
    color: #7d7986;
    text-align: center;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.between('sm', 'lg')} {
      font-size: 1.2rem;
    }
  }

  .connect-button {
    border-radius: 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.between('sm', 'lg')} {
      width: 95%;
      font-size: 1.5rem;
    }
  }

  .next-typo {
    ${({ theme }: { theme: Theme }) => theme.breakpoints.between('sm', 'lg')} {
      font-size: 1.2rem;
    }
  }
`);

const StepsForConnection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isLowerLG = useMediaQuery(theme.breakpoints.down('lg'));
  const isEqualXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      container
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      sx={{ pt: 4, pb: 12 }}
    >
      <Typography className={isEqualXS ? 'main-title-fonts-mob-border' : 'main-title-fonts-desk2'}>
        {t('onboard.becomePartner.title')}
      </Typography>
      <Typography className='step-under-title container'>
        {t('onboard.becomePartner.title2')}
      </Typography>
      <div className={isLowerLG ? 'div-triple-images-mob' : 'div-triple-images-desk'}>
        <StepPaper>
          {/* <img src={time} className="image-papers" /> */}
          <Typography className='step-main-title'>{t('onboard.connection.step1.title')}</Typography>
          <Typography className='step-secondary-title'>
            {t('onboard.connection.step1.text')}
          </Typography>
          <a href='https://boxnow.gr/partner-api' target='_blank'>
            <Button className='connect-button'>
              <Typography className='next-typo'>{t('onboard.connect')}</Typography>
            </Button>
          </a>
        </StepPaper>
        <StepPaper sx={{ mx: 4 }}>
          {/* <img src={fast} className="image-papers" /> */}
          <Typography className='step-main-title'>{t('onboard.connection.step2.title')}</Typography>
          <Typography className='step-secondary-title'>
            {t('onboard.connection.step2.text')}
          </Typography>
          <Button className='connect-button' onClick={() => navigate('/eshops')}>
            <Typography className='next-typo'>{t('onboard.connect')}</Typography>
          </Button>
        </StepPaper>
        <StepPaper>
          {/* <img src={money} className="image-papers" /> */}
          <Typography className='step-main-title'>{t('onboard.connection.step3.title')}</Typography>
          <Typography className='step-secondary-title'>
            {t('onboard.connection.step3.text')}
          </Typography>

          <Button className='connect-button' onClick={() => navigate('/partner-portal')}>
            <Typography className='next-typo'>{t('onboard.connect')}</Typography>
          </Button>
        </StepPaper>
      </div>
      {/* <div className={isLowerLG ? "pink-div-mob" : "pink-div-desk"}></div> */}
    </Grid>
  );
};

export default StepsForConnection;
