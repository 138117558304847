import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useTranslation from "../translations/useTranslation";
import React from "react";
import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../theme/theme";

const Styles = withTheme(styled.div`
  background-color: #f3f3ec;

  .under-outlet-out {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 4em;
  }

  .section-question-grid {
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    margin: auto;
  }

  .section-question-div-column {
    display: flex;
    flex-direction: column;
  }

  .section-question-grid-div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
  }

  .questions-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2.25rem;
    margin: 0.5em 0 1em 0;
    color: ${colors.secondary};
    align-self: flex-start;
  }

  .questions-header {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    padding-left: 1em;
    text-align: start;
    color: ${colors.secondary};
  }

  .accordion-settings {
    border-radius: 0;
    border-top: 1px solid rgba(38, 5, 99, 0.3);
    background-color: #f3f3ec;
    padding: 20px;
  }

  .div-count-flex {
    display: flex;
  }

  .remove-icon {
    color: #6cd04e;
    font-size: 1.5rem;
    margin-top: 3px;
  }

  .add-icon {
    color: #6cd04e;
    font-size: 1.5rem;
    margin-top: 3px;
  }

  .accordion-details {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    padding-left: 3em;
    color: #7d7986;
    text-align: start;
  }
`);

type Question = {
  question: string | undefined;
  answer: string | undefined;
};

const FAQ = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const questions: Question[] = [
    { question: t("question.first"), answer: t("answer.first") },
    { question: t("question.second"), answer: t("answer.second") },
    { question: t("question.third"), answer: t("answer.third") },
    { question: t("question.fourth"), answer: t("answer.fourth") },
    { question: t("question.fifth"), answer: t("answer.fifth") },
  ];

  return (
    <Styles>
      <Grid item xs={12} container className="under-outlet-out">
        <div className="section-question-grid">
          <div className="section-question-div-column">
            <Typography className="questions-title">
              {t("common.questions")}
            </Typography>

            {/* Map questions to accordions */}
            {questions.map((question, index) => {
              const panel = `panel${index}`;

              return (
                <Accordion
                  expanded={expanded === panel}
                  onChange={handleChange(panel)}
                  className="accordion-settings"
                  key={panel}
                >
                  <AccordionSummary
                    aria-controls={`${panel}d-content`}
                    id={`${panel}d-header`}
                  >
                    <div className="div-count-flex">
                      {expanded === panel ? (
                        <RemoveIcon className="remove-icon" />
                      ) : (
                        <AddIcon className="add-icon" />
                      )}
                      <Typography className="questions-header">
                        {question.question}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accordion-details">
                      {question.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </Grid>
    </Styles>
  );
};

export default FAQ;
