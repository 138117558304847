import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import shopify1 from '../../assets/shopify/shopify1.png';
import shopify2 from '../../assets/shopify/shopify2.png';
import shopify3 from '../../assets/shopify/shopify3.png';
import shopify4 from '../../assets/shopify/shopify4.png';
import shopify5 from '../../assets/shopify/shopify5.png';
import shopify6 from '../../assets/shopify/shopify6.png';
import shopify7 from '../../assets/shopify/shopify7.png';
import shopify8 from '../../assets/shopify/shopify8.png';
import shopify9 from '../../assets/shopify/shopify9.png';
import shopify10 from '../../assets/shopify/shopify10.png';
import shopify11 from '../../assets/shopify/shopify11.png';
import shopify12 from '../../assets/shopify/shopify12.png';
import shopify13 from '../../assets/shopify/shopify13.png';
import shopify14 from '../../assets/shopify/shopify14.png';
import shopify15 from '../../assets/shopify/shopify15.png';
import shopify16 from '../../assets/shopify/shopify16.png';
import shopify17 from '../../assets/shopify/shopify17.png';
import shopify18 from '../../assets/shopify/shopify18.png';
import shopify19 from '../../assets/shopify/shopify19.png';
import shopify20 from '../../assets/shopify/shopify20.png';
import shopify21 from '../../assets/shopify/shopify21.png';
import shopify22 from '../../assets/shopify/shopify22.png';
import shopify23 from '../../assets/shopify/shopify23.png';
import shopify24 from '../../assets/shopify/shopify24.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';
import BoxNowContainer from 'components/BoxNowContainer';

export const Shopify = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    img {
      max-width: 1100px !important;
      max-height: 400px;
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 90% !important; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='d-flex flex-row mt-5'>
        <div className='sidebar hidden'>
          <aside>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>Shopify</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Βήμα 1: Εγκατάσταση</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Βήμα 2: Παραμετροποίηση app</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Βήμα 3: Ενεργοποίηση σαν τρόπο αποστολής</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Βήμα 4: Λεκτικό για την αντικαταβολή</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Βήμα 5: Διαχείριση Παραγγελιών</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#6'>Υποστήριξη</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex  align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/eshops');
                    }}
                  >
                    Πίσω
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Shopify</h2>

          <h4 className='mt-5'>Οδηγίες εγκατάστασης</h4>
          <h4 className='mt-5' id='1'>
            Βήμα 1: Εγκατάσταση
          </h4>
          <p className='mt-5'>
            <span>Κατεβάζουμε το app της BOX NOW από: </span>
            <a href='https://apps.shopify.com/boxnow'>
              BoxNow - Automated process for BoxNow parcel delivery lockers. | Shopify App Store
            </a>
          </p>

          <p>
            Στη συνέχεια στο διαχειριστικό του Shopify στην κατηγορία app θα εμφανιστεί η επιλογή
            της BOX NOW.
          </p>

          <img className='mt-5 mb-5' src={shopify1} />

          <h4 id='2'>Βήμα 2: Παραμετροποίηση app</h4>
          <p>
            Κλικάρουμε στην επιλογή της <span className='bolder'>BOX NOW → Settings</span>{' '}
          </p>
          <p>
            Αρχίζετε να συμπληρώνεται τα πεδία σύμφωνα με τις πληροφορίες - κωδικούς που σας έχουν
            δοθεί από εμάς:{' '}
          </p>
          <p>
            <span className='bolder'>Client ID</span>: Το client id όπως σας έχει δοθεί (π.χ.
            9d8gg3de-8119-4tea-991e-37ff20eq5e50).
          </p>
          <p className='text-break'>
            <span className='bolder'>Client Secret</span>: γράψτε το client secret όπως σας έχει
            δοθεί (π.χ. c1144abc1db1f3186442a4ec3d3e953de0105c12759a2d82771a888256659e67f).
          </p>
          <p>
            <span className='bolder'>Partner Id</span>: γράφετε το parnter id όπως σας έχει δοθεί
            (π.χ. 1).{' '}
          </p>
          <p>
            <span className='bolder'>API URL</span>γράφετε το url προς το API (π.χ.{' '}
            <a href='https://api-production.boxnow.gr'>https://api-production.boxnow.gr</a>).
          </p>
          <p>
            <span className='bolder'>Enable Lockers on Checkout</span>: Τσεκάροντας αυτό το πεδίο
            εμφανίζεται η υπηρεσία στο checkout του site.
          </p>
          <p>
            <span className='bolder'>Add More Locations</span>: εδώ γράφετε την αποθήκη σας id και
            όνομα (π.χ. 8 - Partner) και πατάτε add new Location. Αν έχετε πάνω από μία αποθήκη
            επαναλαμβάνεται την διαδικασία.
          </p>
          <p>
            <span className='bolder'>Shipping</span>: γράφετε τo κόστος χρέωσης της υπηρεσίας.
          </p>
          <p>
            <span className='bolder'>Free Shipping</span>: γράφετε τo ποσό που πάνω από αυτό η
            υπηρεσία μας δεν θα χρεώνεται.
          </p>
          <p>*Προσοχή χρειάζεται να συμπληρωθούν υποχρεωτικά τα 2 τελευταία ποσά.</p>
          <p>
            <span className='bolder'>COD</span>: γράφετε το λεκτικό που θέλετε να εμφανίζεται. Και
            στη συνέχεια αποθηκεύεται τις αλλαγές.
          </p>

          <img className='mt-5 mb-5' src={shopify2} />

          <h4 id='3'>Βήμα 3: Ενεργοποίηση σαν τρόπο αποστολής</h4>
          <p>Στη συνέχεια θα χρειαστεί να ενεργοποιήσετε την BOX NOW σαν τρόπο αποστολής.</p>
          <p className='bolder'>
            Settings → Shipping and delivery → Manage → Carrier and app rates
          </p>

          <img className='mt-5 mb-5' src={shopify3} />

          <h4 id='4'>Βήμα 4: Λεκτικό για την αντικαταβολή</h4>
          <p>
            Για να εμφανιστεί το λεκτικό της αντικαταβολής στο checkout, θα χρειαστεί συμπληρωματικά
            να καταχωρηθεί και στις ρυθμίσεις των πληρωμών.
            <span className='bolder'>
              {' '}
              Settings → Payments → Cash on Delivery (COD) → Manage → Additional details.{' '}
            </span>
            Στην συνέχεια καταχωρούμε το λεκτικό που έχουμε γράψει στην παραμετροποίηση του app.
          </p>

          <img className='mt-5 mb-5' src={shopify4} />

          <h4 id='5'>Βήμα 5: Διαχείριση Παραγγελιών</h4>
          <p>
            Πατώντας την επιλογή BoxNow εμφανίζονται συνολικά οι παραγγελίες του e-shop. Για να
            εμφανιστούν μόνο αυτές της BOX NOW θα χρειαστεί να προσθέσουμε το φίλτρο της Box Now.
            Αυτό θα γίνει μέσω του app SHOPIFY FLOW.
          </p>
          <p>
            Από την αρχική σελίδα του διαχειριστικού του Shopify store μας, πατάμε πάνω στην μπάρα
            του Search.
          </p>

          <img className='mt-5 mb-5 tttt' src={shopify5} />

          <p>
            Έπειτα, πατάμε πάνω στο κουμπί που λέει Apps, και αρχίζουμε να πληκτρολογούμε στο πεδίο
            της αναζήτησης “Shopify Flow”.
          </p>

          <img className='mt-5 mb-5 tttt' src={shopify6} />

          <p>
            Στη συνέχεια, πατάμε το κουμπί που λέει Search for “Shopify Flow” in the Shopify App
            Store και αφήνουμε τον περιηγητή να μας πάρει στο app store του Shopify. Από την
            καινούρια σελίδα που έχει ανοίξει, πατάμε το Shopify Flow by Shopify.
          </p>

          <img className='mt-5 mb-5' src={shopify7} />

          <p>Τέλος, πατήστε πάνω στο Add app</p>

          <img className='mt-5 mb-5' src={shopify8} />

          <p>
            και μετά Install app για να εγκατασταθεί η εφαρμογή στο Shopify store σας. Πλέον η
            εφαρμογή έχει ρυθμιστεί στο μαγαζί σας και μπορείτε να τη βρείτε στην περιοχή των Apps
            σας.
          </p>

          <img className='mt-5 mb-5' src={shopify9} />

          <p>
            Μπορείτε να δείτε την εφαρμογή του Shopify Flow πατώντας το Apps κάτω από το Sales
            Channels στο μενού αριστερά. Πατώντας το κουμπί Create workflow, μπορείτε να ξεκινήσετε
            να φτιάχνετε ένα καινούριο workflow.
          </p>

          <img className='mt-5 mb-5' src={shopify10} />

          <p>
            Πατήστε πάνω στο κουμπί Select a trigger και ξεκινήστε φτιάχνοντας την συνθήκη που θα
            ξεκινάει το workflow μας. Στην συγκεκριμένη περίπτωση, θέλουμε με το που δημιουργηθεί
            μια παραγγελία, να ελέγχεται αν στο checkout επιλέχθηκε locker της BoxNow για παράδοση,
            και αν αυτό ισχύει, να της βάζει ένα order tag “box-now” στο διαχειριστικό σας.
          </p>

          <img className='mt-5 mb-5' src={shopify11} />

          <p>
            Στο μενού που έχει ανοίξει στα δεξιά, πατήστε πάνω στο πεδίο Search και πληκτρολογήστε
            το “order”. Έπειτα, όταν εμφανιστούν τα αποτελέσματα, πατήστε πάνω στο Order created.
          </p>

          <img className='mt-5 mb-5' src={shopify12} />

          <p>
            Πατήστε πάνω στο + δίπλα στο Then και έπειτα στο Condition για να προσθέσετε την
            συνθήκη.
          </p>

          <img className='mt-5 mb-5' src={shopify13} />

          <p>
            Πατήστε πάνω στο <span className='bolder'>Add criteria</span>.
          </p>

          <img className='mt-5 mb-5' src={shopify14} />

          <p>
            Στο νέο μενού που θα εμφανιστεί, πατήστε στο θέμα Order και μετά σκρολάρετε στα πεδία
            του που θα εμφανιστούν δεξιά, μέχρι να βρείτε το πεδίο “shippingLines”.
          </p>

          <img className='mt-5 mb-5' src={shopify15} />

          <p>Μετά, σκρολάρετε στα πεδία του shippingLines στα δεξιά και πατήστε πάνω στο source.</p>

          <img className='mt-5 mb-5' src={shopify16} />

          <p>Τέλος, πατήστε εκεί που λέει “Enter source” και πληκτρολογήστε “Box Now Service”.</p>

          <img className='mt-5 mb-5' src={shopify17} />

          <p>
            Τώρα, πατήστε πάνω στο + δεξιά του Then πάνω στο condition που μόλις φτιάξατε, και
            έπειτα πάνω στο Action.
          </p>

          <img className='mt-5 mb-5' src={shopify18} />

          <p>
            Εδώ, θα ρυθμίσετε την διαδικασία που θα εκτελείται όταν ισχύει η παραπάνω συνθήκη. Στο
            μενού που θα εμφανιστεί στα δεξιά, μπορείτε ήδη να δείτε και να πατήσετε το action “Add
            order tags”, αλλιώς αναζητήστε το στο Search και πατήστε το.
          </p>

          <img className='mt-5 mb-5' src={shopify19} />

          <p>Τέλος, πατήστε στο πεδίο που λέει “Start typing” και πληκτρολογήστε “box-now”.</p>

          <img className='mt-5 mb-5' src={shopify20} />

          <p>
            Τώρα το μόνο που μένει είναι να ενεργοποιηθεί το workflow. Πατήστε πάνω δεξιά στο
            πράσινο κουμπί Turn on workflow και μετά στο παράθυρο πατήστε Turn on για να ολοκληρωθεί
            η διαδικασία. Θα εμφανιστεί ένα μήνυμα πως το workflow έχει ενεργοποιηθεί και
            πηγαίνοντας πίσω, θα μπορείτε να το δείτε ως Active, και όχι ως Draft.
          </p>

          <img className='mt-5 mb-5' src={shopify21} />

          <p>
            Μπορείτε πλέον να κάνετε μια δοκιμαστική παραγγελία για να δείτε ότι η συνθήκη έχει
            ρυθμιστεί σωστά, καθώς και η προσθήκη του order tag. Μπορείτε κάτω από το Workflows να
            δείτε και το Run history, που περιέχει το ιστορικό από workflows που έχουν τρέξει και
            έχουν ολοκληρωθεί (μέχρι και 7 ημέρες πίσω).
          </p>

          <img className='mt-5 mb-5' src={shopify22} />

          <p>
            Για τη δημιουργία μεμονωμένων vouchers επιλέγουμε μια παραγγελία την ανοίγουμε και
            επιλέγουμε Create Voucher και στη συνέχεια Download Voucher.
          </p>

          <p>
            Μπορούμε να αλλάξουμε το πλήθος των parcels (vouchers), γράφοντας των αριθμό που
            επιθυμούμε στο πλαίσιο Parcels.
          </p>

          <p>
            {' '}
            *Στην περίπτωση που η συμφωνία συνεργάτη είναι η τοποθέτηση των δεμάτων στα lockers (any
            apm), μπορείτε να επιλέξετε και το μέγεθος της θυρίδας από το Compartment Size (Small,
            Medium & Large). Σε αυτή την περίπτωση θα πρέπει να έχει δηλωθεί στο διαχειριστικό σαν
            αποθήκη το id 2.
          </p>

          <img className='mt-5 mb-5' src={shopify23} />

          <p>
            Στην περίπτωση πολλαπλών vouchers επιλέγουμε συνολικά τις παραγγελίες στη συνέχεια
            Create Voucher (s) και στη συνέχεια Download Voucher(s){' '}
          </p>

          <img className='mt-5 mb-5' src={shopify24} />

          <h4 id='6'>Προσοχή:</h4>
          <ol>
            <li>
              Για να χρησιμοποιείται το app της BOX NOW χρειάζεται να είστε
              <ol type='a'>
                <li>
                  basic + carrier shipping συνδρομητής χρειάζεται με μηνιαία συνδρομή ή ετήσια.
                </li>
                <li>advanced + carrier shipping συνδρομητής.</li>
                <li>plus συνδρομητής παρέχεται δωρεάν.</li>
              </ol>
            </li>
            <li>Τεστάρετε το plugin μόνο με τα stage Api keys.</li>
            <li>
              Επιλέξτε το stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
              10447.
            </li>
            <li>
              Για να λάβετε το voucher της παραγγελίας πρέπει η παραγγελία να είναι
              ολοκληρωμένη/πληρωμένη.
            </li>
          </ol>

          <h4 className='mt-5'>Υποστήριξη</h4>
          <p>
            Για οποιαδήποτε ερώτηση για την διασύνδεση σας με την υπηρεσία μας παρακαλώ να καλέστε
            μας στο 2111005330 ή στείλτε μας στο
            <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
