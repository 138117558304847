import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import wooc1 from '../../assets/woocommerce/wooc1.png';
import wooc2 from '../../assets/woocommerce/wooc2.jpg';
import wooc3 from '../../assets/woocommerce/wooc3.jpg';
import wooc4 from '../../assets/woocommerce/wooc4.jpg';
import wooc5 from '../../assets/woocommerce/wooc5.jpg';
import wooc6 from '../../assets/woocommerce/wooc6.jpg';
import wooc7 from '../../assets/woocommerce/wooc7.jpg';
import wooc8 from '../../assets/woocommerce/wooc8.jpg';
import wooc9 from '../../assets/woocommerce/wooc9.jpg';
import wooc10 from '../../assets/woocommerce/wooc10.jpg';
import wooc11 from '../../assets/woocommerce/wooc11.jpg';
import wooc12 from '../../assets/woocommerce/wooc12.jpg';
import wooc13 from '../../assets/woocommerce/wooc13.jpg';
import wooc14 from '../../assets/woocommerce/wooc14.jpg';
import wooc15 from '../../assets/woocommerce/wooc15.jpg';
import wooc16 from '../../assets/woocommerce/wooc16.jpg';
import wooc17 from '../../assets/woocommerce/wooc17.jpg';
import wooc18 from '../../assets/woocommerce/wooc18.jpg';
import wooc19 from '../../assets/woocommerce/wooc19.jpg';
import wooc20 from '../../assets/woocommerce/wooc20.jpg';
import wooc21 from '../../assets/woocommerce/wooc21.jpg';
import wooc22 from '../../assets/woocommerce/wooc22.jpg';
import wooc23 from '../../assets/woocommerce/wooc23.jpg';
import wooc24 from '../../assets/woocommerce/wooc24.jpg';
import wooc25 from '../../assets/woocommerce/wooc25.jpg';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';

export const WooCommerceEn = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='container d-flex flex-row'>
        <div className='w-25 position-relative hidden'>
          <aside className='sidebar hidden'>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>WooCommerce</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Step 1: Requirements for plugin installation</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Step 2: WooCommerce Settings</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Step 3: Delivery settings page overview</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Step 4: Usefull Links</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/eshops');
                    }}
                  >
                    Go back
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='width--high'>
          <h2>WooCommerce</h2>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a href='https://wordpress.org/plugins/box-now-delivery/' target='_blank'>
              WordPress Plugin
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a
              href='https://boxnow.gr/media/pdf/BOX NOW Delivery WordPress Plugin Documentation GR (2).pdf'
              download
              target='_blank'
            >
              WordPress Plugin Documentation
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a
              href='https://boxnow.gr/media/mp4/TestOrder+CreateVoucher.mp4'
              download
              target='_blank'
            >
              Test Order and Create Voucher video
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a href='https://boxnow.gr/media/mp4/PluginInstallationWP.mp4' download target='_blank'>
              Plugin Installation WP video
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a
              href='https://boxnow.gr/media/pdf/wp-voucher-instructions.pdf'
              download
              target='_blank'
            >
              Voucher Instructions
            </a>
          </div>
          <h3 className='mt-5'>WordPress Plugin Documentation</h3>
          <h5 id='1' className='mt-5'>
            Requirements for correct installation of the plugin
          </h5>
          <ul className='list-inside' id='1'>
            <li>
              WooCommerce must be installed and active before installing the BOX NOW Delivery
              plugin.
            </li>
            <li>PHP version 8 and above is required.</li>
            <li>WordPress version 6.2 and above is required.</li>
          </ul>
          <h5 className='mt-5'>BOX NOW Delivery Admin Menu Settings</h5>

          <img src={wooc1} />

          <h5 className='mt-5'>Your API details section</h5>
          <ul className='list-inside'>
            <li>
              We have the input with the name 'Your API URL', inside there you insert the URL BOX
              NOW has provided you. It automatically removes the 'httpsW and 'http://' prefix
              because it doesn't need.
            </li>
            <li>
              We have the input with the name 'Your Client ID', inside there you insert the Client
              ID BOX NOW has provided you.
            </li>
            <li>
              We have the input with the name 'Your Client Secret', there you insert the Client
              Secret BOX NOW has provided you.
            </li>
            <li>
              We have the input with the name 'Warehouse IDs', inside there you insert the Warehouse
              ID BOX NOW has provided you. If you want to add multiple warehouses please divide ids
              by comma example: 8,2.
            </li>
            <li>
              We have the input with the name 'Your Partner ID', inside there you insert the partner
              ID BOX NOW has provided you.
            </li>
          </ul>

          <h5 className='mt-5'>Button Customization section</h5>
          <ul className='list-inside'>
            <li>
              We have the input with the name 'Change button color", there you insert the color HEX
              code that you want for the Button in the checkout page that opens the popup map. If
              you don't change it, it takes by default the color #84C33F.
            </li>
            <li>
              We have the input with the name 'Change button text', there you insert the text you
              want to display for the button on the checkout page for the popup map. If you don't
              change it the default text is: Pick a locker.
            </li>
          </ul>

          <img src={wooc2} />

          <h5 className='mt-5'>Map customization section</h5>
          <p>
            If you select the first radio button with the name 'Popup modal window', it will display
            a button on the checkout page and when is clicked it will open a modal window with the
            map and lockers.
          </p>

          <img src={wooc3} />

          <p>
            If you select the second radio button with the name 'Embedded iFrame', it will embed the
            map automatically in your checkout page.
          </p>

          <img src={wooc4} />

          <h5 className='mt-5'>GPS tracking location toggle section</h5>
          <p>
            If you select the first radio button with the name "GPS ON', the map will display
            automatically the closest lockers in the map available based on your location at the
            current moment.
          </p>

          <img className='mb-3' src={wooc3} />

          <p>
            {' '}
            If you select the second radio button with the name 'GPS OFF', the map will display the
            lockers close to you according to the Postal Code/ZIP you have provided in the address
            of the order.
          </p>

          <img src={wooc5} />

          <h5 className='mt-5'>Choose voucher option section</h5>
          <p>
            When you select the radio button with the name 'Send voucher via email" a new input will
            appear beneath It with the name 'Please insert your email here: “. Inside that input,
            you can add the email you want for the pdf voucher to be sent.
          </p>

          <img className='mb-3' src={wooc6} />
          <img className='mb-3' src={wooc7} />

          <p>
            You have to change the order status to completed and update the order from the Order
            Actions section in order for the voucher to be sent to the email you have provided. See
            the example images below.
          </p>

          <img className='mb-3' src={wooc8} />
          <img className='mb-3' src={wooc9} />

          <p>
            If you do those actions you will receive an email in the email you have provided with
            the pdf voucher. Example of the email you will receive.
          </p>

          <img className='mb-3' src={wooc10} />

          <p>
            If you want to cancel the order you can select from the Status menu inside the order the
            'BOX NOW Cancel Order' and then update the order. This action sends a request to cancel
            the order in our CMS. See the example images below.
          </p>

          <img className='mb-3' src={wooc11} />
          <img className='mb-3' src={wooc9} />

          <p>
            The 'BOX NOW Cancel Order' only works for canceling the order when you have selected the
            'Send voucher via email' radio button.
          </p>

          <p>
            {' '}
            When you select the radio button with the name 'Display a button in WooCommerce admin
            order page for printing the vouchers', this action will display 3 buttons with the names
            'Create Vouchers (Small)', 'Create Vouchers (Medium)', 'Create Vouchers (Large)' and an
            input field in the page of the order to print the vouchers in your browser and it will
            bypass the send email functionality.
          </p>

          <img className='mb-3' src={wooc12} />
          <img className='mb-3' src={wooc13} />

          <p>
            In the example image below we have created 2 vouchers by clicking the 'Create Vouchers
            (Small)' button. We added the number 2 in the input field and click the 'Create
            Vouchers' button.
          </p>
          <p>
            It created two green buttons with the voucher number that are links that take you to the
            voucher pdf print page and two red buttons with the name 'Cancel Voucher' that cancel
            the associated voucher next to them and also send a request to cancel that voucher to
            our CMS.
          </p>
          <p>
            Tip: This action will send 2 requests on our CMS with 2 orders that have compartment
            sizes "Small' because we choose to create our vouchers by clicking the "Create Vouchers
            (Small)' button. If you want to create a medium compartment size order you have to click
            the 'Create Vouchers (Medium)' button and for Large the Large button.
          </p>

          <p>
            After you create the vouchers the three 'Create Vouchers' buttons will become
            unavailable, you have to cancel the vouchers in order for them to be available again.
          </p>
          <p>
            Tip: You cannot create more vouchers than the Max Vouchers number indicated with red
            color, it will throw you an error message.
          </p>

          <img className='mb-3' src={wooc14} />

          <p>
            If you click on the green buttons with the voucher number on it it will open a new tab
            with the pdf voucher that you can print See the image example below.
          </p>

          <img className='mb-3' src={wooc15} />

          <h5 className='mt-5'>Message shown when locker is not selected section</h5>

          <p>
            In this section, you can add the custom message you want to display on the checkout page
            when a locker is not selected and someone tries to checkout the order. If you don't add
            a custom message it will have the default message “Please select a locker first'.
          </p>

          <img className='mb-3' src={wooc16} />
          <img className='mb-3' src={wooc17} />

          <p>
            After making a change or selecting a different option in the BOX NOW Delivery settings
            menu always save your changes! Just click this button below and you are set to go!
          </p>

          <img className='mb-3' src={wooc18} />

          <h4 className='mt-5' id='2'>
            BOX NOW Delivery WooCommerce Settings
          </h4>

          <p>
            On the WooCommerce 'Settings* page select the ‘Shipping' tab and from there add a
            'Shipping zone* as shown in the steps of the images below.
          </p>

          <img className='mb-3' src={wooc19} />

          <p>
            From there you can add the zone you like we have added the zone 'Greece' in this example
            and from the button 'Add shipping method' we have added our BOX NOW shipping method and
            Free shipping. See the example image below.
          </p>

          <img className='mb-3' src={wooc20} />

          <p>
            From there also we can 'Edit' the settings from the BOX NOW shipping method, just click
            the 'Edit' button under the shipping method. See the example image below.
          </p>

          <img className='mb-3' src={wooc21} />

          <p>
            This is the settings page for the BOX NOW shipping method. Don't forget to always save
            your changes after a change.
          </p>

          <img className='mb-3' src={wooc22} />

          <h4 className='mt-5' id='3'>
            BOX NOW Delivery settings page overview
          </h4>

          <img className='mb-3' src={wooc23} />

          <ul className='list-inside'>
            <li>
              In the 'Enable/Disable' section when it is ticked the BOX NOW Delivery shipping method
              is becoming available in the checkout, if it is not ticked is not available.
            </li>
            <li>
              The 'Method Title' input changes the title for the shipping method, the default name
              is "Box Now'.
            </li>
            <li>The 'Cost' input adds your desired cost for that shipping method.</li>
            <li>
              On the 'Free Delivery Threshold' input you can add the total value of the order you
              want when it exceeds to be free without cost. If it is left empty it won't have any
              free delivery threshold. For example, if you have a 'Cost' of 2€ and your 'Free
              Delivery Threshold' is 60€, if your order total is above 60€ then the 'Cost' will be
              0€.
            </li>
            <li>
              The 'Taxable' dropdown has two options to select 'Yes" and 'No', if you choose 'Yes"
              then it will apply taxes to your order according to the country you are in and the tax
              rates you have. If you choose 'No' it won't apply any taxes to the order.
            </li>
            <li>
              The 'Max Weight' input always has to have the value of 20, because our locker's
              maximum kilograms is 20kg. If you put a number greater than 20 then the BOX NOW
              Delivery method won't work according to standards.
            </li>
            <li>
              When you install the plugin you should go to these settings and put the number 20 in
              this input and save the changes. It is necessary for the correct use of the plugin.
            </li>
            <li>
              On the 'Max Package Dimensions' section the input with the name 'Max Length (cm)' has
              always to be 60. the 'Max Width (cm)' has always to be 45 and the 'Max Height (cm)'
              has always to be 36, because those are the maximum dimensions of our locker
              compartments. If you add a number greater or smaller than the ones presented here the
              BOX NOW Delivery won't work as expected.
            </li>
            <li>
              When you install the plugin you should go to these settings and put in the inputs the
              numbers 60.45. and 36 exactly as they appear in the image of the overview of the
              settings and save the changes. It is necessary for the correct use of the plugin.
            </li>
            <li>
              On the section with the name 'Cash on delivery custom description settings', you can
              click the option with the name 'Enable Custom Description for COD", if you enable that
              you have to also write the custom description you want and save the changes. That
              action will save a custom description for the BOX NOW PAY ON THE GOI payment method
              you can see on the checkout page which is the default COD payment method from
              WooCommerce. See the example images below for a better understanding.
            </li>
          </ul>

          <img className='mb-3 mt-3' src={wooc24} />
          <img className='mb-3' src={wooc25} />

          <p id='4'>
            Don't forget to always Save your changes in order for them to reflect on the checkout
            page!
          </p>

          <h4 className='mt-5'>Useful links</h4>

          <ul className='list-inside'>
            <li>
              If you want more info about our widget you can find it here:{' '}
              <a href='https://widget-v5.boxnow.gr/devs'>https://widget-v5.boxnow.gr/devs</a>
            </li>
            <li>
              You can check our website here for more useful info:{' '}
              <a href='https://boxnow.gr/'>https://boxnow.gr/</a>
            </li>
            <li>
              If you want to contact us you can send your email here:{' '}
              <a href='mailto: info@boxnow.gr'>info@boxnow.gr</a>
            </li>
          </ul>
        </div>
      </div>
    </Styles>
  );
};
