import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import shopify1 from '../../assets/shopify/shopify1.png';
import shopify2 from '../../assets/shopify/shopify2.png';
import shopify3 from '../../assets/shopify/shopify3.png';
import shopify4 from '../../assets/shopify/shopify4.png';
import shopify5 from '../../assets/shopify/shopify5.png';
import shopify6 from '../../assets/shopify/shopify6.png';
import shopify7 from '../../assets/shopify/shopify7.png';
import shopify8 from '../../assets/shopify/shopify8.png';
import shopify9 from '../../assets/shopify/shopify9.png';
import shopify10 from '../../assets/shopify/shopify10.png';
import shopify11 from '../../assets/shopify/shopify11.png';
import shopify12 from '../../assets/shopify/shopify12.png';
import shopify13 from '../../assets/shopify/shopify13.png';
import shopify14 from '../../assets/shopify/shopify14.png';
import shopify15 from '../../assets/shopify/shopify15.png';
import shopify16 from '../../assets/shopify/shopify16.png';
import shopify17 from '../../assets/shopify/shopify17.png';
import shopify18 from '../../assets/shopify/shopify18.png';
import shopify19 from '../../assets/shopify/shopify19.png';
import shopify20 from '../../assets/shopify/shopify20.png';
import shopify21 from '../../assets/shopify/shopify21.png';
import shopify22 from '../../assets/shopify/shopify22.png';
import shopify23 from '../../assets/shopify/shopify23.png';
import shopify24 from '../../assets/shopify/shopify24.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';

export default function Shopify() {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    img {
      max-width: 1100px !important;
      max-height: 400px;
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 90% !important; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='container d-flex flex-row'>
        <div className='w-25 position-relative'>
          <aside className='sidebar hidden'>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4' href='https://boxnow.gr/diy/eshops/tailor-made'>
                  Tailor Made
                </a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Βήμα 1: Στήσιμο</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Βήμα 2: Περιβάλλοντα</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Βήμα 3: Διαδικασία requesting a delivery</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Βήμα 4: Destination Map</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Βήμα 5: Troubleshooting</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#6'>Βοήθεια</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex  align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/form-completed');
                    }}
                  >
                    Πίσω
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Tailor Made</h2>
          <h3 className='mt-5'>Πριν ξεκινήσετε</h3>

          <h5 className='mt-5' id='1'>
            Βήμα 1: Στήσιμο
          </h5>

          <p>
            <b>OAUTH_CLIENT_ID</b>
          </p>
          <p>
            Κρατήστε αυτή την τιμή ιδιωτική και ασφαλή! Αυτή είναι η OAuth2 Client ID που θα
            χρησιμοποιήσετε για να πιστοποιηθείτε με τα Partner API.
          </p>
          <p>
            <b>OAUTH_CLIENT_SECRET</b>
          </p>
          <p>
            Κρατήστε αυτή την τιμή ιδιωτική και ασφαλή! Αυτή είναι η OAuth2 Client Secret που θα
            χρησιμοποιήσετε για να πιστοποιηθείτε με τα Partner API.
          </p>
          <p>
            <b>API_URL</b>
          </p>
          <p>
            Αυτή είναι η Base URL για το Partner API, στo οποίο θα πρέπει να προσαρτίσετε τα σχετικά
            endpoint paths.
          </p>

          <h5 className='mt-5' id='2'>
            Βήμα 2: Περιβάλλοντα
          </h5>

          <p>
            <b>Sandbox /Stage</b>
          </p>
          <p>
            Περιβάλλον με περιορισμένες δυνατότητες όπου θα μπορείτε να δοκιμάσετε την διασύνδεση.
          </p>

          <p>
            <b>Production</b>
          </p>

          <p>
            Χρησιμοποιείτε αυτό το περιβάλλον με προσοχή, καθώς είναι ζωντανό και απευθύνετε σε
            πραγματικούς χρήστες.
          </p>

          <h5 className='mt-5' id='3'>
            Βήμα 3: Διαδικασία requesting a delivery
          </h5>

          <p>
            Ακολουθήστε τα βήματα ώστε να ζητήσετε επιτυχώς μία παράδοση και να πράξετε με τους
            σχετικούς τρόπους:
          </p>
          <p className='mt-5'>
            <u>3.1 Πιστοποιείστε τον εαυτό σας /auth sessions</u>
          </p>
          <p>Η πιστοποίηση γίνεται βάση του OAuth 2.0 standard, μέσω των Client Credentials.</p>
          <p>
            Για να χρησιμοποιήσετε τα API, πρέπει να βάλετε το access token στην επικεφαλήδα
            Authorization ως Bearer token.
          </p>
          <p>Παράδειγμα ορθής διασύνδεσης:</p>

          <p>POST /api/v1/ auth sessions</p>
          <p>{'{'}</p>
          <p>"grant_type”: "client_credentials”,</p>
          <p>"client_id": “string”,</p>
          <p>"client_secret": “string”</p>
          <p>{'}'}</p>
          <p>Status Code 200</p>
          <p>{'{'}</p>
          <p>"access_token”: “client_credentials”,</p>
          <p>"token_type": “Bearer”,</p>
          <p>"expires_in”: 3600</p>

          <p>{'}'}</p>
          <p>Επιπλέον απαντήσεις που μπορούν να προκύψουν:</p>
          <p>400</p>
          <p>
            Ο σέρβερ δεν μπορεί ή δε θα προχωρήσει το αίτημα λόγω κάποιου δικού σας λάθους (π.χ.
            λάθος σύνταξη, λάθος μήνυμα request). Πρέπει να ξαναδιαμορφώσετε το request πρωτού το
            ξαναστείλετε.
          </p>
          <p>401</p>
          <p>
            Μη εξουσιοδωτημένος. Χρησιμοποιείτε ένα ληγμένο Αccess token ή προσπαθείτε να ξεκινήσετε
            ένα Auth session με λάθος δεδομένα.
          </p>
          <p>403</p>
          <p>Ο λογαριασμός σας απενεργοποιήθηκε. Επικοινωνήστε με την υποστήριξη πελατών.</p>
          <p className='mt-5'>
            <u>3.2 Λίστα με όλες τις διαθέσιμες origins /origins</u>
          </p>
          <p>
            Αυτή η κλήση θα βγάλει μια λίστα με όλα τα διαθέσιμα σημεία παραραλαβής από τα οποία η
            BoxNow μπορεί να παραλάβει τα δέματα, συνήθως τις αποθήκες σας.
          </p>
          <p>
            Μπορείτε να δείτε όλες τις αποθήκες σας χρησιμοποιόντας /origins που έχει τις ίδιες
            παραμέτρους σαν την /destinations όπου δεν δηλώνετε συγκεκριμλενε παραμλετρους latlng,
            radius ή requiredSize, αλλά το locationType σαν “warehouse”. Αναφέρεστε σε αυτή την
            τοποθεσία μέσω του ID (locationid).
          </p>
          <p>
            Επιπέον υπάρχει μια συγκεκριμένη τοποθεσία, η any-apm που μπορεί να δηλωθεί με τον ίδιο
            τροπο χρησιμοποιώντας locationType ως “any-apm”, επιστρέφει μόνο ένα location-any-apm.
            Μπορείτε να αναφέρεστε σε αυτό με τοs ID (locationid) του. Αυτή η χρήση θα επεξηγηθεί
            στην επόμενη ενότητα.
          </p>
          <p>
            Παρακάτω βρίσκονται οι παράμετροι, διαθέσιμες σε εσάς για για να φιλτράρετε όλα τα
            Origin locations:
          </p>
          <div className='table-overflow'>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>locationType</td>
                  <td>string</td>
                  <td>
                    <p>
                      {' '}
                      Επιστρέφει μόνο τοποθεσίες ενός τύπου. Αν δεν είναι ενεργοποιημένο το φίλτρο
                      δεν εφαρμόζεται.
                    </p>
                    <ul>
                      <li>Διαθέσιμες τιμές: any-apm, warehouse</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>Παράδειγμα ορθής διασύνδεσης:</p>
          <p>GET /api/v1/ origins</p>
          <p>curl -X ‘GET’\</p>
          <p>'…/origins\</p>
          <p>-H 'accept: application/json’</p>
          <p>Status Code 200</p>
          <p>{'{'}</p>
          <p>"data": [</p>
          <p>{'{'}</p>
          <p>"id": "string",</p>
          <p>"type": "warehouse",</p>
          <p>"image": "https://via.placeholder.com/175",</p>
          <p>"lat": "48.940819584637266",</p>
          <p>"lng": "12.366962491028423",</p>
          <p>"title": "Warehouse 1",</p>
          <p>"name": "Main Warehouse",</p>
          <p>"addressLine1": "ΛΕΩΦΟΡΟΣ ΚΗΦΙΣΙΑΣ 155",</p>
          <p>"addressLine2": "ΑΘΗΝΑ",</p>
          <p>"postalCode": "14661",</p>
          <p>"country": "GR",</p>
          <p>"note": "Next to Super market"// can be null</p>
          <p>{'}'}</p>
          <p>]</p>
          <p>{'}'}</p>

          <p>Επιπλέον απαντήσεις που μπορεί να προκύψουν:</p>
          <p>Error Code</p>
          <p>400</p>
          <p>
            Ο σέρβερ δεν μπορεί ή δε θα προχωρήσει το αίτημα λόγω κάποιου δικού σας λάθους (π.χ.
            λάθος σύνταξη, λάθος μήνυμα request). Πρέπει να ξαναδιαμορφώσετε το request πρωτού το
            ξαναστείλετε.
          </p>
          <p>401</p>
          <p>
            Μη εξουσιοδωτημένος. Χρησιμοποιείτε ένα ληγμένο Αccess token ή προσπαθείτε να ξεκινήσετε
            ένα Auth session με λάθος δεδομένα.
          </p>
          <p>403</p>
          <p>Ο λογαριασμός σας απενεργοποιήθηκε. Επικοινωνήστε με την υποστήριξη πελατών.</p>
          <p className='mt-5'>
            <u>3.3.Λίστα με όλες τους διαθέσιμους προορισμούς /destinations</u>
          </p>
          <p>
            Αυτή ή κλήση θα απαριθμήσει όλες τα διαθέσιμες τοποθεσίες ΑPM (Automatic Parcel Machine)
            που μπορούμε να παραδώσουμε τα δέματά σας.
          </p>
          <p>
            Παρακάτω βρίσκοντε οι παράμετροι διαθέσιμες ώστε να φιλτράρετε όλες τις τοποθεσίες των
            ΑΡΜ:
          </p>
          <div className='table-overflow'>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>locationType</td>
                  <td>string</td>
                  <td>
                    <p>
                      Αν εφαρμοστεί, μόνο τοποθεσίες στην καθορισμένη ακτίνα από τις συγκεκριμένες
                      συντεταγμένες του GPS επιστρέφουν.
                    </p>
                    <ul>
                      <li>Διαθέσιμες τιμές: any-apm, warehouse</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>radius</td>
                  <td>number</td>
                  <td>
                    <p>
                      Ακτίνα σε μέτρα, επιστρέφει μόνο τοποθεσίες σε συγκεκριμένη ακτίνα από τις
                      συγκεκριμένες συντεταγμένες μέσω GPS. Αν το latlng δεν είναι παρών τότε η
                      εντολή αγνοείται.
                    </p>
                    <ul>
                      <li>Παράδειγμα: 1000</li>
                      <li>Defaolt Value: 25000</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>requiredSize</td>
                  <td>number</td>
                  <td>
                    <p>
                      Επέστρεψε μόνο τοποθεσίες που μπορούνα να δεχτούν ένα δέμα του δικού σας
                      requiredSize.
                    </p>
                    <ul>
                      <li>Παράδειγμα 1</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>locationType</td>
                  <td>string</td>
                  <td>
                    <p>
                      Επέστρεψε μόνο τοποθεσίες με συγκεκριμένο τύπο. Αν δεν ειναι παρούσα η εντολή,
                      τότε το φίλτρο δεν εφαρμόζεται.
                    </p>
                    <ul>
                      <li>Διαθέσιμες τιμές: apm, any-apm</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>Παράδειγμα ορθής διασύνδεσης:</p>
          <p>GET api/v1/ destinations</p>
          <p>curl X ‘GET’\</p>
          <p>‘.../destinations\</p>
          <p>-H 'accept: application/json'</p>
          <p>Status Code 200</p>
          <p>{'{'}</p>
          <p>"data": [</p>
          <p>{'{'}</p>
          <p>"id": "string",</p>
          <p>"type": "apm",</p>
          <p>
            "image":{' '}
            <a href='https://via.placeholder.com/150'>"https://via.placeholder.com/150",</a>
          </p>
          <p>"lat": "48.78081955454138",</p>
          <p>"lng": "12.446962472273063",</p>
          <p>"title": "ΠΑΝΤΕΛΟΓΛΟΥ ΔΗΜΗΤΡΗΣ",</p>
          <p>"name": "ΠΑΝΤΕΛΟΓΛΟΥ ΔΗΜΗΤΡΗΣ",</p>
          <p>"addressLine1": "ΛΕΩΦΟΡΟΣ ΕΙΡΗΝΗΣ 28",</p>
          <p>"addressLine2": "string",</p>
          <p>"postalCode": "15121",</p>
          <p>"country": "GR",</p>
          <p>"note": "You can find it behind the pet shop"</p>
          <p>{'}'}</p>
          <p>]</p>
          <p>{'}'}</p>
          <p>
            Αλλιώς, αναφερθείτε στο χωρίο 4 για ένα απόσπασμα Java Script που μπορείτε να
            συμπεριλάβετε στην σελίδα σας για να φαίνονται όλα τα διαθέσιμα ΑΡΜ μέσω ενός
            pop-up/iframe widget, ή για μια σύντομη περιγραφή επιτυχούς διασύνδεσης custom map.
          </p>
          <p>id</p>
          <p>
            Όταν ζητάτε μία παράδοση, θα αναφέρεστε σε αυτά τα αρχεία με το ID τους, συχνότερα το:
          </p>
          <p>locationId</p>
          <p>Επιπλέον απαντήσεις που μπορεί να προκύψουν:</p>
          <p>Error Code</p>
          <p>400</p>
          <p>
            Ο σέρβερ δεν μπορεί ή δε θα προχωρήσει το αίτημα λόγω κάποιου δικού σας λάθους (π.χ.
            λάθος σύνταξη, λάθος μήνυμα request). Πρέπει να ξαναδιαμορφώσετε το request πρωτού το
            ξαναστείλετε.
          </p>
          <p>401</p>
          <p>
            Μη εξουσιοδωτημένος. Χρησιμοποιείτε ένα ληγμένο Αccess token ή προσπαθείτε να ξεκινήσετε
            ένα Auth session με λάθος δεδομένα.
          </p>
          <p>403</p>
          <p>Ο λογαριασμός σας απενεργοποιήθηκε. Επικοινωνήστε με την υποστήριξη πελατών.</p>
          <p className='mt-5'>
            <u>3.4 Ζήτησε μία παράδοση /delivery requests</u>
          </p>
          <p>
            Χρησιμοποιήστε αυτό για να ζητήετε μία παραγγελία δέματος (ή πολλών δεμάτων). Αυτή είναι
            η κύρια κλήση που θα χρησιμοποιείτε για να δημιουργήσετε κάθε τύπο παραγγελιών.
          </p>
          <p>
            Μόλις μία επιτυχή παραγγελία δημιουργηθεί:
            <ul>
              <li>
                (optional) Θα σας αποστέλλουμε e-mail που θα σας ειδοποιεί για μία επιτυχώς
                δημιουργημένη παραγγελία με μία ετικέτα PDF. Η παράμετρος notifyOnAccepted
                αναπαράγεται από αυτήν τη λειτουργία (Βλέπε Appendix 6.3).
              </li>
              <li>
                (Described below) Εναλλακτικά, μπορείτε να λάβετε το αρχείο PDF για κάθε πακέτο μέσω
                της κλήσης: GET/parcels/id/label.pdf έπειτα εκυπώνετε το PDF και το κολλάτε στο
                πακέτο/πακέτα.
              </li>
              <li>
                Στέλνουμε τον courier να παραλλάβει τα πακέτα στις προκαθορισμένες ώρες παραλαβής.
              </li>
              <li>Επίσης ενημερώνουμε τον πελάτη.</li>
            </ul>
            <ol>
              <li>Παραλάβαμε την εντολή παραγγελίας και ένα δέμα θα παραδοθεί σε αυτούς.</li>
              <li>
                Παραδόσαμε επιτυχώς το πακέτο τους στο καθορισμένο APM με τις απαραίτητες
                λεπτομέριες για την παραλαβή του πακέτου.
              </li>
            </ol>
          </p>

          <p>Παράδειγμα επιτυχούς διασύνδεσης:</p>
          <p>POST api/v1/ delivery requests</p>
          <p>{'{'}</p>
          <p>"orderNumber": “string”,</p>
          <p>"invoiceValue": “25.50”,</p>
          <p>"paymentMode":”prepaid”,</p>
          <p>”amountToBeCollected": “0.00",</p>
          <p>"allowReturn”: true,</p>
          <p>"origin”:{'{'}</p>
          <p>"contactNumber":“+30 21 4 655 1234”,</p>
          <p>
            "contactEmail”: <span className='blue'>“partner.example@boxnow.gr”</span>,
          </p>
          <p>"contactName": “Kostas Kostantinidis",</p>
          <p>"locationId":”string”</p>
          <p>{'}'},</p>
          <p>“destination”:{'{'}</p>
          <p>"contactNumber": "+30 69 1 234 1234”,</p>
          <p>
            "contactEmail”: <span className='blue'>“customer.example@boxnow.gr”</span>,
          </p>
          <p>"contactName” "Yiannis Papadopoolos",</p>
          <p>"locationId":”string”</p>
          <p>{'}'},</p>
          <p>"items”: [</p>
          <p>{'{'}</p>
          <p>"id": “string”,</p>
          <p>"name": “Smartphone”</p>
          <p>"value”: “3.45”,</p>
          <p>"weight”: 0</p>
          <p>{'}'}</p>
          <p>]</p>
          <p>{'}'}</p>
          <p>
            <b>items: weight</b>
          </p>
          <p>Αν το βάρος του ατικειμένου είναι άγνωστο δώστε 0.</p>
          <p>Ο αριθμός που δίνεται σε αυτό το πεδίο θα πρέπει να είναι ακέραιος</p>
          <p>
            Αυτοί οι παράμετροι είναι το κύριο αναγνωριστικό για τις τοποθεσίες παραλαβής και
            παράδοσης:
          </p>
          <p>
            <b>origin: locationId</b>
          </p>
          <p>Η αποθήκη όπου το δέμα θα παραληφθεί.</p>
          <p>
            <b>destination: locationId</b>
          </p>
          <p>Automatic Parcel Machine (APM) τοποθεσία της θυρίδας παράδοσης</p>
          <p>Επίσης,</p>
          <p>μη ξεχνάτε να μας μεταφέρετε τα ακόλουθα προσωπικά στοιχία με κάθε παραγγελία:</p>
          <p>Αποστολέας:</p>
          <ol>
            <li>Όνομα</li>
          </ol>
          <p>Παραλήπτης:</p>
          <ol>
            <li>Όνομα</li>
            <li>Τηλεφωνικός αριθμός</li>
            <li>Email</li>
          </ol>
          <p>
            <b>Status Code 200</b>
          </p>
          <p>{'{'}</p>
          <p>"referenceNumber":”string”,</p>
          <p>"parcels”:[</p>
          <p>{'}'}</p>
          <p>"id":”string”</p>
          <p>{'}'}</p>
          <p>]</p>
          <p>{'}'}</p>
          <p>
            <b>Σημείωση</b>: Στο παραπάνω παράδειγμα, το αντικείμενο αφορά στο πακέτο, αλλά το ID
            του πακέτου είναι μοναδικό ID μέσω του e-shop (νούμερο αναφοράς, αν θέλετε). Εάν δεν
            έχετε μοναδικό ID για κάθε αντικείμενο τότε δημιουργείτε μέσω του αριθμού παραγγελίας με
            ακόλουθο το νούμερο του αντικειμένου ή με όποια σειρά θέλετε εσείς. Ενώ η{' '}
            <b>ταυτότητα δέματος</b> (parcel ID) είναι εσωτερική στην BoxNow, μια μοναδική ID
            χρησιμοποιείται επιπλέον που αφορά αυτό το δέμα.
          </p>

          <p>
            Για αποστολές από APM μπορείτε να χρησιμοποιείτε ως origin το any APM και προορισμό
            συγκεκριμένο APM.
          </p>

          <p>
            Για παράδοση στο APM όπου θα παραλλάβει ο πελάτης μπορείτε να χρησιμοποιείτε και origin
            and destination location any-APM.
          </p>
          <p>Επιπλέον απαντήσεις, που μπορεί να προκύψουν:</p>
          <p>Error Code</p>
          <p>400</p>
          <p>
            Ο σέρβερ δεν μπορεί ή δε θα προχωρήσει το αίτημα λόγω κάποιου δικού σας λάθους (π.χ.
            λάθος σύνταξη, λάθος μήνυμα request). Πρέπει να ξαναδιαμορφώσετε το request πρωτού το
            ξαναστείλετε.
          </p>
          <p>401</p>
          <p>
            Μη εξουσιοδωτημένος. Χρησιμοποιείτε ένα ληγμένο Αccess token ή προσπαθείτε να ξεκινήσετε
            ένα Auth session με λάθος δεδομένα.
          </p>
          <p>403</p>
          <p>Ο λογαριασμός σας απενεργοποιήθηκε. Επικοινωνήστε με την υποστήριξη πελατών.</p>
          <p className='mt-5'>
            <u>3.5 Fetch a PDF label /parcels/id/label.pdf</u>
          </p>
          <p>
            Use this call to request a .pdf file with a label you shoold print a stick onto each
            parcel.
          </p>
          <p>Only this parameter is available to you:</p>

          <div className='table-overflow'>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>id</td>
                  <td>string</td>
                  <td>Unique parcel number returned to you by method /delivery-requests</td>
                </tr>
              </tbody>
            </table>
            <div>
              <p>See an example of a successfol integration:</p>
              <p>GET /api/v1/ parcels</p>
              <p>curl -X 'GET' \</p>
              <p>‘.../parcels/id/label.pdf’ \</p>
              <p>-H 'accept: application/pdf’</p>
              <p>Status Code 200</p>
              <p>.pdf file with the corresponding label</p>
              <p>
                To print all PDF labels at once for your order, you can replace id with orderNumber:
              </p>
              <p>GET /api/v1/ delivery requests</p>
              <p>curl -X 'GET' \</p>
              <p>‘.../delivery-requests/orderNumber/label.pdf’ \</p>
              <p>-H 'accept: application/pdf’</p>
              <h5 className='mt-5' id='4'>
                Step 4: Destination Map (Widget /Custom
              </h5>
              <p>
                <u>4.1 Widget Integration</u>
              </p>
              <p>
                As an alternative to integrating our API, you can embed our ready made widget into
                your check out page. This widget is communicating with our API and includes the same
                data you can access via GET /api/v1/ destination.
              </p>
              <p>
                <u>How to install BoxNow Map Widget:</u>
              </p>
              <ol>
                <li>
                  Paste the BoxNow Map Widget JavaScript code into the checkout page (or any other
                  page where you want to display the BoxNow Map Widget).
                </li>
                <li>
                  Create new HTML button with className attribute boxnow-widget-button to open
                  BoxNow Map Widget. For example:{' '}
                  <pre>
                    &lt;a href="javascript:;" className="boxnow-widget-button"{'>'}Open
                    widget&lt;/a&gt;
                  </pre>
                </li>
                <li>
                  Create function for accept data from selected locker (id, address, name, etc.).
                </li>
              </ol>
              <p>
                <u>BoxNow Map Widget (Javascript Code):</u>
              </p>
              {/* <pre>

    <p>&lt;div id="boxnowmap"&gt;&lt;/div&gt;</p>
    <p>&lt;script type="text/javascript"&gt;</p>
    <p>var _bn_map_widget_config = {</p>
    <p>partnerId: 123,</p>
    <p>parentElement: "#boxnowmap"</p>
    <p>afterSelect: function(selected){</p>
    <p>alert(selected.boxnowLockerPostalCode);
    </p>
    <p>alert(selected.boxnowLockerAddressLine1);
    </p>
    <p>alert(selected.boxnowLockerId);

    </p>
    <p>}</p>
    <p>};</p>
    
    <p style="overflow: hidden;text-overflow: ellipsis;white-space: initial;">(function(d){var e = d.createElement("script");e.src = "https://widget-cdn.boxnow.gr/map-widget/client/v1.js";e.async = true;e.defer = true;d.getElementsByTagName("head")[0].appendChild(e);})(document);&lt;/script&gt;</p>

	</pre> */}
              <p>
                <b>Note</b>:The most important is variable _bn_map_widget_config. With this variable
                you can setup all required options , as shown below.
              </p>
              <div className='table-overflow'>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Usage</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>parentElement</td>
                      <td>required</td>
                      <td>
                        <p>
                          Please fill CSS selector for Map Widget container. For example, just
                          create{' '}
                        </p>
                        {/* <p style="overflow: hidden;text-overflow: ellipsis;white-space: initial;"><pre style="overflow: hidden;text-overflow: ellipsis;white-space: initial;">&lt;div id="boxnowmap"&gt;&lt;/div&gt; </pre> </p> */}
                        <p>
                          and fill #boxnowmap. The BoxNow map widget will be placed inside this
                          element.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>afterSelect</td>
                      <td>
                        <p>required for type:iframe</p>
                        <p>and type:popup</p>
                      </td>
                      <td>
                        <p>
                          Function that is triggered when the lock is selected. Included one
                          parameter (object) contains all information about locker (properties
                          boxnowLockerPostalCode, boxnowLockerAddressLine1 and boxnowLockerId are
                          the most important).
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>partnerId</td>
                      <td>optional</td>
                      <td>Please use your partnerId</td>
                    </tr>
                    <tr>
                      <td>type</td>
                      <td>optional</td>
                      <td>Use iframe, popup or navigate. Defaolt is iframe.</td>
                    </tr>
                    <tr>
                      <td>gps</td>
                      <td>optional</td>
                      <td>
                        Use it if you want to change the user's location request immediately after
                        displaying the map. Possible options are true or false. Defaolt is true.
                      </td>
                    </tr>
                    <tr>
                      <td>autoclose</td>
                      <td>optional</td>
                      <td>
                        Use it when you want to change what happens after you select a locker. For
                        type:iframe, the defaolt value is true, which means that the map will be
                        hidden when the locker is selected. For type:popup, autoclose is always
                        true. The possible values are true or false. The defaolt value is true.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                **For more integration examples you can refer to: widget{' '}
                <a href='http://v3.boxnow.gr/developers'>v3.boxnow.gr/developers</a>
              </p>
              <p>
                <u>4.2 Custom Map Integration</u>
              </p>
              <p>
                Our widget takes advantage of Google Maps Javascript API:{' '}
                <a href='https://developers.google.com/maps/apis'>
                  https://developers.google.com/maps/apis
                </a>
                -by-platform
              </p>
              <p>
                By calling <b>GET /api/v1/ destination</b>, you can obtain longitude as variable lng
                and latitude as variable lat of each delivery location, that you can then pass to
                the Google Maps API to display the location on the map:
              </p>
              <a
                className='text-break'
                href='https://developers.google.com/maps/documentation/javascript/adding'
              >
                https://developers.google.com/maps/documentation/javascript/adding
              </a>
              -a-google-map
              <ol>
                <li>id for locker ID</li>
                <li>image for a url with image of the locker</li>
                <li>name</li>
                <li>addressLine1 and addressLine2</li>
                <li>postalCode</li>
                <li>note for a detailed description of the lockerlocker’s location. </li>
              </ol>
              <h5 className='mt-5' id='5'>
                Step 5: Troubleshooting
              </h5>
              <p>Description of error codes for 400 Unprocessable entity responses:</p>
              <p>
                <b>Error Code P400</b>
              </p>
              <p>
                Invalid request data. Make sure you are sending the request according to the
                documentation.
              </p>
              <p>
                <b>Error Code P401</b>
              </p>
              <p>
                Invalid request origin location reference. Make sure you are referencing a valid
                location ID from Origins endpoint or valid address.
              </p>
              <p>
                <b>Error Code P402</b>
              </p>
              <p>
                Invalid request destination location reference. Make sure you are referencing a
                valid location ID from Destinations endpoint or valid address.
              </p>
              <p>
                <b>Error Code P403</b>
              </p>
              <p>
                You are not allowed to use AnyAPM-SameAPM delivery. Contact support if you believe
                this is a mistake.
              </p>
              <p>
                <b>Error Code P404</b>
              </p>
              <p>Invalid import CSV. See error contents for additional info.</p>
              <p>
                <b>Error Code P405</b>
              </p>
              <p>
                Invalid phone number. Make sure you are sending the phone number in foll
                international format, e.g. +30 xx x xxx xxxx.
              </p>
              <p>
                <b>Error Code P406</b>
              </p>
              <p>
                Invalid compartment/parcel size. Make sure you are sending one of required sizes 1,
                2 or 3 ( Medium or Large). Size is required when sen ding from AnyAPM directly.
              </p>
              <p>
                <b>Error Code P407</b>
              </p>
              <p>
                Invalid country code. Make sure you are sending country code in ISO 3166-1 alpha-2
                format, e.g. GR.
              </p>
              <p>
                <b>Error Code P410</b>
              </p>
              <p>
                Order number conflict. You are trying to create a delivery request for order ID that
                has already been created. Choose another order ID.
              </p>
              <p>
                <b>Error Code P411</b>
              </p>
              <p>
                You are not eligible to use Cash-on-delivery payment type. Use another payment type
                or contact our support.
              </p>
              <p>
                <b>Error Code P420</b>
              </p>
              <p>
                Parcel not ready for cancel. You can cancel only new, undelivered, or parcels that
                are not returned or lost. Make sure parcel is in transit and try again.
              </p>
              <p>
                <b>Error Code P430</b>
              </p>
              <p>
                Parcel not ready for AnyAPM confirmation. Parcel is probably already confirmed or
                being delivered. Contact support if you believe this is a mistake.
              </p>
              <h4 className='mt-5' id='6'>
                Βοήθεια:
              </h4>
              <p>
                If you are having troubles integrating our API into your online store based on the
                current documentation reach out to us at <span className='blue'>ict@boxnow.gr</span>
              </p>
              <h4 className='mt-5'>Σημειώσεις:</h4>
              <ol>
                <li>Testing plugin with stage Api keys.</li>
                <li>
                  Select stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
                  10447
                </li>
                <li>
                  When a new order is completed we will automatically send you a PDF shipping label.
                </li>
              </ol>
              <ul>
                <li>(POST) Authorization: baseURL/api/v1/auth-sessions</li>
                <li>(GET) Origins: baseURL/api/v1/origins</li>
                <li>(GET) Destinations: baseURL/api/v1/destinations</li>
                <li>(POST) Delivery-request: baseURL/api/v1/delivery-requests</li>
                <li>(GET) Parcel label: baseURL/api/v1/parcels/id/label.pdf</li>
                <li>(GET) Parcels: baseURL/api/v1/parcels</li>
                <li>(POST) Cancel parcel: baseURL/api/v1/parcels/id:cancel</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}
