import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import Prestashop1 from '../../assets/prestashop/PrestaShop1.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';
import t1 from '../../assets/partnerportal/t1.png';
import t2 from '../../assets/partnerportal/t2.png';
import t3 from '../../assets/partnerportal/t3.png';
import t4 from '../../assets/partnerportal/t4.png';
import t5 from '../../assets/partnerportal/t5.png';
import t6 from '../../assets/partnerportal/t6.png';
import t7 from '../../assets/partnerportal/t7.png';
import t8 from '../../assets/partnerportal/t8.png';

export default function PrestaShop() {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
      ol {
        padding-left: 32px;
      }
      li {
        text-align: left;
      }
      p {
        text-align: left;
        padding-left: 16px;
      }
      a {
        font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
        text-decoration: none;
        color: ${colors.secondary};
        font-weight: 500;
        -webkit-text-decoration: underline #f1bfdf 2px;
        text-decoration: underline #f1bfdf 2px;
        text-underline-position: under;
      }
      aside ul {
        padding-left: 16px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='container d-flex flex-row'>
        <div className='w-50 position-relative'>
          <aside className='sidebar hidden'>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4' href='https://boxnow.gr/en/diy/eshops/partner-portal'>
                  Partner Portal
                </a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Step 1: Login</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Step 2: Using the Partner Portal</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>2.1 Fill in the form</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>2.2 Upload a CSV file</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Support</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <span className='h5 d-flex align-items-center'>
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/form-completed');
                        }}
                      >
                        Go back
                      </a>
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
        </div>
        <div className='mobile-margin'>
          <h2>Partner Portal</h2>
          <h5 className='mt-5' id='1'>
            Step 1: Login
          </h5>
          <p>
            Your mobile phone number is entered and a code is received to log in to
            <a href='https://partner.boxnow.gr/login-code'>Box Now - Partner Portal </a>
          </p>

          <img className='mt-3 mb-3' src={t1} />

          <p>Insert 6digit code from SMS.</p>

          <img className='mt-3 mb-3' src={t2} />

          <h5 className='mt-5' id='2'>
            Step 2: Using the Partner Portal
          </h5>
          <p>Existing orders are displayed on the home screen.</p>

          <img className='mt-3 mb-3' src={t3} />

          <p>
            To create an order we click on the button
            <span className='bolder'>Create order {'-> '}From Warehouse</span>.
          </p>

          <img className='mt-3 mb-3' src={t4} />

          <p>There are two options.</p>
          <ol>
            <li>Fill in the form</li>
            <li>Upload a CSV file</li>
          </ol>

          <p id='3'>
            <u>2.1 Fill in the form:</u>
          </p>
          <p>These fields are required (Your customer’s details)</p>

          <ul className='list-inside'>
            <li>Full name (e.g. George Papas)</li>
            <li>Phone number (e.g. +306912345678)</li>
            <li>
              Email (π.χ. <a href='mailto: test@test.gr'>test@test.gr</a>)
            </li>
            <li>APM number (locker id)</li>
            <li>Payment method (Prepaid or Cash on delivery)</li>
            <li>
              Return (always <span className='bolder'>allowed</span>)
            </li>
          </ul>

          <p className='mt-3'>
            Press <span className='bolder'>Create order</span>
          </p>

          <img className='mt-3 mb-3' src={t5} />

          <p>
            As soon as the order is created, the registration will be made on the home page and the
            notification for the voucher will come.
          </p>

          <img className='mt-3 mb-3' src={t6} />

          <p>
            Click on <span className='bolder'>Get parcel labels</span> the voucher is displayed.
          </p>

          <p className='mt-5' id='4'>
            <u>2.2 Upload a CSV file:</u>
          </p>
          <p>First of all download csv example file.</p>

          <img className='mt-3 mb-3' src={t7} />

          <p>Open the csv file and complete customer details.</p>

          <img className='mt-3 mb-3' src={t8} />

          <ul className='list-inside mt-5'>
            <li>
              <span className='bolder'>from_location</span>: Warehouse ID (constant value, if ypu
              have one warwhouse else picked the warehouse)
            </li>
            <li>
              <span className='bolder'>destination_location</span>: Locker ID (chosen by customer)
              e.g. 187
            </li>
            <li>
              <span className='bolder'>customer_phone_number</span>: e.g. +30 21 4 655 1234
            </li>
            <li>
              <span className='bolder'>customer_email</span>: e.g. someone@example.com
            </li>
            <li>
              <span className='bolder'>customer_full_name</span>: e.g. Test Test
            </li>
            <li>
              <span className='bolder'>number_of_parcels</span>: e.g. 2
            </li>
            <li>
              <span className='bolder'>payment_mode</span>: cod (Cash on delivery) or Prepaid
            </li>
            <li>
              <span className='bolder'>amount_to_be_collected</span>: e.g. 1
            </li>
            <li>
              <span className='bolder'>price_currency</span>: e.g. EUR{' '}
            </li>
          </ul>

          <p className='mt-3'>
            Once the columns are filled in, we save the csv file and upload it by clicking on
            <u className='bolder'> upload from your computer.</u>
          </p>

          <p className='mt-5' id='5'>
            <u className='bolder'>CAUTION</u>: The file must be as such and only. Any other from
            will bring an error.
          </p>

          <p>
            The site for finding the IDs locker for your Call Agents is:
            <a href='https://boxnow.gr/locker-finder'>BOX NOW Website - Locker Finder GR</a>{' '}
          </p>

          <h5 className='mt-5'>Get Support</h5>

          <p>
            If you have any questions about our solutions, or questions about how to integrate with
            our solutions, please refer to our email{' '}
            <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>.
          </p>
        </div>
      </div>
    </Styles>
  );
}
