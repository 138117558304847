import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Typography, Theme, Container } from '@mui/material';
import magento1 from '../../assets/magento/magento1.png';
import magento2 from '../../assets/magento/magento2.png';
import magento3 from '../../assets/magento/magento3.png';
import magento4 from '../../assets/magento/magento4.png';
import magento5 from '../../assets/magento/magento5.png';
import magento6 from '../../assets/magento/magento6.png';
import magento7 from '../../assets/magento/magento7.png';
import magento8 from '../../assets/magento/magento8.png';
import magento9 from '../../assets/magento/magento9.png';
import magento10 from '../../assets/magento/magento10.png';
import magento11 from '../../assets/magento/magento11.png';
import { colors } from '../../theme/theme';

export const Magento1en = () => {
  const navigate = useNavigate();

  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <Container></Container>
      <div className='d-flex flex-row'>
        <div className='hidden sidebar'>
          <aside>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>Magento</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Εγκατάσταση</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Ρυθμίσεις Συστήματος </a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Δοκιμή Plugin </a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Έκδοση – Εκτύπωση Voucher </a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Βοήθεια</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <span className='h5 d-flex align-items-center'>
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/eshops');
                        }}
                      >
                        Πίσω
                      </a>
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Magento</h2>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>{' '}
            <a href='https://prestashopzip.blob.core.windows.net/magento1zip/Boxnow_Magento_1.9 (v1.2).zip'>
              Boxnow_Magento_1.9.zip
            </a>
          </div>
          <h3 className='mt-5' id='1'>
            Setup instructions
          </h3>
          <h4 className='mt-5'>Installing the plugin</h4>

          <p>
            The installation of the plugin is carried out with the default and recommended method
            that the extension for Magento 1 is installed. Here are the steps as detailed in the
            official guide:
          </p>

          <ol>
            <li>
              We connect to the Server via <span style={{ fontWeight: 'bold' }}>FTP</span>.
            </li>
            <li>We tranfer and unzip the extension file to the root folder of the site.</li>
            <li className='pb-3'>
              We log in to its administrative site, go to the section
              <span style={{ fontWeight: 'bold' }}>
                system`{'->'}`tools`{'->'}`backups
              </span>{' '}
              and we create Backup by clicking "System Backup”.
            </li>

            <img src={magento1} />
            <br />
            <br />

            <li>
              We go to the section{' '}
              <span style={{ fontWeight: 'bold' }}>
                system`{'->'}`tools`{'->'}`Compilations
              </span>
              and disable compilations.
            </li>
            <li className='pb-3'>
              We go to the section{' '}
              <span style={{ fontWeight: 'bold' }}>System`{'->'}` Cache Management</span> section
              and clear the cache memory. We select all the folders from the list, select the
              Refresh option and click the <span style={{ fontWeight: 'bold' }}>“Submit”</span>{' '}
              button.
            </li>

            <img src={magento2} />
            <br />
            <br />

            <li>
              We go to the section{' '}
              <span style={{ fontWeight: 'bold' }}>
                System`{'->'}` Configuration`{'->'}`Advanced
              </span>
              , search for the module and activate it by selecting
              <span style={{ fontWeight: 'bold' }}>Enable → Save Config</span>.
            </li>
          </ol>

          <h4 className='mt-5' id='2'>
            System Settings
          </h4>

          <p>
            The plugin has been installed, the next step is to go to the
            <span style={{ fontWeight: 'bold' }}>
              System`{'->'}` Configuration`{'->'}`Shipping Methods{' '}
            </span>
            of the administrative enviroment where we will see how a new shipping method has been
            created named "BOX NOW Delivery Service".{' '}
          </p>

          <p>
            The tab contains the settings such as the value, the display name, the countries for
            which the shipping method will be available and the interfaces with the Api of BOX NOW.
            In the field «Warehouse Number» we can insert more than one pickup points so tha we can
            choose it. Each pickup point will be below the previous one in separate line and will be
            like following example:
          </p>

          <img src={magento3} />
          <br />
          <br />
          <img src={magento4} />
          <br />
          <br />

          <p>
            We activate the shipping method and <span style={{ fontWeight: 'bold' }}>Save</span>.
          </p>

          <h4 className='mt-5' id='3'>
            Using the plugin
          </h4>
          <p>
            We make a test order by choosing the shipping method
            <span style={{ fontWeight: 'bold' }}>BOX NOW Delivery Services</span>
          </p>

          <img src={magento5} />
          <br />
          <br />
          <p>We choose one of the available lockers</p>

          <img src={magento6} />
          <br />
          <br />

          <p>We complete the order.</p>

          <img src={magento7} />
          <br />
          <br />
          <p>
            We go from administrative menou to the
            <span style={{ fontWeight: 'bold' }}>BOX NOW → Happyonline BOX NOW Module</span>
          </p>

          <img src={magento8} />
          <br />
          <br />
          <p>
            We create vouchers by selecting Create Voucher button, setting the number we want. In
            the "Locker ID" column we see the number of the pickup point that the customer has
            selected in the order. If we wish to change the point, we can do so by clicking on the
            number, a window will appear so that we can select another pickup point.
          </p>

          <img src={magento9} />
          <br />
          <p>
            Now we see that the status for this order is “Created”. We can locate parcel by clicking
            on its number, under the parcels column, opens the package’s tracking page directly.
          </p>

          <img src={magento10} />
          <br />
          <br />
          <p>
            Below the Info column there are links to the number of each voucher where by clicking
            them, we can store them locally.
          </p>
          <img src={magento11} />
          <br />
          <br />

          <h4 className='mt-5' id='4'>
            Notes:
          </h4>
          <ol>
            <li>Testing plugin with stage Api keys.</li>
            <li>
              Select stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
              10447
            </li>
            <li>
              When a new order is completed we will automatically send you a PDF shipping label.
            </li>
          </ol>
          <h4 className='mt-5'>Get Support</h4>
          <p>
            If you have any questions about our solutions, or questions about how to integrate with
            our solutions, please refer to our email <span className='blue'>ict@boxnow.gr</span>.
          </p>
        </div>
      </div>
    </Styles>
  );
};
