import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import Prestashop1 from '../../assets/prestashop/PrestaShop1.png';
import Prestashop2 from '../../assets/prestashop/PrestaShop2.png';
import Prestashop3 from '../../assets/prestashop/PrestaShop3.png';
import Prestashop4 from '../../assets/prestashop/PrestaShop4.png';
import Prestashop5 from '../../assets/prestashop/PrestaShop5.png';
import Prestashop6 from '../../assets/prestashop/PrestaShop6.png';
import Prestashop7 from '../../assets/prestashop/PrestaShop7.png';
import Prestashop8 from '../../assets/prestashop/PrestaShop8.png';
import Prestashop9 from '../../assets/prestashop/PrestaShop9.png';
import Prestashop10 from '../../assets/prestashop/PrestaShop10.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';

export const PrestaShopEn = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
      ol {
        padding-left: 32px;
      }
      li {
        text-align: left;
      }
      p {
        text-align: left;
        padding-left: 16px;
      }
      a {
        font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
        text-decoration: none;
        color: ${colors.secondary};
        font-weight: 500;
        -webkit-text-decoration: underline #f1bfdf 2px;
        text-decoration: underline #f1bfdf 2px;
        text-underline-position: under;
      }
      aside ul {
        padding-left: 16px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='container d-flex flex-row'>
        <div className='w-50 position-relative'>
          <aside className='sidebar hidden'>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>Presta Shop</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Step 1: Installation</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Step 2: Filling out your details</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Step 3: Configuring payment options</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Step 4: Using the plugin</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Get Support</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex  align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/eshops');
                    }}
                  >
                    Go back
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Presta Shop</h2>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a href='https://prestashopzip.blob.core.windows.net/prestashopzip/prestashop-1.7-refactor-master (small).zip'>
              boxnow_prestashop_1.7.8-1.7.9_small.zip
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a href='https://prestashopzip.blob.core.windows.net/prestashopzip/prestashop-1.7-refactor-master (medium).zip'>
              boxnow_prestashop_1.7.8-1.7.9_medium.zip
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a href='https://prestashopzip.blob.core.windows.net/prestashopzip/prestashop-1.7-refactor-master (large).zip'>
              boxnow_prestashop_1.7.8-1.7.9_large.zip
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Download:</span>
            <a href='https://prestashopzip.blob.core.windows.net/prestashopzip/prestashop-8.1.2-main 2.zip'>
              prestashop-8.1.2.zip
            </a>
          </div>
          <p className='mt-5'>
            *To install the Plugins in presta you will need to unzip the file, rename the inner
            folder to boxnow and rezip it.
          </p>
          <h3 className='mt-5'>Setup instructions</h3>
          <h5 className='mt-5' id='1'>
            Step 1: Installation
          </h5>
          <p>
            To install the plugin in our administration we go to
            <span className='bolder'>Modules → Module Manager → Upload a module</span>.{' '}
          </p>

          <img src={Prestashop1} alt='BoxNow' />

          <p>
            A new window will open in which we will choose to upload a new file from our computer.
            Select file "boxnow.zip" and automatically the installation of the module will begin.
          </p>

          <img src={Prestashop2} alt='BoxNow' />

          <h5 className='mt-5' id='2'>
            Step 2: Filling out your details
          </h5>

          <p>
            You can open the plugin's administration to insert your details from{' '}
            <span className='bolder'>Modules → Module Manager</span>.{' '}
          </p>
          <p>
            Click on the <span className='bolder'>Configure button</span>.
          </p>
          <img src={Prestashop3} alt='BoxNow' />

          <p>A new page will appear with all the customiztion fields. </p>
          <p>
            Insert the API credentials you had previously received from us and email you will
            receive the orders vouchers here:
          </p>
          <img src={Prestashop4} alt='BoxNow' />

          <p>
            <span className='bolder'>API URL</span>:(e.g.: box-now-zvvzxxssazzq-ew.a.run.app).
          </p>
          <p>
            <span className='bolder'>Client ID</span>:(e.g. 9d8gg3de-8119-4tea-991e-37ff20eq5e50).
          </p>
          <p>
            <span className='bolder'>Client Secret</span>:(e.g.{' '}
            <span className='word-break'>
              c1144abc1db1f3186442a4ec3d3e9 53de0105c12759a2d82771a888256659e67f).
            </span>
          </p>
          <p>
            <span className='bolder'>Warehouse Number</span>: e.g. 4098, 4099 etc.
          </p>

          <p>
            <span className='bolder'>Partner ID</span>: e.g. 202.
          </p>
          <p>
            <span className='bolder'>Email</span>: where there PDF vouchers send.
          </p>
          <p>
            <span className='bolder'>Μap customization</span>: popup/iframe.
          </p>
          <p>
            <span className='bolder'>Boxnow Button text</span>: edit the colour and text of the
            button that opens the map.
          </p>
          <p>Make sure to save any changes.</p>

          <h5 className='mt-5' id='3'>
            Step 3: Configuring payment options
          </h5>
          <p>
            With the installation of the module, the default set in the Prestashop will be set as a
            cost value. To change the prices go to
            <span className='bolder'>Shipping → Carriers → Edit (BoxNow)</span>.
          </p>

          <img src={Prestashop5} alt='BoxNow' />
          <img src={Prestashop6} alt='BoxNow' />

          <h5 className='mt-5' id='4'>
            Step 4: Using the plugin
          </h5>

          <img src={Prestashop7} alt='BoxNow' />

          <p>
            When the order is ready for pickup you <span className='bolder'>Select Warehouse</span>{' '}
            and the
            <span className='bolder'>Create Vouchers</span> to create a new shipment and return
            voucher number.{' '}
          </p>
          <p>If everything is correct it will show the proper voucher number.</p>

          <img src={Prestashop8} alt='BoxNow' />

          <p>
            Clicking on the number will open a new page or ask you to download the PDF file with the
            voucher.
          </p>
          <p>
            If you click on <span className='bolder'>Cancel Voucher</span> the voucher will be
            cancelled and must create a new one (voucher will be cancelled if it has not been
            received by BoxNow).
          </p>
          <p>
            You can also print multiple vouchers for each order (depending on the number of
            products), in case you cannot send all the products with one shipment.
          </p>
          <p>
            {' '}
            In case the locker chosen by the customer has a problem and does not work, BoxNow will
            give you a new locker ID which you must update by clicking{' '}
            <span className='bolder'>Change</span>.
          </p>

          <img src={Prestashop9} alt='BoxNow' />

          <p>
            In the new window that will appear write the new locker ID and{' '}
            <span className='bolder'>Update</span>.
          </p>

          <img src={Prestashop10} alt='BoxNow' />

          <h5 className='mt-5' id='5'>
            Notes:
          </h5>

          <ol>
            <li>Testing plugin with stage Api keys.</li>
            <li>
              Select stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
              10447
            </li>
            <li>
              When a new order is completed we will automatically send you a PDF shipping label.
            </li>
          </ol>

          <h5 className='mt-5'>Get Support</h5>
          <p>
            If you have any questions about our solutions, or questions about how to integrate with
            our solutions, please refer to our emai{' '}
            <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
