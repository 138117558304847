import CloseIcon from "@mui/icons-material/Close";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import {
  Button,
  Container,
  Paper,
  Divider,
  Typography,
  ClickAwayListener,
  useTheme,
  useMediaQuery,
  Link,
} from "@mui/material";
import { useState } from "react";

import useTranslation from "../../translations/useTranslation";

import arrow from "../../assets/arrow.png";
import headerarrow from "../../assets/headerarrow.png";
import header from "../../assets/header.png";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getTranslations, p2pSelector } from "../../features/p2pSlice";
import { Locale } from "api/types";

interface HeaderProps {
  footerRef: React.RefObject<HTMLDivElement>;
}

const Header = (props: HeaderProps) => {
  const theme = useTheme();
  const isLowerLG = useMediaQuery(theme.breakpoints.down("lg"));

  const [menuCount, setMenuCount] = useState(0);
  const [openMenu, setOpenMenu] = useState(true);
  const [toggleMenuMobile, setToggleMenuMobile] = useState(false);

  const { headerOptions } = useAppSelector(p2pSelector);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleLanguageChange = (locale: Locale) => {
    dispatch(getTranslations(locale));
  };

  const handleClickAway = () => {
    setMenuCount(0);
    setOpenMenu(false);
  };

  return isLowerLG ? (
    <div className="container-mob">
      <div className="appbar-d">
        <Link href={window.location.pathname.includes("/en") ? "/en" : "/"}>
          <img
            src={header}
            className="image-header"
            width="83px"
            height="60px"
            alt="BoxNow Logo"
          />
        </Link>
        {toggleMenuMobile ? (
          <CloseIcon
            onClick={() => setToggleMenuMobile(!toggleMenuMobile)}
            className="appbar-icons"
          />
        ) : (
          <DragHandleIcon
            onClick={() => setToggleMenuMobile(!toggleMenuMobile)}
            className="appbar-icons"
          />
        )}
      </div>
      <Divider className="drawer-divider" />
      {toggleMenuMobile && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="appbar-sec">
            <div
              className={
                menuCount === 1 ? "navigation-mob-active" : "navigation-mob"
              }
            >
              <Link
                className="anchor-cursor"
                onClick={() => {
                  setMenuCount(menuCount === 1 ? 0 : 1);
                  setOpenMenu(true);
                }}
              >
                <Typography className="menu-typo">
                  {t("boxnow.services")}
                </Typography>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </Link>
            </div>
            {menuCount === 1 && (
              <div className="mob-menu-div">
                {headerOptions
                  .filter(
                    (item) =>
                      item.group === "Services" || item.group === "Υπηρεσίες"
                  )
                  .sort((a, b) => a.key - b.key)
                  .map(({ title, link }) => (
                    <Link href={link} target="_blank" className="menu-header-a">
                      <div className="menu-header-a-div">
                        <img src={headerarrow} alt="" />
                      </div>

                      <Typography className="menu-typo-s">{title}</Typography>
                    </Link>
                  ))}
              </div>
            )}
            <div
              className={
                menuCount === 2 ? "navigation-mob-active" : "navigation-mob"
              }
            >
              <Link
                className="anchor-cursor"
                onClick={() => {
                  setMenuCount(menuCount === 2 ? 0 : 2);
                  setOpenMenu(true);
                }}
              >
                <Typography className="menu-typo">
                  {t("customers.trans")}
                </Typography>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </Link>
            </div>
            {menuCount === 2 && (
              <div className="mob-menu-div">
                {headerOptions
                  .filter(
                    (item) =>
                      item.group === "Customers" || item.group === "Πελάτες"
                  )
                  .sort((a, b) => a.key - b.key)
                  .map(({ title, link }) => (
                    <Link href={link} target="_blank" className="menu-header-a">
                      <div className="menu-header-a-div">
                        <img src={headerarrow} alt="" />
                      </div>

                      <Typography className="menu-typo-s">{title}</Typography>
                    </Link>
                  ))}
              </div>
            )}
            <div
              className={
                menuCount === 3 ? "navigation-mob-active" : "navigation-mob"
              }
            >
              <Link
                className="anchor-cursor"
                onClick={() => {
                  setMenuCount(menuCount === 3 ? 0 : 3);
                  setOpenMenu(true);
                }}
              >
                <Typography className="menu-typo">
                  {t("partners.trans")}
                </Typography>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </Link>
            </div>
            {menuCount === 3 && (
              <div className="mob-menu-div">
                {headerOptions
                  .filter(
                    (item) =>
                      item.group === "Partners" || item.group === "Συνεργάτες"
                  )
                  .sort((a, b) => a.key - b.key)
                  .map(({ title, link }) => (
                    <Link href={link} target="_blank" className="menu-header-a">
                      <div className="menu-header-a-div">
                        <img src={headerarrow} alt="" />
                      </div>

                      <Typography className="menu-typo-s">{title}</Typography>
                    </Link>
                  ))}
              </div>
            )}
            <div
              className={
                menuCount === 4 ? "navigation-mob-active" : "navigation-mob"
              }
            >
              <Link
                className="anchor-cursor"
                onClick={() => {
                  setMenuCount(menuCount === 4 ? 0 : 4);
                  setOpenMenu(true);
                }}
              >
                <Typography className="menu-typo">
                  {t("about.trans")}
                </Typography>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </Link>
            </div>
            {menuCount === 4 && (
              <div className="mob-menu-div">
                {headerOptions
                  .filter(
                    (item) =>
                      item.group === "About Us" ||
                      item.group === "Σχετικά με εμάς"
                  )
                  .sort((a, b) => a.key - b.key)
                  .map(({ title, link }) => (
                    <Link href={link} target="_blank" className="menu-header-a">
                      <div className="menu-header-a-div">
                        <img src={headerarrow} alt="" />
                      </div>

                      <Typography className="menu-typo-s">{title}</Typography>
                    </Link>
                  ))}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography className="contact-header-typo-mob">
                    {t("contact.us.box")}
                  </Typography>
                  <Link href="tel:+302111005304" className="contact-a-link">
                    <Typography className="contact-text-typo-mob">
                      +30 211-100 5304
                    </Typography>
                  </Link>
                  <Link href="mailto:info@boxnow.gr" className="contact-a-link">
                    <Typography className="contact-text-typo-mob">
                      info@boxnow.gr
                    </Typography>
                  </Link>

                  <Button
                    onClick={() =>
                      props.footerRef.current?.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                    className="see-details-button-mob"
                  >
                    <Typography className="see-details-typo">
                      {t("see.details")}
                    </Typography>
                  </Button>
                </div>
              </div>
            )}
            <div className="lang-div">
              <Button
                onClick={() => {
                  handleLanguageChange("el");
                  //setActiveLang("el");
                  if (window.location.pathname.includes("/en")) {
                    const newPath = window.location.pathname.slice(3);
                    console.log("pathname", newPath);
                    window.history.pushState({}, "", newPath);
                  }
                }}
                className={
                  !window.location.pathname.includes("/en")
                    ? "header-buttons-mob-active"
                    : "header-buttons-mob"
                }
              >
                <Typography className="header-typo">GR</Typography>
              </Button>
              <Button
                onClick={() => {
                  handleLanguageChange("en");
                  //setActiveLang("en");
                  if (!window.location.pathname.includes("/en")) {
                    const newPath = "/en" + window.location.pathname;
                    console.log("pathname", newPath);
                    window.history.pushState({}, "", newPath);
                  }
                }}
                className={
                  window.location.pathname.includes("/en")
                    ? "header-buttons-mob-active"
                    : "header-buttons-mob"
                }
              >
                <Typography className="header-typo">EN</Typography>
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  ) : (
    <Container className="header">
      <div className="f-div">
        <Button
          onClick={() => {
            handleLanguageChange("el");
            if (window.location.pathname.includes("/en")) {
              const newPath = window.location.pathname.slice(3);
              console.log("pathname", newPath);
              window.history.pushState({}, "", newPath);
            }
          }}
          className={
            !window.location.pathname.includes("/en")
              ? "header-buttons-active"
              : "header-buttons"
          }
        >
          <Typography className="header-typo">GR</Typography>
        </Button>
        <Button
          onClick={() => {
            handleLanguageChange("en");
            if (!window.location.pathname.includes("/en")) {
              const newPath = "/en" + window.location.pathname;
              console.log("pathname", newPath);
              window.history.pushState({}, "", newPath);
            }
          }}
          className={
            window.location.pathname.includes("/en")
              ? "header-buttons-active"
              : "header-buttons"
          }
        >
          <Typography className="header-typo">EN</Typography>
        </Button>
      </div>
      <div className="s-div">
        <div className="s-f-div">
          <Link href={window.location.pathname.includes("/en") ? "/en" : "/"}>
            <img
              src={header}
              className="image-header"
              style={{ height: "116px" }}
              alt="BoxNow Logo"
            />
          </Link>
        </div>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="s-s-div">
            <div
              className={menuCount === 1 ? "navigation-active" : "navigation"}
              style={{ borderRight: 0 }}
            >
              <Link
                className="anchor-cursor"
                onClick={() => {
                  setMenuCount(menuCount === 1 ? 0 : 1);
                  setOpenMenu(true);
                }}
              >
                <Typography className="menu-typo">
                  {t("boxnow.services")}
                </Typography>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </Link>
            </div>
            <div
              className={menuCount === 2 ? "navigation-active" : "navigation"}
            >
              <Link
                className="anchor-cursor"
                onClick={() => {
                  setMenuCount(menuCount === 2 ? 0 : 2);
                  setOpenMenu(true);
                }}
              >
                <Typography className="menu-typo">
                  {t("customers.trans")}
                </Typography>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </Link>
            </div>
            <div
              className={
                menuCount === 3 ? "navigation-s-active" : "navigation-s"
              }
            >
              <Link
                className="anchor-cursor"
                onClick={() => {
                  setMenuCount(menuCount === 3 ? 0 : 3);
                  setOpenMenu(true);
                }}
              >
                <Typography className="menu-typo">
                  {t("partners.trans")}
                </Typography>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </Link>
            </div>
            <div
              className={menuCount === 4 ? "navigation-active" : "navigation"}
            >
              <Link
                className="anchor-cursor"
                onClick={() => {
                  setMenuCount(menuCount === 4 ? 0 : 4);
                  setOpenMenu(true);
                }}
              >
                <Typography className="menu-typo">
                  {t("about.trans")}
                </Typography>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </Link>
            </div>
            {menuCount !== 0 && openMenu && (
              <div className="menu-header-div">
                <Paper className="menu-header-paper">
                  {menuCount === 1 ? (
                    headerOptions
                      .filter(
                        (item) =>
                          item.group === "Services" ||
                          item.group === "Υπηρεσίες"
                      )
                      .sort((a, b) => a.key - b.key)
                      .map(({ title, link }) => (
                        <Link
                          href={link}
                          target="_blank"
                          className="menu-header-a"
                        >
                          <div className="menu-header-a-div">
                            <img src={headerarrow} alt="" />
                          </div>

                          <Typography className="menu-typo-s">
                            {title}
                          </Typography>
                        </Link>
                      ))
                  ) : menuCount === 2 ? (
                    headerOptions
                      .filter(
                        (item) =>
                          item.group === "Customers" || item.group === "Πελάτες"
                      )
                      .sort((a, b) => a.key - b.key)
                      .map(({ title, link }) => (
                        <Link
                          href={link}
                          target="_blank"
                          className="menu-header-a"
                        >
                          <div className="menu-header-a-div">
                            <img src={headerarrow} alt="" />
                          </div>

                          <Typography className="menu-typo-s">
                            {title}
                          </Typography>
                        </Link>
                      ))
                  ) : menuCount === 3 ? (
                    headerOptions
                      .filter(
                        (item) =>
                          item.group === "Partners" ||
                          item.group === "Συνεργάτες"
                      )
                      .sort((a, b) => a.key - b.key)
                      .map(({ title, link }) => (
                        <Link
                          href={link}
                          target="_blank"
                          className="menu-header-a"
                        >
                          <div className="menu-header-a-div">
                            <img src={headerarrow} alt="" />
                          </div>

                          <Typography className="menu-typo-s">
                            {title}
                          </Typography>
                        </Link>
                      ))
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        {headerOptions
                          .filter(
                            (item) =>
                              item.group === "About Us" ||
                              item.group === "Σχετικά με εμάς"
                          )
                          .sort((a, b) => a.key - b.key)
                          .map(({ title, link }) => (
                            <Link
                              href={link}
                              target="_blank"
                              className="menu-header-a"
                            >
                              <div className="menu-header-a-div">
                                <img src={headerarrow} alt="" />
                              </div>

                              <Typography className="menu-typo-s">
                                {title}
                              </Typography>
                            </Link>
                          ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography className="contact-header-typo">
                          {t("contact.us.box")}
                        </Typography>
                        <Link
                          href="tel:+302111005304"
                          className="contact-a-link"
                        >
                          <Typography className="contact-text-typo">
                            +30 211-100 5304
                          </Typography>
                        </Link>
                        <Link
                          href="mailto:info@boxnow.gr"
                          className="contact-a-link"
                        >
                          <Typography className="contact-text-typo">
                            info@boxnow.gr
                          </Typography>
                        </Link>

                        <Button
                          onClick={() =>
                            props.footerRef.current?.scrollIntoView({
                              behavior: "smooth",
                            })
                          }
                          className="see-details-button"
                        >
                          <Typography className="see-details-typo">
                            {t("see.details")}
                          </Typography>
                        </Button>
                      </div>{" "}
                    </div>
                  )}
                </Paper>
              </div>
            )}
          </div>
        </ClickAwayListener>
        <div className="s-t-div"></div>
      </div>
    </Container>
  );
};

export default Header;
