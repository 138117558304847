import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Link, Theme, Typography } from "@mui/material";
import { colors } from "theme/theme";
import p2pIcon from "../assets/services/p2p-icon.png";
import packageDeliveryIcon from "../assets/services/package-delivery-icon.png";
import returnsIcon from "../assets/services/returns-icon.png";
import payOnTheGoIcon from "../assets/services/pay-on-the-go-icon.png";
import useTranslation from "translations/useTranslation";
import { Container } from "@mui/system";

const Styles = withTheme(styled.div`
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(5)};
  padding: ${({ theme }: { theme: Theme }) => theme.spacing(10, 0)};
  background-color: #f3f3ec;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .header-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2.25rem;
    color: ${colors.secondary};
  }

  .service-container {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("md")} {
      flex-direction: column;
      align-items: center;
      gap: 0.5em;
    }
  }
`);

type ServiceCardStyleProps = {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  textWidth?: string;
  theme: any;
};

const ServiceCardStyle = withTheme(styled.div<ServiceCardStyleProps>`
  background-color: ${(props) => props.backgroundColor};
  width: 18%;

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("md")} {
    width: 300px;
  }

  &:hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor ?? props.backgroundColor};
  }

  a {
    text-decoration: none;
  }

  .icon-row {
    margin: ${({ theme }: { theme: Theme }) => theme.spacing(1)};
    display: flex;
  }

  .text-row {
    margin: ${({ theme }: { theme: Theme }) => theme.spacing(1)};
    display: flex;
    justify-content: space-between;
  }

  .service-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-size: 1.2rem;
    color: ${(props) => props.color ?? colors.secondary};
    width: ${(props) => props.textWidth ?? "65%"};
    text-align: start;
  }

  .service-arrow {
    align-self: flex-end;
    font-size: 3rem;
    color: ${(props) => props.color ?? colors.secondary};
  }
`);

type ServiceCardProps = {
  text?: string;
  icon?: string;
  color?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  link?: string;
  textWidth?: string;
};

const ServiceCard = (props: ServiceCardProps) => {
  return (
    <ServiceCardStyle
      color={props.color}
      backgroundColor={props.backgroundColor}
      hoverBackgroundColor={props.hoverBackgroundColor}
      textWidth={props.textWidth}
    >
      <Link href={props.link}>
        <div className="icon-row">
          <img src={props.icon} alt={props.text} />
        </div>
        <div className="text-row">
          <Typography className="service-text">{props.text}</Typography>
          <Typography className="service-arrow">→</Typography>
        </div>
      </Link>
    </ServiceCardStyle>
  );
};

const Services = () => {
  const { t } = useTranslation();
  return (
    <Styles>
      <Container>
        <Typography className="header-title">{t("services.header")}</Typography>
        <div className="service-container">
          <ServiceCard
            color="#3f2a66"
            backgroundColor="#f1bfdf"
            hoverBackgroundColor="#fd82d1"
            text={t("services.p2p")}
            icon={p2pIcon}
            link="https://steiledema.boxnow.gr/home"
            textWidth="50%"
          />
          <ServiceCard
            color="#3f2a66"
            backgroundColor="white"
            hoverBackgroundColor="#ecece2"
            text={t("services.eshops")}
            icon={packageDeliveryIcon}
            link="https://boxnow.gr/collectfrome-shop"
          />
          <ServiceCard
            color="white"
            backgroundColor="#3f2a66"
            hoverBackgroundColor="#9c00c2"
            text={t("services.returns")}
            icon={returnsIcon}
            link="https://returns.boxnow.gr"
          />
          <ServiceCard
            color="#3f2a66"
            backgroundColor="#ffcc05"
            hoverBackgroundColor="#ffea00"
            text={t("services.payonthego")}
            icon={payOnTheGoIcon}
            link="https://boxnow.gr/payonthego"
          />
        </div>
      </Container>
    </Styles>
  );
};
export default Services;
