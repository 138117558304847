import { Grid, Paper, SxProps, Theme, Typography } from "@mui/material";
import useTranslation from "../translations/useTranslation";

import time from "../assets/times.png";
import fast from "../assets/fasts.png";
import money from "../assets/moneys.png";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { colors } from "../theme/theme";
import { Container } from "@mui/system";

const StepPaper = withTheme(styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  background-color: ${colors.onBackground};
  border: 1px solid rgba(38, 5, 99, 0.3);
  width: 32%;
  height: 268px;
  padding: 20px;

  margin-bottom: 0;

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
    width: calc(100vw - 450px);
    margin-bottom: 2em;
    padding: 40px;
    height: 468px;
  }

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("md")} {
    width: calc(100vw - 200px);
  }

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
    width: 300px;
    height: 268px;
  }

  .image-papers {
    width: 60px;
    height: 60px;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.between("sm", "lg")} {
      width: 80px;
      height: 80px;
    }
  }

  .step-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.4rem;
    color: ${colors.secondary};
    text-align: center;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.between("sm", "lg")} {
      font-size: 2.5rem;
    }
  }

  .step-secondary-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 0.875rem;
    color: #7d7986;
    text-align: center;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.between("sm", "lg")} {
      font-size: 1.5rem;
    }
  }
`);

const StepsGrid = withTheme(styled(Grid)`
  position: relative;

  .main-title-fonts {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 900;
    font-size: 2.5rem;
    text-align: center;
    color: ${colors.secondary};
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.primary};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      font-weight: 800;
      font-size: 2rem;
      line-height: 1.1;
    }
  }

  .div-triple-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;
    z-index: 100;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      flex-direction: column;
      align-items: center;
    }
  }

  .pink-div {
    position: absolute;
    background-color: #f1bfdf;
    width: 1130px;
    height: 268px;
    margin-left: 12em;
    margin-top: 15em;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      width: 160px;
      height: 700px;
      margin-left: 0;
    }
  }
`);

type StepCardProps = {
  title: string | undefined;
  text: string | undefined;
  imagePath: string;
  sx?: SxProps<Theme>;
};

const StepCard = (props: StepCardProps) => {
  return (
    <StepPaper sx={props.sx}>
      <img src={props.imagePath} className="image-papers" alt="" />
      <Typography className="step-main-title">{props.title}</Typography>
      <Typography className="step-secondary-title">{props.text}</Typography>
    </StepPaper>
  );
};

const Steps = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StepsGrid
        container
        xs={12}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 4, pb: 12 }}
      >
        <Typography className="main-title-fonts">
          {t("onboard.titleExperience")}
        </Typography>
        <div className="div-triple-images">
          <StepCard
            title={t("onboard.step1.title")}
            text={t("onboard.step1.para")}
            imagePath={time}
          />
          <StepCard
            title={t("onboard.step2.title")}
            text={t("onboard.step2.para")}
            imagePath={fast}
            sx={{ mx: 4 }}
          />
          <StepCard
            title={t("onboard.step3.title")}
            text={t("onboard.step3.para")}
            imagePath={money}
          />
        </div>
        <div className="pink-div" />
      </StepsGrid>
    </Container>
  );
};

export default Steps;
