import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import axios from 'axios';
import BoxNowContainer from 'components/BoxNowContainer';
import { formSubmit } from 'features/onBoardingForm/onBoardingFormSlice';
import moment from 'moment';
import randomstring from 'randomstring';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';
import { OBF } from '../api/types';
import { useAppDispatch, useAppSelector, useLanguageNavigate } from '../app/hooks';
import { p2pSelector } from '../features/p2pSlice';
import CheckRoundNegative from '../icons/CheckRoundNegative';
import { colors } from '../theme/theme';
import useTranslation from '../translations/useTranslation';
import doyCodes from '../assets/doy.json';

const Styles = withTheme(styled.div`
  .formContainer {
    background-color: white;
    border: 1px solid ${colors.secondary};
  }

  .form__label {
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
  }

  .form__button {
    width: calc(47% - 8px);
    margin-top: 38px;
    border-radius: 0;
    margin-bottom: 16px;
    background-color: #260563;
  }

  .form__button:hover {
    background-color: rgb(68, 214, 45);
  }

  .theForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px 32px 8px 32px;
    margin: 32px 0 0 0;

    @media (max-width: 930px) {
      flex-direction: column;
    }
  }

  .theForm > div {
    width: calc(47% - 8px);
    margin-bottom: 12px;

    @media (max-width: 930px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .personalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .personalForm > div {
    margin-top: 8px;
  }

  .personal-field {
    width: 45%;
    margin: 1em;
    @media (max-width: 930px) {
      width: 100%;
    }
  }

  .phone-fields {
    display: flex;
    justify-content: space-between;
    gap: 1em;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  }

  .next-typo {
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.25rem;
    color: white;
  }

  .main-title-typo {
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-size: 1.75rem;
    width: 100%;
    margin-top: 16px;
    color: rgb(38, 5, 99);
  }

  .time-secondary-title {
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
    margin: ${({ theme }: { theme: Theme }) => theme.spacing(2)};
    font-weight: 400;
    font-size: 1rem;
    color: #7d7986;
    text-align: center;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down('sm')} {
      font-size: 0.9rem;
    }
  }

  .personal-data {
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
    font-weight: bold;
  }

  .MuiFormControl-root {
    margin-top: 1em;
  }
`);

export default function Form() {
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [warning, setWarning] = useState('');
  const [apiError, setApiError] = useState(null);
  const [showForm, setShowform] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const warningRef = useRef<HTMLDivElement>(null);
  const [icon, setIcon] = useState(<KeyboardArrowDownIcon />);
  const [extraFieldsShow, setExtraFieldsShow] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const siteKey = '6LfXeDYfAAAAANnsglEVwYrU5wVL-7khzCuI5gRb';
  const { phoneCodes, locale } = useAppSelector(p2pSelector);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const navigate = useLanguageNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  /*
  Allow only Greek for the following fields if language is set to Greek
  - representative
  - DOY
  - Address
  - City
  */
  const isGreek = useMemo(() => locale === 'el', [locale]);
  const greekValidator = (value: string) =>
    isGreek ? /^[\u0370-\u03ff\u1f00-\u1fff\d\s]+$/.test(value) : true;

  const initialValues = {
    name: '',
    legalCompanyName: '',
    vat: null as any,
    date: '',
    representative: '',
    email: '',
    address: '',
    addressNumber: '',
    zipcode: null as any,
    doy: '',
    city: '',
    phoneCode: '+30',
    phoneNumber: '',
    emailTimologisis: '',
    websiteLink: '',
    personalName: '',
    personalEmail: '',
    personalNumber: '',
    notes: '',
  };

  const onBoardingSumbitSchema = z.object({
    name: z.string().min(3),
    legalCompanyName: z.string().min(3),
    vat: z.string().refine((value) => /^\d{9}$/.test(value), {
      message: 'onboard.vat.errors.digits',
    }),
    date: z.string(),
    representative: z.string().min(3).refine(greekValidator, {
      message: 'onboard.representative.errors.greek',
    }),
    email: z.string().email('onboard.email.errors.invalid'),
    zipcode: z.string().refine((value) => /^\d{5}$/.test(value), {
      message: 'onboard.zipcode.errors.digits',
    }),
    address: z.string().min(3).refine(greekValidator, {
      message: 'onboard.address.errors.greek',
    }),
    addressNumber: z.string().min(1),
    city: z.string().min(3).refine(greekValidator, {
      message: 'onboard.city.errors.greek',
    }),
    doy: z.string().min(1),
    phoneCode: z.string(),
    phoneNumber: z.string().refine(() => {
      let phoneCode = watch('phoneCode');
      let phoneNumberValue: string = watch('phoneNumber');

      if (phoneCode === '+30') {
        return /^(?:\+30)?69[0-9]{8}$/.test(phoneNumberValue);
      } else if (phoneCode === '+1') {
        return /^(?:\+1)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === '+31') {
        return /^(?:\+31)?6[0-9]{8}$/.test(phoneNumberValue);
      } else if (phoneCode === '+33') {
        return /^(?:\+33)?[0-9]{7}$/.test(phoneNumberValue);
      } else if (phoneCode === '+34') {
        return /^(?:\+34)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === '+41') {
        return /^(?:\+41)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === '+357') {
        return /^(?:\+357)?[0-9]{8}$/.test(phoneNumberValue);
      } else if (phoneCode === '+39') {
        return /^(?:\+39)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === '+44') {
        return /^(?:\+44)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === '+46') {
        return /^(?:\+46)?[0-9]{9,10}$/.test(phoneNumberValue);
      } else if (phoneCode === '+49') {
        return /^(?:\+49)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === '+61') {
        return /^(?:\+61)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === '+81') {
        return /^(?:\+81)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === '+86') {
        return /^(?:\+86)?1[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === '+91') {
        return /^(?:\+91)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === '+420') {
        return /^(?:\+420)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === '+971') {
        return /^(?:\+971)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === '+972') {
        return /^(?:\+972)?[0-9]{9}$/.test(phoneNumberValue);
      } else {
        // Default validation for 9-digit numbers
        return /^\d{9}$/.test(phoneNumberValue);
      }
    }),
    notes: z.string().max(100),
    // jobTitle: z.string().min(2),
    emailTimologisis: z.string().email('onboard.email.errors.invalid'),
    personalName: z.string(),
    personalEmail: z.string().max(0).or(z.string().email('onboard.email.errors.invalid')),
    personalNumber: z.string(),
    websiteLink: z.string().min(1),
  });

  const { control, handleSubmit, watch, setValue } = useForm<OBF>({
    defaultValues: initialValues,
    resolver: zodResolver(onBoardingSumbitSchema),
  });

  const handleExtraFieldsTrigger = () => {
    setExtraFieldsShow(!extraFieldsShow);
    setIcon(extraFieldsShow ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />);
  };

  useEffect(() => {
    if (warning && warningRef.current) {
      warningRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [warning]);

  // Watch the value of phoneCode

  //console.log(phoneNumberValue);

  // Use useEffect to trigger validation when phoneCode changes
  // useEffect(() => {
  //   trigger("phoneNumber"); // Trigger validation for phoneNumber when phoneCode changes
  // }, [phoneCode, trigger]);

  useEffect(() => {
    setValue('date', currentDate);
  }, [currentDate, setValue]);

  const sendForm: SubmitHandler<OBF> = async (data) => {
    let g_recaptcha_response = null;
    if (recaptchaRef.current) {
      g_recaptcha_response = await recaptchaRef.current.executeAsync();
    }

    //loader after taking the value from recaptcha because of a bug
    setIsLoading(true);

    let randomId = randomstring.generate(4);
    randomId = data.name.substring(0, 3) + randomId;

    console.log(randomId);
    let formData = {
      ...data,
      randomId,
      g_recaptcha_response,
    };

    recaptchaRef?.current?.reset();

    axios
      .post(
        'https://selfboarding.azurewebsites.net/api/selfonboarding?code=3CGu77p-L_pUPAws2OG1bCi0izfXq5IZ8o6Biwde3k5-AzFuUtgHOA==',
        {
          data: formData,
        }
      )
      .then(function (response) {
        console.log('response from azure-->', response);
        dispatch(formSubmit(response.data));
        if (response.data?.stage?.keys && response.data?.prod?.keys) {
          navigate('/form-completed');
        } else if (response.data?.warning) {
          setWarning(response.data.warning);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log('error is:', error);
        setApiError(error);
      });
  };

  return (
    <BoxNowContainer>
      <Styles>
        <Container>
          {isLoading ? (
            <div className='loader' style={{ flexDirection: 'column', gap: '4em' }}>
              <div className='main-title-typo'>
                Please don't close the window. Your API keys are being generated.
              </div>
              <CircularProgress />
            </div> // Render the Loading component when data is still loading
          ) : showForm ? (
            <div className='formContainer mt-3 mb-5'>
              <Typography className='main-title-typo'>{t('onboard.form.title')}</Typography>
              <Typography className='time-secondary-title'>{t('onboard.form.para')}</Typography>
              {warning && (
                <div
                  ref={warningRef}
                  style={{
                    color: 'red',
                    margin: '2rem',
                    fontWeight: 600,
                    fontSize: '1.2rem',
                  }}
                >
                  {warning}
                </div>
              )}
              <form onSubmit={handleSubmit(sendForm)} className='theForm'>
                <Controller
                  name='name'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('onboard.companyname.header')}
                      placeholder='Name'
                      className='form__label'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='legalCompanyName'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('onboard.legalCompanyName.header')}
                      placeholder='Legal name'
                      className='form__label'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='date'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('onboard.date.header')}
                      placeholder='Date'
                      fullWidth
                      className='form__label'
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        readOnly: true,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='representative'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('onboard.legalrepresentative.header')}
                      placeholder='Legal Representative'
                      className='form__label'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='vat'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('onboard.vat.header')}
                      placeholder='e.g. 123456789'
                      className='form__label'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='email'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('email.address.name')}
                      placeholder='Email'
                      className='form__label'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='doy'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        options={doyCodes}
                        getOptionLabel={(option) => option.description ?? ''}
                        onChange={(event, newValue) => {
                          setValue('doy', `${newValue?.code},${newValue?.description}`);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('doy.id.header')}
                            required
                            className='form__label'
                            error={!!fieldState.error}
                            helperText={t(fieldState.error?.message)}
                            InputProps={{
                              ...params.InputProps,
                              required: false,
                              endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                            }}
                            inputRef={ref}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    );
                  }}
                />
                <div className='phone-fields'>
                  <Controller
                    name='address'
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        label={t('address.header')}
                        placeholder='Street Address'
                        className='form__label'
                        fullWidth
                        required
                        error={!!fieldState.error}
                        helperText={t(fieldState.error?.message)}
                        InputProps={{
                          required: false,
                          endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                        }}
                        inputRef={ref}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name='addressNumber'
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        label={t('address.number')}
                        placeholder={t('address.number')}
                        className='form__label'
                        fullWidth
                        required
                        error={!!fieldState.error}
                        helperText={t(fieldState.error?.message)}
                        InputProps={{
                          required: false,
                          endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                        }}
                        inputRef={ref}
                        {...field}
                      />
                    )}
                  />
                </div>
                <Controller
                  name='zipcode'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('zip.header')}
                      placeholder='e.g. 16675'
                      className='form__label'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <div className='phone-fields'>
                  <Controller
                    name='phoneCode'
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        label={t('phone.code.header')}
                        select
                        fullWidth
                        className='phone-code-field'
                        required
                        error={!!fieldState.error}
                        helperText={t(fieldState.error?.message)}
                        InputProps={{
                          required: false,
                          endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                        }}
                        inputRef={ref}
                        {...field}
                      >
                        {phoneCodes.map((item) => (
                          <MenuItem key={item.code} value={item.code} className='someclassname'>
                            {item.code}
                            {` (${item.country})`}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name='phoneNumber'
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        label={t('phone.number.header')}
                        fullWidth
                        className='textfieldpnwidth'
                        required
                        placeholder='e.g. 6911223344'
                        error={!!fieldState.error}
                        helperText={t(fieldState.error?.message)}
                        InputProps={{
                          required: false,
                          endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                        }}
                        inputRef={ref}
                        {...field}
                      />
                    )}
                  />
                </div>
                <Controller
                  name='city'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('city.header')}
                      placeholder={t('city.header')}
                      className='form__label'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                {/* <Controller
                  name='jobTitle'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('subject.job')}
                      placeholder='your info'
                      className='form__label'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                /> */}
                <Controller
                  name='emailTimologisis'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('onboard.invoiceEmail')}
                      placeholder={t('onboard.invoiceEmail')}
                      fullWidth
                      required
                      className='textfieldpnwidth'
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='websiteLink'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label='Website URL'
                      fullWidth
                      required
                      placeholder='e.g. www.yoursite.com'
                      className='textfieldpnwidth'
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='notes'
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t('onboard.notes.header')}
                      fullWidth
                      multiline
                      minRows={4}
                      className='textfieldpnwidth'
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />

                <div
                  style={{
                    width: '100%',
                    marginTop: '2em',
                    color: colors.secondary,
                  }}
                >
                  <div>
                    <Typography className='personal-data'>{t('onboard.personalData')}</Typography>
                    <IconButton
                      onClick={handleExtraFieldsTrigger}
                      aria-label='add'
                      color='secondary'
                    >
                      {icon}
                    </IconButton>
                  </div>
                  {extraFieldsShow && (
                    <div className='personalForm'>
                      <Controller
                        name='personalName'
                        control={control}
                        render={({ field: { ref, ...field }, fieldState }) => (
                          <TextField
                            label={t('onboard.personalName')}
                            placeholder={t('onboard.personalName')}
                            className='personal-field'
                            fullWidth
                            required={false}
                            error={!!fieldState.error}
                            helperText={t(fieldState.error?.message)}
                            InputProps={{
                              required: false,
                              endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                            }}
                            inputRef={ref}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name='personalNumber'
                        control={control}
                        render={({ field: { ref, ...field }, fieldState }) => (
                          <TextField
                            label={t('onboard.personalPhone')}
                            placeholder={t('onboard.personalPhone')}
                            className='personal-field'
                            fullWidth
                            required={false}
                            error={!!fieldState.error}
                            helperText={t(fieldState.error?.message)}
                            InputProps={{
                              required: false,
                              endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                            }}
                            inputRef={ref}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name='personalEmail'
                        control={control}
                        render={({ field: { ref, ...field }, fieldState }) => (
                          <TextField
                            label={t('onboard.personalEmail')}
                            placeholder={t('onboard.personalEmail')}
                            className='personal-field'
                            fullWidth
                            required={false}
                            error={!!fieldState.error}
                            helperText={t(fieldState.error?.message)}
                            InputProps={{
                              required: false,
                              endAdornment: fieldState.error ? <CheckRoundNegative /> : null,
                            }}
                            inputRef={ref}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>

                <div style={{ width: '100%' }}>
                  <Button className='form__button' type='submit'>
                    <Typography className='next-typo'>{t('onboard.form.submit')}</Typography>
                  </Button>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  badge='bottomright'
                  sitekey={siteKey}
                  size='invisible'
                />
              </form>
            </div>
          ) : apiError ? (
            <div>
              {apiError === 404 ? (
                <div>404</div>
              ) : apiError === 500 ? (
                <div>500</div>
              ) : (
                <div>general error</div>
              )}
            </div>
          ) : (
            <></>
          )}
        </Container>
      </Styles>
    </BoxNowContainer>
  );
}
