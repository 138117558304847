import { combineReducers } from "@reduxjs/toolkit";
import p2pReducer from "../features/p2pSlice";
import onBoardingFormReducer from "../features/onBoardingForm/onBoardingFormSlice";

const rootReducer = combineReducers({
  p2p: p2pReducer,
  obf: onBoardingFormReducer,
});

export default rootReducer;
