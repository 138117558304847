import React from "react";
import { SvgIcon } from "@mui/material";

const DropdownIcon: React.FC<any> = (props) => (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 29 29" fill="none">
        <rect width="29" height="28" rx="14" fill="#E2E2E2" />
        <path
            d="M8 12L13.8217 17.3739C14.2048 17.7275 14.7952 17.7275 15.1783 17.3739L21 12"
            fill="transparent"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </SvgIcon>
);

export default DropdownIcon;
