import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "./store";
import { useNavigate } from "react-router-dom";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useLanguageNavigate = () => {
  const navigate = useNavigate();

  return (path: string) => {
    if (window.location.pathname.includes("/en")) {
      navigate(`/en${path}`);
    } else {
      navigate(path);
    }
  };
};
