import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import Opencart1 from '../../assets/opencart/opencart1.png';
import Opencart2 from '../../assets/opencart/OpenCart2.png';
import Opencart3 from '../../assets/opencart/OpenCart3.png';
import Opencart4 from '../../assets/opencart/OpenCart4.png';
import Opencart5 from '../../assets/opencart/OpenCart5.png';
import Opencart6 from '../../assets/opencart/OpenCart6.png';
import Opencart7 from '../../assets/opencart/OpenCart7.png';
import Opencart8 from '../../assets/opencart/OpenCart8.png';
import Opencart10 from '../../assets/opencart/OpenCart10.png';
import Opencart11 from '../../assets/opencart/OpenCart11.png';
import Opencart12 from '../../assets/opencart/opencart12.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';

export const OpenCartEn = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
    .box {
      display: none;
    }

    .download-link {
      display: block;
      margin-bottom: 8px;
      margin-top: 8px;
    }

    #trigger:checked + .box {
      display: block;
    }
    #trigger2:checked + .box {
      display: block;
    }
  `);
  return (
    <Styles>
      <div className='container d-flex flex-row'>
        <div className='w-50 position-relative hidden'>
          <aside className='sidebar '>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>Open Cart</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Installation and management plugin BoxNow Delivery.</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Filling out your details</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Using the plugin</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Support</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex  align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/eshops');
                    }}
                  >
                    Πίσω
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Open Cart</h2>
          <div className='mt-5'>
            <span>For downloading and plugin support, contact: </span>
            <a href='https://www.design-solutions.gr/' target='_blank'>
              design-solutions.gr
            </a>
          </div>
          <div className='mt-5'>
            <span>
              For further information please contact the email address:
              <a href='mailto: info@design-solutions.gr'>info@design-solutions.gr</a>
            </span>
          </div>

          <h3 className='mt-5'>Setup instructions</h3>
          <h5 className='mt-5' id='1'>
            Step 1: Installation and management plugin BoxNow Delivery.
          </h5>
          <p>
            To install the plugin in our administration we go to
            <span className='bolder'>Extensions {'->'} Install Extensions</span>.
          </p>

          <img className='mt-5 mb-5' src={Opencart1} />

          <p>Then we upload the zip file and the installation is comlpete.</p>
          <img className='mt-5 mb-5' src={Opencart2} />
          <p>
            We have the tab <span className='bolder'>BoxNow Delivery Services</span>.
          </p>
          <img className='mt-5 mb-5' src={Opencart3} />

          <h5 className='mt-5' id='2'>
            Step 2: Filling out your details
          </h5>
          <p>
            Then we go from our administration{' '}
            <span className='bolder'>
              Extensions → Extensions → Shipments → BOX NOW Delivery Services
            </span>{' '}
            and the following menu appears to insert Api keys, cost, free shipping etc.{' '}
          </p>

          <p>
            <span className='bolder'>API URL</span>: e.g. https://box-now-zvvzxxssazzq-ew.a.run.app
          </p>
          <p>
            <span className='bolder'>Client ID</span>: e.g. 9d8gg3de-8119-4tea-991e-37ff20eq5e50
          </p>
          <p>
            <span className='bolder'>Client Secret</span>: e.g.{' '}
            <span className='text-break'>
              c1144abc1db1f3186442a4ec3d3e953de0105c12759a2d82771a888256659e67f
            </span>
          </p>
          <p>
            <span className='bolder'>Warehouse Number</span>: e.g. 4098:Main Warehouse,
            4099:Secondary Warehouse etc.
          </p>
          <p>
            <span className='bolder'>Partner ID</span>: e.g. 202.
          </p>

          <img className='mt-5 mb-5' src={Opencart4} />
          <img className='mt-5 mb-5' src={Opencart5} />

          <p>
            In the field «Warehouse Number» we can insert more than one pickup points so tha we can
            choose it. Each pickup point will be below the previous one in separate line and will be
            like following example:
          </p>

          <img className='mt-5 mb-5' src={Opencart6} />

          <h5 className='mt-5' id='3'>
            Step 3: Using the plugin
          </h5>
          <p>
            We select the <span className='bolder'>BoxNow Delivery Services</span> tab and the
            following options appear.
          </p>

          <img className='mt-5 mb-5' src={Opencart7} />

          <p>
            With the <span className='bolder'>Show </span> button we can see the order we choose.
          </p>
          <img className='mt-5 mb-5' src={Opencart8} />
          <p>
            In the <span className='bolder'>Number of Packages</span> by clicking on the number,
            opens the package’s tracking page directly.
          </p>

          <img className='mt-5 mb-5' src={Opencart10} />
          <p>
            In <span className='bolder'>BOX NOW Status</span> we see the status of each voucher
            number, if it has been created,
          </p>
          <img className='mt-5 mb-5' src={Opencart11} />

          <p>
            In case it needs to be created, we create it by selecting Create Voucher button. We can
            also select Warehouse number from the list that appears. In the "Locker ID" collumn we
            see the number of the pickup point that the customer has selected in the order. If we
            wish to change the point, we can do so by clicking on the number.
          </p>
          <p>
            In case was not made by pressing the button, we see the Error Code in the information.{' '}
          </p>

          <img className='mt-5 mb-5' src={Opencart12} />

          <h5 id='4'>Notes:</h5>
          <ol>
            <li>Testing plugin with stage Api keys.</li>
            <li>
              Select stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
              10447
            </li>
            <li>
              When a new order is completed we will automatically send you a PDF shipping label.
            </li>
          </ol>

          <h5>Get Support</h5>
          <p>
            If you have any questions about our solutions, or questions about how to integrate with
            our solutions, please refer to our email
            <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
