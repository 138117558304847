import { createTheme, css } from "@mui/material";
import DropdownIcon from "../icons/DropdownIcon";
import React from "react"; // Import React

export const colors = {
  primary: "#6cd04e",
  primaryDark: "#8bb04a",

  secondary: "#260563",
  secondaryDark: "#9C9C9C",

  textPrimary: "#2F2F2F",
  textPrimaryLight: "#989898",
  textPrimaryDisabled: "#cecece",
  textSecondary: "#595959",
  textContrast: "#FFF",

  success: "#92C054",
  error: "#D95555",
  asterisk: "#D95555",

  onBackground: "#FFFFFF",
  background: "#F6F6F6",
};

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.primary,
      contrastText: colors.textContrast,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.textContrast,
    },
    common: {
      black: colors.textPrimary,
      white: colors.background,
    },
    background: {
      paper: colors.onBackground,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      disabled: colors.textPrimaryDisabled,
    },
  },
  typography: {
    fontFamily: "'Rubik', sans-serif",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: "Rubik",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
      styleOverrides: {
        root: css`
          text-transform: none;

          &.MuiLoadingButton-root {
            background-color: ${colors.primary};

            .MuiLoadingButton-loadingIndicator {
              color: ${colors.onBackground};
            }
          }
        `,

        containedPrimary: css`
          &:hover {
            background-color: ${colors.secondary};
            //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
            //    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
            //    0 16px 16px rgba(0, 0, 0, 0.12);
          }
        `,

        sizeSmall: css`
          min-height: 32px;
          border-radius: 12px;
          padding: 6px 16px;
        `,

        sizeMedium: css`
          min-height: 48px;
          border-radius: 22px;
          padding: 8px 24px;
        `,

        sizeLarge: css`
          min-height: 68px;
          border-radius: 36px;
          padding: 8px 56px;

          .MuiButton-endIcon {
            position: absolute;
            right: 24px;
          }

          .MuiButton-startIcon {
            position: absolute;
            left: 24px;
          }
        `,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: css`
          border-radius: 30px;
        `,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        InputLabelProps: {
          shrink: true,
          disableAnimation: true,
        },
      },
      styleOverrides: {
        root: css`
          .MuiInputLabel-root {
            color: ${colors.textPrimary};
            font-size: 1.45rem;

            &.Mui-disabled {
              color: ${colors.textPrimaryDisabled};
            }
          }

          .MuiOutlinedInput-root {
            border-radius: 0;
            border: 1px solid #260563;
          }
        `,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: css`
          background-color: ${colors.onBackground};
          border-radius: 16px;

          .MuiOutlinedInput-input {
            padding: 16px 26px;
          }

          textarea {
            padding: 0 13px !important;
          }

          label + & {
            margin-top: 22px;
          }

          [aria-expanded="true"] {
            border-radius: 16px 16px 0 0;
            background-color: ${colors.onBackground};
          }

          [aria-expanded="true"] ~ .MuiOutlinedInput-notchedOutline {
            //box-shadow: 1px -9px 20px 0px rgba(0, 0, 0, 0.08);
            border-radius: 16px 16px 0 0;
            border-bottom: 1px solid red;
          }

          .MuiOutlinedInput-notchedOutline {
            border: none;
            transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          }

          &:hover {
            .MuiOutlinedInput-notchedOutline {
              box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.08);
            }
          }

          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border: none;
              box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.08);
            }
          }

          &.Mui-disabled {
            .MuiOutlinedInput-notchedOutline {
              box-shadow: none;
            }
          }
        `,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: css`
          font-weight: 600;
          color: #260563 !important;
        `,
        asterisk: css`
          color: ${colors.asterisk};
        `,
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: css`
          padding-right: 26px !important;
          padding-left: 48px;
          display: flex;
          flex-direction: row;

          //& ~ .dropdown-icon path {
          //  transition-duration: .3s;
          //}

          &[aria-expanded="true"] ~ .dropdown-icon path {
            transform: scale(-1, -1);
            transform-origin: 50% 50%;
          }

          & .MuiListItemIcon-root {
            min-width: 36px;
          }
        `,
      },
      defaultProps: {
        IconComponent: () => (
          <DropdownIcon
            className="dropdown-icon"
            style={{
              position: "absolute",
              left: 7,
              pointerEvents: "auto",
            }}
          />
        ),
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            elevation: 0,
          },
          MenuListProps: {
            className: "select-dropdown",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: css``,
        paper: css`
          &.MuiPopover-paper {
            border-radius: 0 0 16px 16px;
            box-shadow: 0 9px 9px 2px rgba(0, 0, 0, 0.08);
          }
        `,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: css`
          background-color: ${colors.secondaryDark};
          padding: 20px 8px;
        `,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: css`
          .MuiTableHead-root {
            th {
              border-bottom: 0;
              background-color: #f1bfdf;
            }
          }

          .MuiTableBody-root > tr:last-child > td {
            border-bottom: 0;
          }
        `,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: css`
          background-color: #efefef;
          border-radius: 10px;

          .MuiAccordionSummary-expandIconWrapper {
            .MuiSvgIcon-root {
              font-size: 38px;
            }
          }
        `,
      },
    },
  },
});
