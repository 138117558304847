import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import wooc1 from '../../assets/woocommerce/wooc1.png';
import wooc2 from '../../assets/woocommerce/wooc2.jpg';
import wooc3 from '../../assets/woocommerce/wooc3.jpg';
import wooc4 from '../../assets/woocommerce/wooc4.jpg';
import wooc5 from '../../assets/woocommerce/wooc5.jpg';
import wooc6 from '../../assets/woocommerce/wooc6.jpg';
import wooc7 from '../../assets/woocommerce/wooc7.jpg';
import wooc8 from '../../assets/woocommerce/wooc8.jpg';
import wooc9 from '../../assets/woocommerce/wooc9.jpg';
import wooc10 from '../../assets/woocommerce/wooc10.jpg';
import wooc11 from '../../assets/woocommerce/wooc11.jpg';
import wooc12 from '../../assets/woocommerce/wooc12.jpg';
import wooc13 from '../../assets/woocommerce/wooc13.jpg';
import wooc14 from '../../assets/woocommerce/wooc14.jpg';
import wooc15 from '../../assets/woocommerce/wooc15.jpg';
import wooc16 from '../../assets/woocommerce/wooc16.jpg';
import wooc17 from '../../assets/woocommerce/wooc17.jpg';
import wooc18 from '../../assets/woocommerce/wooc18.jpg';
import wooc19 from '../../assets/woocommerce/wooc19.jpg';
import wooc20 from '../../assets/woocommerce/wooc20.jpg';
import wooc21 from '../../assets/woocommerce/wooc21.jpg';
import wooc22 from '../../assets/woocommerce/wooc22.jpg';
import wooc23 from '../../assets/woocommerce/wooc23.jpg';
import wooc24 from '../../assets/woocommerce/wooc24.jpg';
import wooc25 from '../../assets/woocommerce/wooc25.jpg';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';

export const WooCommerce = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='d-flex flex-row'>
        <div className='sidebar hidden'>
          <aside>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>WooCommerce</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Βήμα 1: Απαιτήσεις Εγκατάστασης προσθέτου</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Βήμα 2: Ρυθμίσεις για το WooCommerce</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Βήμα 3: Επισκόπηση σελίδας ρυθμίσεων</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Βήμα 4: Χρήσιμοι σύνδεσμοι</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/eshops');
                    }}
                  >
                    Πίσω
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='width--high container'>
          <h2>WooCommerce</h2>
          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a href='https://wordpress.org/plugins/box-now-delivery/' target='_blank'>
              WordPress Plugin
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a
              href='https://boxnow.gr/media/pdf/BOX NOW Delivery WordPress Plugin Documentation GR (2).pdf'
              download
              target='_blank'
            >
              WordPress Plugin Documentation
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a
              href='https://boxnow.gr/media/mp4/TestOrder+CreateVoucher.mp4'
              download
              target='_blank'
            >
              Test Order and Create Voucher video
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a href='https://boxnow.gr/media/mp4/PluginInstallationWP.mp4' download target='_blank'>
              Plugin Installation WP video
            </a>
          </div>

          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a
              href='https://boxnow.gr/media/pdf/wp-voucher-instructions.pdf'
              download
              target='_blank'
            >
              Voucher Instructions
            </a>
          </div>
          <h3 className='mt-5'>WordPress Plugin Οδηγίες χρήσης</h3>
          <h5 id='1' className='mt-5'>
            Απαιτήσεις για σωστή εγκατάσταση του πρόσθετου
          </h5>
          <ul className='list-inside' id='1'>
            <li>
              To WooCommerce πρέπει να είναι εγκατεστημένο και ενεργό πριν εγκαταστήσετε το πρόσθετο
              BOX NOW Delivery.
            </li>
            <li>Απαιτείται PHP έκδοση 8 ή νεότερη.</li>
            <li>Απαιτείται η έκδοση WordPress 6.2 ή νεότερη.</li>
          </ul>
          <h5 className='mt-5'>BOX NOW Delivery Ρυθμίσεις μενού διαχειριστή</h5>

          <img src={wooc1} />

          <h5 className='mt-5'>Τμήμα your API details</h5>
          <ul className='list-inside'>
            <li>
              Στο input με όνομα ‘Your API URL‘, εισάγετε τη διεύθυνση URL που σας έχει δώσει η BOX
              NOW. Αφαιρεί αυτόματα το πρόθεμα ‘https://’ και ‘http://’ γιατί δεν χρειάζεται.
            </li>
            <li>
              Στο input με όνομα ‘Your Client ID', εισάγετε το Client ID που σας έχει δώσει η BOX
              NOW.
            </li>
            <li>
              Στο input με όνομα ‘Your Client Secret*, εισάγετε το Client Secret που η BOX NOW σας
              έχει δώσει
            </li>
            <li>
              Στο input με όνομα ‘Warehouse IDs*, εισάγετε το Warehouse ID που η BOX NOW σας έχει
              δώσει. Εάν θέλετε να προσθέσετε πολλές αποθήκες, διαιρέστε τα αναγνωριστικά με κόμμα
              για παράδειγμα: 8,2.
            </li>
            <li>
              Στο input με όνομα ‘Your Partner ID", μέσα εκεί εισάγετε το ID συνεργάτη που σας έχει
              δώσει η BOX NOW.
            </li>
          </ul>

          <h5 className='mt-5'>Τμήμα Button Customization</h5>
          <ul className='list-inside'>
            <li>
              Στο input με όνομα ‘Change button color", εισάγετε τον έγχρωμο κωδικό HEX που θέλετε
              για το Κουμπί στη σελίδα ολοκλήρωσης αγοράς που ανοίγει τον αναδυόμενο χάρτη. Εάν δεν
              το αλλάξετε, παίρνει από προεπιλογή το χρώμα «84C33F.
            </li>
            <li>
              Στο input με όνομα ‘Change button text', εκεί εισάγετε το κείμενο που θέλετε να
              εμφανιστεί για το κουμπί στη σελίδα ολοκλήρωσης αγοράς για τον αναδυόμενο χάρτη. Εάν
              δεν το αλλάξετε, το προεπιλεγμένο κείμενο είναι: Pick a locker.
            </li>
          </ul>

          <img src={wooc2} />

          <h5 className='mt-5'>Τμήμα Map customization</h5>
          <p>
            Εάν επιλέξετε το πρώτο κουμπί επιλογής με το όνομα ‘Popup modal window", θα εμφανιστεί
            ένα κουμπί στη σελίδα ολοκλήρωσης αγοράς και όταν κάνετε κλικ, θα ανοίξει ένα παράθυρο
            με τον χάρτη και τα ντουλάπια.
          </p>

          <img src={wooc3} />

          <p>
            Εάν επιλέξετε το δεύτερο κουμπί επιλογής με το όνομα "Embedded iFrame”, θα ενσωματώσει
            τον χάρτη αυτόματα στη σελίδα ολοκλήρωσης αγοράς.
          </p>

          <img src={wooc4} />

          <h5 className='mt-5'>Τμήμα GPS tracking location toggle</h5>
          <p>
            Εάν επιλέξετε το πρώτο κουμπί επιλογής με το όνομα ‘GPS ΟΝ*, ο χάρτης θα εμφανίσει
            αυτόματα τα πλησιέστερα ντουλάπια στον χάρτη που είναι διαθέσιμα με βάση την τοποθεσία
            σας την τρέχουσα στιγμή.
          </p>

          <img className='mb-3' src={wooc3} />

          <p>
            Εάν επιλέξετε το δεύτερο κουμπί επιλογής με το όνομα "GPS OFF', ο χάρτης θα εμφανίσει τα
            ντουλάπια κοντά σας σύμφωνα με τον Ταχυδρομικό Κώδικα/ΤΚ που έχετε δηλώσει στη διεύθυνση
            της παραγγελίας.
          </p>

          <img src={wooc5} />

          <h5 className='mt-5'>Τμήμα Choose voucher option</h5>
          <p>
            Όταν επιλέξετε το κουμπί επιλογής με το όνομα ‘Send voucher via email" θα εμφανιστεί ένα
            νέο input από κάτω με το όνομα ‘Please insert your email here:". Μέσα σε αυτό το input,
            μπορείτε να προσθέσετε το email που θέλετε για να σταλεί το voucher pdf.
          </p>

          <img className='mb-3' src={wooc6} />
          <img className='mb-3' src={wooc7} />

          <p>
            Πρέπει να αλλάξετε την κατάσταση της παραγγελίας σε ολοκληρωμένη και να ενημερώσετε την
            παραγγελία από την ενότητα Ενέργειες παραγγελίας προκειμένου το κουπόνι να σταλεί στο
            email που έχετε δώσει. Δείτε τις παρακάτω εικόνες παραδειγμάτων.
          </p>

          <img className='mb-3' src={wooc8} />
          <img className='mb-3' src={wooc9} />

          <p>
            Εάν κάνετε αυτές τις ενέργειες, θα λάβετε ένα email στο email που έχετε παράσχει με το
            voucher pdf. Παράδειγμα του email που θα λάβετε.
          </p>

          <img className='mb-3' src={wooc10} />

          <p>
            Εάν θέλετε να ακυρώσετε την παραγγελία, μπορείτε να επιλέξετε από το μενού Κατάσταση
            μέσα στην παραγγελία το "BOX NOW Cancel Order" και στη συνέχεια να ενημερώσετε την
            παραγγελία. Αυτή η ενέργεια στέλνει ένα αίτημα για ακύρωση της παραγγελίας στο CMS μας.
            Δείτε τις παρακάτω εικόνες παραδειγμάτων.
          </p>

          <img className='mb-3' src={wooc11} />
          <img className='mb-3' src={wooc9} />

          <p>
            To ‘BOX NOW Cancel Order" λειτουργεί για την ακύρωση της παραγγελίας μόνο όταν έχετε
            επιλέξει το κουμπί επιλογής ‘Send voucher via email*.
          </p>

          <p>
            Όταν επιλέγετε το κουμπί επιλογής με το όνομα ‘Display a button in WooCommerce admin
            order page for printing the vouchers',αυτή η ενέργεια θα εμφανίσει τρία κουμπιά με τα
            ονόματα ‘Create Vouchers (Small)·, ‘Create Vouchers (Medium)', ‘Create Vouchers (Large)'
            και ένα πεδίο εισαγωγής στη σελίδα της παραγγελίας για την εκτύπωση των κουπονιών στο
            πρόγραμμα περιήγησής σας αυτή η ενέργεια θα παρακάμψει τη λειτουργία αποστολής email.
          </p>

          <img className='mb-3' src={wooc12} />
          <img className='mb-3' src={wooc13} />

          <p>
            Στην παρακάτω εικόνα παραδείγματος έχουμε δημιουργήσει 2 vouchers κάνοντας κλικ στο
            κουμπί με όνομα ‘Create Vouchers (Small)'. Βάλαμε τον αριθμό 2 στο πεδίο εισαγωγής και
            κάναμε κλικ στο κουμπί ‘Create Vouchers (Small)'.
          </p>
          <p>
            Δημιούργησε δύο πράσινα κουμπιά με τον αριθμό του voucher, οι οποίοι είναι σύνδεσμοι που
            σας μεταφέρουν στη σελίδα εκτύπωσης του κουπονιού pdf και δύο κόκκινα κουμπιά με το
            όνομα ‘Cancel Voucher" που ακυρώνουν το σχετικό voucher δίπλα τους και στέλνουν επίσης
            αίτημα για ακύρωση αυτού του voucher στο CMS μας.
          </p>
          <p>
            Συμβουλή: Αυτή η ενέργεια θα στείλει 2 αιτήματα στο CMS μας με 2 παραγγελίες που έχουν
            μεγέθη διαμερισμάτων ‘Small· επειδή επιλέγουμε να δημιουργήσουμε τα vouchers μας
            κάνοντας κλικ στο κουμπί ‘Create Vouchers (Small)'. Αν θέλετε να δημιουργήσετε μια
            παραγγελία μεσαίου μεγέθους διαμερίσματος (medium), πρέπει να κάνετε κλικ στο κουμπί
            ‘Create Vouchers (Medium)" και για Μεγάλο (Large) στο κουμπί ‘Create Vouchers (Large)'
            αντίστοιχα.
          </p>

          <p>
            Αφού δημιουργήσετε τα vouchers που θέλετε, τα τρία κουμπιά "Create Vouchers' δεν θα
            είναι πλέον διαθέσιμα, θα πρέπει να ακυρώσετε όλα τα vouchers για να γίνουν ξανά
            διαθέσιμα.
          </p>
          <p>
            Συμβουλή: Δεν μπορείτε να δημιουργήσετε περισσότερα vouchers από τον αριθμό Max Vouchers
            που υποδεικνύεται με κόκκινο χρώμα, θα εμφανιστεί μήνυμα σφάλματος.
          </p>

          <img className='mb-3' src={wooc14} />

          <p>
            Εάν κάνετε κλικ στα πράσινα κουμπιά με τον αριθμό του κουπονιού, θα ανοίξει μια νέα
            καρτέλα με το κουπόνι pdf που μπορείτε να εκτυπώσετε. Δείτε το παράδειγμα της εικόνας
            παρακάτω.
          </p>

          <img className='mb-3' src={wooc15} />

          <h5 className='mt-5'>Τμήμα Message shown when locker is not selected</h5>

          <p>
            Σε αυτήν την ενότητα, μπορείτε να προσθέσετε το προσαρμοσμένο μήνυμα που θέλετε να
            εμφανίζεται στη σελίδα ολοκλήρωσης αγοράς όταν δεν έχει επιλεγεί ένα ντουλάπι και
            κάποιος προσπαθεί να ολοκληρώσει την παραγγελία Εάν δεν προσθέσετε ένα προσαρμοσμένο
            μήνυμα θα έχει το προεπιλεγμένο μήνυμα ‘Please select a locker first!'.
          </p>

          <img className='mb-3' src={wooc16} />
          <img className='mb-3' src={wooc17} />

          <p>
            Αφού κάνετε μια αλλαγή ή κάνετε μια διαφορετική επιλογή στο μενού ρυθμίσεων BOX NOW,
            αποθηκεύατε πάντα τις αλλαγές σας! Απλώς κάντε κλικ σε αυτό το κουμπί παρακάτω και είστε
            έτοιμοι να ξεκινήσετε!
          </p>

          <img className='mb-3' src={wooc18} />

          <h4 className='mt-5' id='2'>
            BOX NOW Delivery ρυθμίσεις για το WooCommerce
          </h4>

          <p>
            Στη σελίδα ‘Settings' του WooCommerce επιλέξτε την καρτέλα ‘Shipping’ και από εκεί
            προσθέστε μια "Shipping zone’ όπως φαίνεται στα βήματα των παρακάτω εικόνων.
          </p>

          <img className='mb-3' src={wooc19} />

          <p>
            Από εκεί μπορείτε να προσθέσετε τη ζώνη που θέλετε, έχουμε προσθέσει τη ζώνη ‘Ελλάδα’ σε
            αυτό το παράδειγμα και από το κουμπί "Add shipping method’ προσθέσαμε τον τρόπο
            αποστολής BOX NOW και Free shipping. Δείτε το παράδειγμα της εικόνας παρακάτω.
          </p>

          <img className='mb-3' src={wooc20} />

          <p>
            Από εκεί επίσης μπορούμε να κάνουμε επεξεργασία στις ρυθμίσεις από τη μέθοδο αποστολής
            BOX NOW, απλώς κάντε κλικ στο κουμπί "Edit" κάτω από τη μέθοδο αποστολής. Δείτε το
            παράδειγμα της εικόνας παρακάτω.
          </p>

          <img className='mb-3' src={wooc21} />

          <p>
            Αυτή είναι η σελίδα ρυθμίσεων για τη μέθοδο αποστολής BOX NOW όπως βλέπουμε στην εικόνα
            παρακάτω. Μην ξεχνάτε να αποθηκεύετε πάντα τις αλλαγές σας μετά από μια αλλαγή.
          </p>

          <img className='mb-3' src={wooc22} />

          <h4 className='mt-5' id='3'>
            Επισκόπηση σελίδας ρυθμίσεων BOX NOW Delivery
          </h4>

          <img className='mb-3' src={wooc23} />

          <ul className='list-inside'>
            <li>
              Στην ενότητα ‘Enable/Disable" όταν επιλεχθεί, η μέθοδος αποστολής BOX NOW γίνεται
              διαθέσιμη στο ταμείο, εάν δεν είναι επιλεγμένη, δεν είναι διαθέσιμη.
            </li>
            <li>
              Η εισαγωγή ‘Title Method" αλλάζει τον τίτλο για τη μέθοδο αποστολής, το προεπιλεγμένο
              όνομα είναι "Box Now*.
            </li>
            <li>
              Η εισαγωγή ‘Cost’ προσθέτει το επιθυμητό κόστος για τη συγκεκριμένη μέθοδο αποστολής.
            </li>
            <li>
              Στο input με όνομα ‘Free Delivery Threshold" μπορείτε να προσθέσετε τη συνολική αξία
              της παραγγελίας που θέλετε όταν αυτή υπερβαίνει να είναι δωρεάν χωρίς κόστος. Εάν
              μείνει κενό, δεν θα έχει κανένα όριο δωρεάν παράδοσης. Για παράδειγμα, εάν έχετε
              ‘Cost" 2€ και το ‘Free Delivery Threshold" είναι 60€, εάν το σύνολο της παραγγελίας
              οας είναι πάνω από 60€ τότε το ‘Cost' θα είναι 0€.
            </li>
            <li>
              Το αναπτυσσόμενο μενού ‘Taxable’ έχει δύο επιλογές για να επιλέξετε ‘Yes' και ‘No*,
              εάν επιλέξετε ‘Yes', τότε θα εφαρμόσει φόρους στην παραγγελία σας ανάλογα με τη χώρα
              στην οποία βρίσκεστε και τους φορολογικούς συντελεστές που έχετε. Εάν επιλέξετε ‘No‘,
              δεν θα επιβληθούν φόροι στην παραγγελία
            </li>
            <li>
              To input με όνομα 'Max Weight" πρέπει πάντα να έχει την τιμή 20, επειδή τα μέγιστα
              κιλά του ντουλαπιού μας είναι 20 κιλά. Εάν βάλετε έναν αριθμό μεγαλύτερο από 20, τότε
              η μέθοδος παράδοσης BOX NOW δεν θα λειτουργήσει σύμφωνα με τα πρότυπα.
            </li>
            <li>
              Όταν κάνετε εγκατάσταση το plugin θα πρέπει να μεταβείτε σε αυτές τις ρυθμίσεις και να
              βάλετε τον αριθμό 20 σε αυτό το input και να σώσετε τις αλλαγές. Είναι απαραίτητο για
              τη σωστή χρήση του πρόσθετου.
            </li>
            <li>
              Στην ενότητα "Max Package Dimensions" η είσοδος με το όνομα ‘Max Length (cm)‘ πρέπει
              πάντα να είναι 60, το ‘Max Width (cm)‘ πρέπει πάντα να είναι 45 και το ‘Max Height
              (cm)" να είναι πάντα 36, γιατί αυτές είναι οι μέγιστες διαστάσεις των ντουλαπιών μας.
              Εάν προσθέσετε έναν αριθμό μεγαλύτερο ή μικρότερο από αυτούς που παρουσιάζονται εδώ.
              το BOX NOW Delivery δεν θα λειτουργήσει όπως αναμένεται.
            </li>
            <li>
              Όταν κάνετε εγκατάσταση το plugin θα πρέπει να μεταβείτε σε αυτές τις ρυθμίσεις και να
              βάλετε στα inputs τους αριθμούς 60. 45 και 36 όπως φαίνονται ακριβώς στην εικόνα της
              απισκόπησης των ρυθμίσεων και να σώσετε τις αλλαγές. Είναι απαραίτητο για τη σωστή
              χρήση του πρόσθετου.
            </li>
            <li>
              Στην ενότητα με όνομα ‘Cash on delivery custom description settings’, μπορείτε να
              κάνετε κλικ στην επιλογή με το όνομα ‘Enable Custom Description for COD", εάν την
              ενεργοποιήσετε θα πρέπει επίσης να γράψετε την προσαρμοσμένη περιγραφή που θέλετε και
              να αποθηκεύσετε τις αλλαγές. Αυτή η ενέργεια θα αποθηκεύσει μια προσαρμοσμένη
              περιγραφή για τον ‘BOX NOW PAY ON THE GO!‘ τρόπο πληρωμής που μπορείτε να δείτε στη
              σελίδα ολοκλήρωσης αγοράς. Αντικαθιστά τον προεπιλεγμένο τρόπο πληρωμής με
              αντικαταβολή "COD" από το WooCommerce. Δείτε τις παρακάτω εικόνες παραδειγμάτων για
              καλύτερη κατανόηση.
            </li>
          </ul>

          <img className='mb-3 mt-3' src={wooc24} />
          <img className='mb-3' src={wooc25} />

          <p id='4'>
            Μην ξεχνάτε να αποθηκεύετε πάντα τις αλλαγές σας για να εμφανίζονται στη σελίδα
            ολοκλήρωσης αγοράς!
          </p>

          <h4 className='mt-5'>Χρήσιμοι σύνδεσμοι</h4>

          <ul className='list-inside'>
            <li>
              Αν θέλετε περισσότερες πληροφορίες για το widget μας, μπορείτε να το βρείτε εδώ:{' '}
              <a href='https://widget-v5.boxnow.gr/devs'>https://widget-v5.boxnow.gr/devs</a>
            </li>
            <li>
              Μπορείτε να ελέγξετε την ιστοσελίδα μας εδώ για περισσότερες χρήσιμες πληροφορίες:{' '}
              <a href='https://boxnow.gr/'>https://boxnow.gr/</a>
            </li>
            <li>
              Αν θέλετε να επικοινωνήσετε μαζί μας μπορείτε να στείλετε το email σας εδώ:{' '}
              <a href='mailto: info@boxnow.gr'>info@boxnow.gr</a>
            </li>
          </ul>
        </div>
      </div>
    </Styles>
  );
};
