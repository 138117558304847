import React from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";

const CheckRoundNegative: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} width="34" height="34" viewBox="0 0 34 34" fill="none">
        <rect width="34" height="34" rx="17" fill="#D95555" />
        <path d="M13 13L17.5 17.5L22 22" stroke="white" strokeWidth="3" strokeLinecap="round" />
        <path d="M22 13L17.5 17.5L13 22" stroke="white" strokeWidth="3" strokeLinecap="round" />
    </SvgIcon>
);

export default CheckRoundNegative;
