import {
  FooterIconOption,
  FooterOption,
  HeaderOption,
  Locale,
} from "../api/types";
import axios from "axios";

const apiKey =
  "775e0beeb864beb49f103c9ae1a7bdea8581bc2c79b775c3aacced1a25d7f645b33ea7ec157a2f40e1a53139861b03f9a78b96359bd686cabce62624741e83a8e86dde4efa25b616d297e1f18479ba4584cd54e58fd24664e905e05cbef6b72ad326bb0b58e4195575755a67f359e180cdebf07dc901cf6def2346e96a80d31a";

const strapiAPI = axios.create({
  baseURL: "/api/strapi/",
  timeout: 20000,
  headers: {
    Authorization: `Bearer ${apiKey}`,
  },
});

export const fetchHeaderOptions = (
  locale: Locale = "en"
): Promise<{ data: HeaderOption[] }> => {
  return strapiAPI.get("/headers", {
    params: {
      "pagination[start]": 0,
      "pagination[limit]": 1000,
      locale: locale,
    },
  });
};

export const fetchFooterOptions = (
  locale: Locale = "en"
): Promise<{ data: FooterOption[] }> => {
  return strapiAPI.get("/footers", {
    params: {
      "pagination[start]": 0,
      "pagination[limit]": 1000,
      locale: locale,
    },
  });
};

export const fetchFooterIconOptions = (): Promise<{
  data: FooterIconOption[];
}> => {
  return strapiAPI.get("/footer-icons", {
    params: {
      "pagination[start]": 0,
      "pagination[limit]": 1000,
      populate: "*",
    },
  });
};

export const fetchPhoneCodes = (): Promise<{ data: any[] }> => {
  return strapiAPI.get("/phone-codes", {
    params: { "pagination[start]": 0, "pagination[limit]": 1000 },
  });
};

export const fetchPromoCodes = (): Promise<{ data: string[] }> => {
  return strapiAPI.get("/promo-codes", {
    params: { "pagination[start]": 0, "pagination[limit]": 1000 },
  });
};

export const fetchTranslations = (
  locale: Locale = "en"
): Promise<{ data: { code: string }[] }> => {
  return strapiAPI.get("/translations", {
    params: {
      "pagination[start]": 0,
      "pagination[limit]": 1000,
      locale: locale,
    },
  });
};
