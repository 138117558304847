import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import magento21 from '../../assets/magento2/magento21.png';
import magento22 from '../../assets/magento2/magento22.png';
import magento23 from '../../assets/magento2/magento23.png';
import magento24 from '../../assets/magento2/magento24.png';
import magento25 from '../../assets/magento2/magento25.png';
import magento26 from '../../assets/magento2/magento26.png';
import magento27 from '../../assets/magento2/magento27.png';
import magento28 from '../../assets/magento2/magento28.png';
import magento29 from '../../assets/magento2/magento29.png';
import magento29en from '../../assets/magento2/magento29en.png';
import magento210 from '../../assets/magento2/magento210.png';
import magento211 from '../../assets/magento2/magento211.png';
import magento212 from '../../assets/magento2/magento212.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';

export const Magento2 = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='d-flex flex-row'>
        <div className='sidebar hidden'>
          <aside>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>Magento</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Εγκατάσταση</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Ρυθμίσεις Συστήματος </a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Δοκιμή Plugin </a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Σύστημα διαχείρισης αποστολών</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Βοήθεια</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <span className='h5 d-flex  align-items-center'>
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/eshops');
                        }}
                      >
                        Πίσω
                      </a>
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Magento</h2>

          <div className='mt-5'>
            <span>Για τη λήψη και την υποστήριξη του plugin επικοινωνήστε με την :</span>
            <a href='https://www.design-solutions.gr/' target='_blank'>
              design-solutions.gr
            </a>
          </div>
          <div className='mt-5'>
            <span>
              Για περαιτέρω πληροφορίες παρακαλώ απευθυνθείτε στην διεύθυνση ηλεκτρονικού
              ταχυδρομείου:
              <a href='mailto: info@design-solutions.gr'>info@design-solutions.gr</a>
            </span>
          </div>

          <h3 className='mt-5' id='1'>
            Οδηγίες εγκατάστασης
          </h3>
          <h5 className='mt-5 mb-5'>Βήμα 1: Δυνατότητες Plugin Frontend</h5>

          <ol>
            <li>
              Εμφάνιση τρόπου αποστολής "BOX NOW - Παράδοση σε Αυτόματο Μηχάνημα Παραλαβής" στο βήμα
              Αποστολής του Magento checkout.
            </li>
            <li>
              Απόκρυψη του τρόπου αποστολής BOX NOW αν ο καταναλωτής επιλέξει χώρα αποστολής άλλη
              πέραν της Ελλάδος.
            </li>
            <li>
              Με την επιλογή του τρόπου αποστολής "Παραλαβή από BOX NOW" εμφανίζεται iframe με
              χάρτη, τα σημεία, το search βάσει διεύθυνσης, ΤΚ κλπ. (και ότι λοιπές δυνατότητες).
            </li>
            <li>
              Με την επιλογή από τον χρήστη του σημείου παραλαβής, εμφανίζεται σε σχετικό σημείο η
              διεύθυνση του BOX NOW σημείου παραλαβής.
            </li>
            <li>
              Το σημείο παραλαβής και η διεύθυνση του σημείου παραλαβής αποθηκεύονται στο default
              πεδίο του Magento: Shipping Title και σε custom πεδίο στην διεύθυνση αποστολής
              (shipping address).
            </li>
            <li>
              Υπολογισμός και προσθήκη κόστους στο σύνολο της παραγγελίας στο checkout (flat κόστος
              που ορίζεται από τον διαχειριστή).
            </li>
            <li>
              Απόκρυψη του τρόπου πληρωμής Αντικαταβολή (αφορά την native Magento Αντικαταβολή)
            </li>
            <li>
              Τρόπος πληρωμής για αντικαταβολή BOX NOW. Λεκτικό "Αντικαταβολή (Πληρωμή με
              χρεωστική/πιστωτική κάρτα μέσω payment link σε ασφαλές τραπεζικό περιβάλλον).".
              Εμφανίζεται όταν επιλεχθεί ο τρόπος αποστολής BOX NOW.
            </li>
            <li>
              Το Shipping Title ακολουθεί το Magento σε όλα τα σημεία που αυτό εμφανίζεται στον
              καταναλωτή, στο backend και στα transactional emails.
            </li>
            <li>
              Επιπλέον, δημιουργείται custom πεδίο στη διεύθυνση αποστολής (custom address
              attribute) του πελάτη, στο οποίο αποθηκεύεται το ID του BOX NOW σημείου παραλαβής
              (boxnow_delivery_id)
            </li>
          </ol>

          <h5 className='mt-5 mb-5'>Βήμα 2: Δυνατότητες Plugin Backend</h5>

          <p>
            Ο διαχειριστής επεξεργάζεται την κάθε παραγγελία/αποστολή ξεχωριστά, έχοντας έτσι τη
            δυνατότητα να δημιουργήσει πολλαπλά vouchers ανά παραγγελία.
          </p>

          <p>Ο διαχειριστής αφού μπει στην παραγγελία ακολουθεί τα εξής βήματα:</p>

          <ol>
            <li>Πατάει το default κουμπί Shipment του Magento</li>
            <li>
              Επιλέγει τα προϊόντα και την ποσότητα ανά προϊόν που θέλει να αποστείλει (μερική
              αποστολή)
            </li>
            <li>
              Πατώντας αποθήκευση εκτελούνται τα ακόλουθα:
              <ul className='list-inside'>
                <li>στέλνεται αυτόματα request στο API της BOX NOW για δημιουργία voucher.</li>
                <li>
                  με την επιτυχή δημιουργία του voucher μαρκάρονται οι ποσότητες ως απεσταλμένες.
                </li>
                <li>
                  (προαιρετικά) ενημερώνεται μέσω email από το Magento ο πελάτης για την αποστολή.
                </li>
                <li>
                  η αποστολή των vouchers προς τον έμπορο γίνεται αυτόματα, μέσω email από την BOX
                  NOW.
                </li>
                <li>η ενημέρωση του καταναλωτή θα γίνεται αυτόματα από την BOX NOW.</li>
              </ul>
            </li>
          </ol>

          <h5 className='mt-5 mb-5'>Βήμα 3: Υποστήριξη BOX NOW Αντικαταβολής</h5>

          <ol>
            <li>
              Νέος τρόπος πληρωμής για αντικαταβολή BOX NOW. Λεκτικό "Αντικαταβολή (Πληρωμή με
              χρεωστική/πιστωτική κάρτα μέσω payment link σε ασφαλές τραπεζικό περιβάλλον)."
            </li>
            <li>
              Κλειδωμένο λεκτικό για την υπηρεσία αντικαταβολή έτσι ώστε να μην μπορεί να το αλλάξει
              ο διαχειριστής
            </li>
            <li>Δυνατότητα Ενεργοποίησης / Απενεργοποίησης.</li>
            <li>
              Διαθέσιμη υπηρεσία αντικαταβολής μόνο για πελάτες με χώρα παράδοσης Ελλάδα και μέθοδο
              αποστολής "Παραλαβή από BOX NOW".
            </li>
            <li>Δυνατότητα καθορισμού σειράς εμφάνισης του συγκεκριμένου τρόπου πληρωμής.</li>
            <li>
              Custom πεδίο που μπορεί ο διαχειριστής να σημειώσει οδηγίες για τον τρόπο πληρωμής.
            </li>
          </ol>

          <h5 className='mt-5 mb-5'>Βήμα 4: Εγκατάσταση</h5>

          <p>
            Μπορείτε να κατεβάσετε την τελευταία version του plugin. Μετά το κατέβασμα του αρχείου:
          </p>

          <ol>
            <li>Κάντε unzip το zip file στο Magento 2 directory, στο: app/code/</li>
            <li>
              Ενεργοποιήστε το module τρέχοντας το: php bin/magento module:enable Elegento_BoxNow
            </li>
            <li>Εφαρμόστε τα database updates τρέχοντας το: php bin/magento setup:upgrade</li>
            <li>
              Flush cache τρέχοντας το: php bin/magento cache:flush Το module είναι πλέον έτοιμο να
              ενεργοποιηθεί από την admin σελίδα.
            </li>
          </ol>

          <h5 className='mt-5 mb-5' id='2'>
            Βήμα 5: Σελίδα Admin / Dashboard
          </h5>

          <p className='bolder'>Delivery Method</p>
          <p>
            Αρχικά, πρέπει να ενεργοποιηθεί το BOX NOW σα μέθοδος αποστολής στο store configuration.
            Stores -{'>'} Settings -{'>'} Configuration
          </p>

          <img className='mt-5 mb-5' src={magento21} />

          <p>
            Στη συνέχεια πηγαίνετε:{' '}
            <span className='bolder'>
              Sales -{'>'} Delivery Methods -{'>'} BOX NOW
            </span>
          </p>

          <img className='mt-5 mb-5' src={magento22} />

          <p>
            Σε αυτό το σημείο πρέπει να αλλάξετε τη BOX NOW μέθοδο αποστολής σε Enable: “Yes” και
            επιβεβαιώστε πως είναι ενεργοποιημένο για το σωστό Store View ή Website επίσης.
          </p>
          <p>
            Θα πρέπει να συμπληρώσετε τις ρυθμίσεις της μεθόδου αποστολής για να εμφανιστεί η
            μέθοδος σαν επιλογή στο checkout.
          </p>

          <img className='mt-5 mb-5' src={magento23} />

          <p>
            Τέλος, θα πρέπει να συμπληρώσετε τις πληροφορίες Πελάτη και τα Api Details για να
            ολοκληρωθεί η εγκατάσταση. Οι πληροφορίες θα δοθούν από την BOX NOW.
          </p>

          <img className='mt-5 mb-5' src={magento24} />

          <p className='bolder'>Payment Method</p>
          <p>
            Θα πρέπει να ενεργοποιηθεί το BOX NOW σα τρόπος αποστολής στο store configuration.
            <span>
              Stores -{'>'} Settings -{'>'} Configuration
            </span>
          </p>

          <img className='mt-5 mb-5' src={magento25} />

          <p>
            Στη συνέχεια πηγαίνετε:
            <span className='bolder'>
              Sales -{'>'} Payment Methods -{'>'} BOX NOW Payment
            </span>
          </p>

          <img className='mt-5 mb-5' src={magento26} />

          <p>
            Σε αυτό το σημείο πρέπει να αλλάξετε τη BOX NOW μέθοδο πληρωμής σε Enable: “Yes” και
            επιβεβαιώστε πως είναι ενεργοποιημένο για το σωστό Store View ή Website επίσης.
          </p>
          <p>
            Να σημειωθεί ότι το λεκτικό που εμφανίζεται στο front-end είναι κλειδωμένο και δεν
            μπορεί να αλλαχτεί από τους διαχειριστές.
          </p>
          <p>
            Η ενεργοποίηση του BOX NOW Payment αντικαθιστά την default Cash On Delivery μέθοδο του
            Magento.
          </p>

          <img className='mt-5 mb-5' src={magento27} />

          <h5 className='mt-5 mb-5' id='3'>
            Βήμα 6: Χρησιμοποιώντας το plugin
          </h5>

          <p>
            Κατά το checkout ο καταναλωτής μπορεί να επιλέξει τη μέθοδο αποστολής BOX NOW από τις
            διαθέσιμες μεθόδους αποστολής.
          </p>

          <img className='mt-5 mb-5' src={magento28} />

          <p>
            Αφού επιλέξει BOX NOW Express, θα εμφανιστεί ο χάρτης και ο καταναλωτής θα μπορεί να
            επιλέξει το επιθυμητό σημείο παραλαβής.
          </p>

          <img className='mt-5 mb-5' src={magento29} />

          <p>
            Αφού επιλέξει BOX NOW σημείο παραλαβής μπορεί να προχωρήσει κανονικά στο επόμενο βήμα
            της πληρωμής.
          </p>
          <p>
            Αν ο πελάτης έχει επιλέξει την BOX NOW σα τρόπο παράδοσης στο προηγούμενο βήμα, θα έχει
            δυνατότητα στην σελίδα πληρωμής, να επιλέξει ‘Πληρωμη σε BOX NOW με μετρητά/κάρτα’. Η
            συγκεκριμένη μέθοδος δεν έχει κάποιο επιπλέον κόστος.
          </p>

          <img className='mt-5 mb-5' src={magento210} />

          <p>
            Αφού ο πελάτης επιλέξει και τον τρόπο πληρωμής, μπορεί να ολοκληρώσει την διαδικασία του
            checkout.
          </p>

          <h5 className='mt-5 mb-5' id='4'>
            Βήμα 7: Magento Dashboard / Σύστημα διαχείρισης αποστολών
          </h5>

          <p>
            Από το διαχειριστικό του Magento ο διαχειριστής μπορεί να δημιουργήσει το
            αυτοματοποιημένο voucher παραλαβής, ακολουθώντας τα βήματα της αποστολής χωρίς να
            χρειαστεί να συμπληρώσει κάποια πληροφορία.
          </p>
          <p>
            Με σκοπό τη δημιουργία του αυτοματοποιημένου voucher παραλαβής, ο διαχειριστής θα πρέπει
            να ολοκληρώσει τη διαδικασία του “Invoice” και στη συνέχεια τη διαδικασία του“Ship”.
          </p>
          <p>
            Αφού ο διαχειριστής ολοκληρώσει τα προηγούμενα δύο βήματα, μπορεί να κατεβάσει το
            voucher της παραλαβής από τη σελίδα της παραγγελίας πατώντας τα κουμπιά “Download BOX
            NOW Voucher” κατεβάζοντας το αρχείο σε PDF format.
          </p>

          <img className='mt-5 mb-5' src={magento211} />

          <p>
            Τα κουμπιά είναι διαθέσιμα και ξεχωριστά. Ο διαχειριστής θα πρέπει να επισκεφτεί το
            Shipments -{'>'} View . Το κουμπί εμφανίζεται στην επάνω μπάρα ενεργειών (στο ίδιο
            σημείο με τη σελίδα της παραγγελίας).
          </p>

          <img className='mt-5 mb-5' src={magento212} />

          <h5 id='5'>Προσοχή:</h5>
          <ol>
            <li>Τεστάρετε το plugin μόνο με τα tage Api keys.</li>
            <li>
              Επιλέξτε το stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
              10447.
            </li>
            <li>
              Για να λάβετε το voucher της παραγγελίας πρέπει η παραγγελία να είναι
              ολοκληρωμένη/πληρωμένη.
            </li>
          </ol>

          <h5 className='mt-5'>Υποστήριξη</h5>
          <p>
            Για οποιαδήποτε ερώτηση για την διασύνδεση σας με την υπηρεσία μας παρακαλώ να καλέστε
            μας στο 2111005330 ή στείλτε μας στο <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
