import React from 'react';
import { withTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import magento from '../assets/magento.png';
import cskart from '../assets/cskart.png';
import opencart from '../assets/opencart.png';
import presta from '../assets/presta.png';
import shopify from '../assets/shopify.png';
import woo from '../assets/woo.png';
import { Typography, Theme, Container } from '@mui/material';
import { colors } from '../theme/theme';
import BoxNowContainer from 'components/BoxNowContainer';
import useTranslation from '../translations/useTranslation';

export const Eshops = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const Styles = withTheme(styled.div`
    .formContainer {
      background-color: red;
    }

    .eshop-img {
      object-fit: cover;
    }

    .plugin-card-sizing {
      width: 280px;
      height: 280px;
    }

    .main-title-typo {
      font-family: Rubik, sans-serif;
      font-weight: 500;
      font-size: 1.25rem;
      width: 100%;
      margin-top: 0px;
      color: rgb(38, 5, 99);
    }

    .eshop_button {
      padding: 8px;
      background-color: ${colors.secondary};
      text-decoration: none;
    }

    .next-typo {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      font-weight: 500;
      font-size: 1.25rem;
      color: white;
    }

    .eshop_button:hover {
      cursor: pointer;
    }

    .hover01 a img {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.4s ease-in-out;
    }

    .hover01 a:hover img {
      -webkit-transform: scale(1.1);
      transform: scale(1.3);
      cursor: pointer;
    }

    .main-title-eshops {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      font-weight: 800;
      font-size: 2.3rem;
      color: ${colors.secondary};
      text-align: center;
      line-height: 1.1;
    }
  `);
  return (
    <BoxNowContainer>
      <Styles>
        <Container>
          <div className='d-flex flex-column align-items-center mt-5'>
            <Typography className='main-title-eshops'>{t('onboard.eshopsTitle')}</Typography>
          </div>
          <div className='d-flex flex-wrap align-items-center justify-content-center'>
            <div className='d-flex flex-column m-5'>
              <h4 className='align-self-center'>WooCommerce</h4>
              <div className='border hover01 bg-white d-flex align-items-center justify-content-center plugin-card-sizing'>
                <a
                  className='d-flex align-items-center justify-content-center'
                  onClick={() => navigate('/eshops/woocommerce')}
                >
                  <div className='d-flex justify-content-center p-5'>
                    {/* <img src="/media/woo.png" className="w-50"> */}
                    <img src={woo} width='180px' height='180px' />
                  </div>
                </a>
              </div>
              <a className='eshop_button' onClick={() => navigate('/eshops/woocommerce')}>
                <Typography className='next-typo'>{t('onboard.showmore')}</Typography>
              </a>
            </div>
          </div>

          <div className='d-flex flex-wrap align-items-center justify-content-center'>
            <div className='d-flex flex-column m-5'>
              <h4 className='align-self-center'>Magento</h4>
              <div className='border hover01 bg-white d-flex align-items-center justify-content-center plugin-card-sizing'>
                <a
                  className='d-flex align-items-center justify-content-center'
                  onClick={() => navigate('/eshops/magento1')}
                >
                  <div className='d-flex justify-content-center p-5'>
                    <img src={magento} width='180px' height='180px' className='hover01' />
                  </div>
                </a>
              </div>
              <a className='eshop_button' onClick={() => navigate('/eshops/magento1')}>
                <Typography className='next-typo'>{t('onboard.showmore')}</Typography>
              </a>
            </div>
            <div className='d-flex flex-column m-5'>
              <h4 className='align-self-center'>OpenCart</h4>
              <div className='border hover01 bg-white d-flex align-items-center justify-content-center plugin-card-sizing'>
                <a
                  className='d-flex align-items-center justify-content-center'
                  onClick={() => navigate('/eshops/opencart')}
                >
                  <div className='d-flex justify-content-center'>
                    <img src={opencart} width='250px' height='250px' className='w-75' />
                  </div>
                </a>
              </div>
              <a className='eshop_button' onClick={() => navigate('/eshops/opencart')}>
                <Typography className='next-typo'>{t('onboard.showmore')}</Typography>
              </a>
            </div>
            <div className='d-flex flex-column m-5'>
              <h4 className='align-self-center'>PrestaShop</h4>
              <div className='border hover01 bg-white d-flex align-items-center justify-content-center plugin-card-sizing'>
                <a
                  className='d-flex align-items-center justify-content-center'
                  onClick={() => navigate('/eshops/prestashop')}
                >
                  <div className='d-flex justify-content-center p-5'>
                    {/* <img src="/media/presta.png" className="w-50"> */}
                    <img src={presta} width='180px' height='180px' />
                  </div>
                </a>
              </div>
              <a className='eshop_button' onClick={() => navigate('/eshops/prestashop')}>
                <Typography className='next-typo'>{t('onboard.showmore')}</Typography>
              </a>
            </div>

            <div className='d-flex flex-column m-5'>
              <h4 className='align-self-center'>CsCart</h4>
              <div className='border hover01 bg-white d-flex align-items-center justify-content-center plugin-card-sizing'>
                <a
                  className='d-flex align-items-center justify-content-center'
                  onClick={() => navigate('/eshops/cscart')}
                >
                  <div className='d-flex justify-content-center'>
                    {/* <img src="/media/cskart.png" className="w-50"> */}
                    <img src={cskart} width='300px' height='300px' />
                  </div>
                </a>
              </div>
              <a className='eshop_button' onClick={() => navigate('/eshops/cscart')}>
                <Typography className='next-typo'>{t('onboard.showmore')}</Typography>
              </a>
            </div>

            <div className='d-flex flex-column m-5'>
              <h4 className='align-self-center'>Shopify</h4>
              <div className='border hover01 bg-white d-flex align-items-center justify-content-center plugin-card-sizing'>
                <a
                  className='d-flex align-items-center justify-content-center'
                  onClick={() => navigate('/eshops/shopify')}
                >
                  <div className='d-flex justify-content-center p-5'>
                    {/* <img src="/media/shopify.png" className="w-50"> */}
                    <img src={shopify} width='180px' height='180px' />
                  </div>
                </a>
              </div>
              <a className='eshop_button' onClick={() => navigate('/eshops/shopify')}>
                <Typography className='next-typo'>{t('onboard.showmore')}</Typography>
              </a>
            </div>
            <div className='d-flex flex-column m-5'>
              <h4 className='align-self-center'>Magento2</h4>
              <div className='border hover01 bg-white d-flex align-items-center justify-content-center plugin-card-sizing'>
                <a
                  className='d-flex align-items-center justify-content-center'
                  onClick={() => navigate('/eshops/magento2')}
                >
                  <div className='d-flex justify-content-center p-5'>
                    {/* <img src="/media/magento.png" className="w-50"> */}
                    <img src={magento} width='180px' height='180px' />
                  </div>
                </a>
              </div>
              <a className='eshop_button' onClick={() => navigate('/eshops/magento2')}>
                <Typography className='next-typo'>{t('onboard.showmore')}</Typography>
              </a>
            </div>
          </div>

          <div className='mb-5'>
            <div className='d-flex flex-row'>
              <div className='d-flex justify-content-center'>
                {/* <img className="w-50" src="/media/icons/contact-us-icon.svg"> */}
              </div>
              <div className='d-flex flex-row align-items-center'>
                <p className='h5 main-title-typo mt-5 text-center'>
                  {t('onboard.forAnyQuestion1')}
                  <a href='tel: 2111005330'> 2111005330</a> {t('onboard.forAnyQuestion2')}{' '}
                  <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Styles>
    </BoxNowContainer>
  );
};
