import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Theme, Typography } from "@mui/material";
import FAQ from "components/FAQ";
import Hero from "components/Hero";
import Steps from "components/Steps";
import BoxNowContainer from "components/BoxNowContainer";
import useTranslation from "translations/useTranslation";
import Services from "components/Services";
import LockerMapSection from "components/LockerMap";
import LockerCount from "components/LockerCount";
import { useLanguageNavigate } from "app/hooks";

export default function StartingPage() {
  const { t } = useTranslation();
  const navigate = useLanguageNavigate();
  const Styles = withTheme(styled.div`
    .formContainer {
      background-color: red;
    }

    .button__toForm {
      width: 280px;
      height: 60px;
      border-radius: 0px;
      margin-top: 1em;
      background-color: rgb(68, 214, 45);
      border: none;
    }
    .button__toForm:hover {
      cursor: pointer;
    }
    .next-typo {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};
      font-weight: 700;
      font-size: 1.25rem;
      color: white;
    }
    .main-title-typo {
      font-family: Rubik, sans-serif;
      font-weight: 500;
      font-size: 1.75rem;
      width: 100%;
      margin-top: 0px;
      color: rgb(38, 5, 99);
    }
    .under-main-title-margin {
      font-family: Rubik, sans-serif;
      font-weight: 500;
      font-size: 1.2rem;
      color: rgb(125, 121, 134);
      margin-top: 1em;
    }
  `);
  return (
    <BoxNowContainer>
      <Hero />
      <Steps />
      <Styles>
        <Button
          className="button__toForm mb-5"
          onClick={() => navigate("/form")}
        >
          <Typography className="next-typo">
            {t("onboard.becomePartner.button")}
          </Typography>
        </Button>
      </Styles>
      <Services />
      <LockerMapSection />
      <LockerCount />
      <FAQ />
    </BoxNowContainer>
  );
}
