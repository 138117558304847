import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import shopify1 from '../../assets/shopify/shopify1.png';
import shopify2 from '../../assets/shopify/shopify2.png';
import shopify3 from '../../assets/shopify/shopify3.png';
import shopify4 from '../../assets/shopify/shopify4.png';
import shopify5 from '../../assets/shopify/shopify5.png';
import shopify6 from '../../assets/shopify/shopify6.png';
import shopify7 from '../../assets/shopify/shopify7.png';
import shopify8 from '../../assets/shopify/shopify8.png';
import shopify9 from '../../assets/shopify/shopify9.png';
import shopify10 from '../../assets/shopify/shopify10.png';
import shopify11 from '../../assets/shopify/shopify11.png';
import shopify12 from '../../assets/shopify/shopify12.png';
import shopify13 from '../../assets/shopify/shopify13.png';
import shopify14 from '../../assets/shopify/shopify14.png';
import shopify15 from '../../assets/shopify/shopify15.png';
import shopify16 from '../../assets/shopify/shopify16.png';
import shopify17 from '../../assets/shopify/shopify17.png';
import shopify18 from '../../assets/shopify/shopify18.png';
import shopify19 from '../../assets/shopify/shopify19.png';
import shopify20 from '../../assets/shopify/shopify20.png';
import shopify21 from '../../assets/shopify/shopify21.png';
import shopify22 from '../../assets/shopify/shopify22.png';
import shopify23 from '../../assets/shopify/shopify23.png';
import shopify24 from '../../assets/shopify/shopify24.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';
import BoxNowContainer from 'components/BoxNowContainer';

export const ShopifyEn = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    img {
      max-width: 1100px !important;
      max-height: 400px;
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 90% !important; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='container d-flex flex-row'>
        <div className='w-50 position-relative hidden'>
          <aside className='sidebar'>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>Shopify</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Step 1: Installation</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Step 2: Configuration</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Step 3: Activation</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Step 4: Cash On Delivery verbal</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Step 5: Order management</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#6'>Support</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex  align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/eshops');
                    }}
                  >
                    Go back
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Shopify</h2>

          <h4 className='mt-5'>Installation guide.</h4>
          <h4 className='mt-5' id='1'>
            Step 1. Installation
          </h4>
          <p>Download the BOX NOW app:</p>
          <a href='https://apps.shopify.com/boxnow'>
            BoxNow - Automated process for BoxNow parcel delivery lockers. | Shopify App Store
          </a>
          <p>
            Then, in Shopify’s administration page, in Apps section, BOX NOW option will appear.
          </p>

          <img className='mt-5 mb-5' src={shopify1} />

          <h4 id='2'>Step 2: App Configuration</h4>
          <p>
            Click <span className='bolder'>BOX NOW → Settings</span>{' '}
          </p>
          <p>Complete the field with the relative information - codes provided by BOX NOW staff:</p>
          <p>
            <span className='bolder'>Client ID</span>: Client ID as provided (π.χ.
            9d8gg3de-8119-4tea-991e-37ff20eq5e50).
          </p>
          <p className='text-break'>
            <span className='bolder'>Client Secret</span>: Client Secret as provided (e.g.
            c1144abc1db1f3186442a4ec3d3e953de0105c12759a2d82771a888256659e67f).
          </p>
          <p>
            <span className='bolder'>Partner Id</span>: Fill in the provided partner_id (e.g. 1)
          </p>
          <p>
            <span className='bolder'>API URL</span>Complete the API URL (e.g.{' '}
            <a href='https://api-production.boxnow.gr'>https://api-production.boxnow.gr</a>).
          </p>
          <p>
            <span className='bolder'>Enable Lockers on Checkout</span>: By checking this field Box
            Now service is being displayed in order’s checkout.
          </p>
          <p>
            <span className='bolder'>Add More Locations</span>: Here you register your warehouse,
            filling in id and name, (e.g. 8 - Partner) and then “add new Location”. In case more
            than one warehouse need to be registered you follow the above as many times as needed.
          </p>
          <p>
            <span className='bolder'>Shipping</span>: Shipping cost.
          </p>
          <p>
            <span className='bolder'>Free Shipping</span>: The cost from which no shipping fee will
            be charged.
          </p>
          <p>*The “Shipping” and “Free Shipping” fields must always have a value.</p>
          <p>
            <span className='bolder'>COD</span>: Complete the provided verbal. Save changes.
          </p>

          <img className='mt-5 mb-5' src={shopify2} />

          <h4 id='3'>Step 3: Activation</h4>
          <p>Activating BOX NOW as a shipping method.</p>
          <p className='bolder'>
            Settings → Shipping and delivery → Manage → Carrier and app rates
          </p>

          <img className='mt-5 mb-5' src={shopify3} />

          <h4 id='4'>Step 4: Cash On Delivery verbal</h4>
          <p>
            You need to register the verbal for COD in the payment settings section in order to
            appear at the checkout.
            <span className='bolder'>
              {' '}
              Settings → Payments → Cash on Delivery (COD) → Manage → Additional details.{' '}
            </span>
          </p>
          <p>Fill in the verbal you used in the app configuration (e.g. BOX NOW PAY ON THE GO).</p>

          <img className='mt-5 mb-5' src={shopify4} />

          <h4 id='5'>Step 5: Order management</h4>
          <p>
            By choosing the Box Now app, a list with all the e-shop’s orders appears. To show only
            Box Now related orders you need to add the Box Now filter. This will be achieved through
            the app
            <span className='bolder'>SHOPIFY FLOW.</span>
          </p>
          <p>From your Shopify’s administrative home page click on the Search bar.</p>

          <img className='mt-5 mb-5' src={shopify5} />

          <p>Then click on the Apps category and search for “Shopify Flow”.</p>

          <img className='mt-5 mb-5' src={shopify6} />

          <p>
            Afterwards, click on Search for “Shopify Flow” in the Shopify App Store and wait for the
            Shopify app store to load. When the page loads click on Shopify Flow by Shopify.
          </p>

          <img className='mt-5 mb-5' src={shopify7} />

          <p>Finally click on Add app</p>

          <img className='mt-5 mb-5' src={shopify8} />

          <p>
            and Install app. Now the app has been configured in your e-shop and you can find it in
            the Apps section.
          </p>

          <img className='mt-5 mb-5' src={shopify9} />

          <p>
            You can check the Shopify Flow app by clicking the Apps under the Sales Channels left in
            your menu.Click on the button Create workflow to create a new workflow.
          </p>

          <img className='mt-5 mb-5' src={shopify10} />

          <p>
            Click on the button Select a trigger and start by creating a condition which will
            trigger our workflow. In this instance we need to check , after the order is created if
            a BoxNow locker was chosen during the checkout and whether it has a “box-now” order tag
            on your administrative console.
          </p>

          <img className='mt-5 mb-5' src={shopify11} />

          <p>
            In the menu that loaded click on the right side at the Search field and type “order”.
            After the results are loaded click on the Order created.
          </p>

          <img className='mt-5 mb-5' src={shopify12} />

          <p>Click on + next to Then and afterwards on Condition to add the condition.</p>

          <img className='mt-5 mb-5' src={shopify13} />

          <p>
            Πατήστε πάνω στο <span className='bolder'>Click on Add criteria.</span>.
          </p>

          <img className='mt-5 mb-5' src={shopify14} />

          <p>
            On the menu that pops up click on Order on the left and then scroll on the fields that
            will show up on the right until you find the filed “shippingLines”
          </p>

          <img className='mt-5 mb-5' src={shopify15} />

          <p>
            Afterwards scroll on the shippingLines fileds on the right and click on{' '}
            <span className='bolder'>source.</span>
          </p>

          <img className='mt-5 mb-5' src={shopify16} />

          <p>
            Finally, click on <span className='bolder'>“Enter source”</span> and type{' '}
            <span className='bolder'>“Box Now Service”</span>
          </p>

          <img className='mt-5 mb-5' src={shopify17} />

          <p>
            Now click on <span className='bolder'>+</span> on the right side of Then in the
            condition you just created and afterwards on <span className='bolder'>Action.</span>
          </p>

          <img className='mt-5 mb-5' src={shopify18} />

          <p>
            Here you will set up the process that will me executed when the above condition is met.
            In the menu that will appear on the right side of your screen you can already see and
            click on the button <span className='bolder'>“Add order tags”</span>, otherwise look for
            it in the Search and click it.
          </p>

          <img className='mt-5 mb-5' src={shopify19} />

          <p>
            Finally, click on the filed <span className='bolder'>“Start typing”</span> and type{' '}
            <span className='bolder'>“box-now”.</span>
          </p>

          <img className='mt-5 mb-5' src={shopify20} />

          <p>
            Now the only thing left is for the workflow to be activated. Click on the button at the
            top of the right side <span className='bolder'>“Turn on workflow”</span> and then on the
            window that appears click
            <span className='bolder'>“Turn on”</span> for the process to be completed. A message
            will appear that the workflow has been activated and then when you go back you can see
            it as <span className='bolder'>Active</span> instead of Draft.
          </p>

          <img className='mt-5 mb-5' src={shopify21} />

          <p>
            You can now complete a test order to see that the condition has been configured properly
            and the order tag has been added. In addition you can check under the Workflows your Run
            history which includes your history of completed workflows (up to 7 days old).
          </p>

          <img className='mt-5 mb-5' src={shopify22} />

          <p>
            For creating individual vouchers, we choose an order and select{' '}
            <span className='bolder'>Create Voucher → Download Voucher.</span>
          </p>

          <p>
            You can change the amount of parcels (vouchers), by typing the desired amount in the
            field <span className='bolder'>Parcels</span>.
          </p>

          <p>
            *In case that the agreed deal between the e-shop and BoxNow is that you will put the
            parcels in the locker on your own( any apm), you can choose the size of the locker from
            the Compartment Size section (Small, Medium & Large). In this case the warehouse_id must
            have the value “2”.
          </p>

          <img className='mt-5 mb-5' src={shopify23} />

          <p>
            In case of multiple vouchers, we choose the orders and select
            <span className='bolder'>Create Voucher(s) → Download Voucher(s)</span>
          </p>

          <img className='mt-5 mb-5' src={shopify24} />

          <h4 id='6'>ATTENTION:</h4>
          <ol>
            <li>
              To use the BOX NOW app you need one of the following subscription plans:
              <ol type='a'>
                <li>basic + carrier shipping monthly or yearly subscription</li>
                <li>advanced + carrier shipping subscription</li>
                <li>plus subscription provided for free.</li>
              </ol>
            </li>
            <li>Test the plugin using only the Stage API keys.</li>
            <li>
              Choose the stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
              10447.
            </li>
            <li>To get the orders voucher you need to set the order as “Completed” first.</li>
          </ol>

          <h4 className='mt-5'>Support</h4>
          <p>
            For any questions regarding the above please call at:+302111005330 or email us at:
            <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
