import { Container, Paper, Theme, useMediaQuery, Typography } from '@mui/material';
import cover from '../assets/hero.png';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '../theme/theme';
import useTranslation from '../translations/useTranslation';

const CoverImage = withTheme(styled(Paper)`
  margin: ${({ theme }: { theme: Theme }) => theme.spacing(4, 0)};
  border-radius: 0;
  height: 500px;
  position: relative;

  background-image: url(${cover});
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down('lg')} {
    height: 500px;
  }

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down('md')} {
    height: 400px;
  }

  ${({ theme }: { theme: Theme }) => theme.breakpoints.down('sm')} {
    height: 290px;
  }

  /* Text styling */
  .image-text {
    z-index: 1; /* Place text above the overlay */
    color: white;
    font-size: 1.6rem;
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
  }
`);

const Hero = () => {
  // const isLowerLG = useMediaQuery(theme.breakpoints.down("lg"));
  // const { t } = useTranslation();

  return (
    <Container maxWidth='lg'>
      <CoverImage>
        {/* Text in the middle */}
        {/* <Typography variant={isLowerLG ? "h5" : "h4"} className="image-text">
          {t("onboard.image.title")}
        </Typography> */}
      </CoverImage>
    </Container>
  );
};

export default Hero;
