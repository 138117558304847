import { useAppSelector } from '../app/hooks';
import { p2pSelector } from '../features/p2pSlice';
import { useCallback } from 'react';

export type UseTranslationReturn = {
  t: (key: string | undefined, ...args: string[]) => string | undefined;
};

const useTranslation = (): UseTranslationReturn => {
  const { translations } = useAppSelector(p2pSelector);

  const translate: UseTranslationReturn['t'] = useCallback(
    (key: string | undefined, ...args: string[]) => {
      if (!key) return undefined;

      let template = translations[key];

      //Simple solution could be improved
      args.forEach((arg, i) => (template = template.replace(`{${i + 1}}`, arg)));

      return template;
    },
    [translations]
  );

  return {
    t: translate,
  };
};

export default useTranslation;
