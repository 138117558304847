import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import Prestashop1 from '../../assets/prestashop/PrestaShop1.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';
import t1 from '../../assets/partnerportal/t1.png';
import t2 from '../../assets/partnerportal/t2.png';
import t3 from '../../assets/partnerportal/t3.png';
import t4 from '../../assets/partnerportal/t4.png';
import t5 from '../../assets/partnerportal/t5.png';
import t6 from '../../assets/partnerportal/t6.png';
import t7 from '../../assets/partnerportal/t7.png';
import t8 from '../../assets/partnerportal/t8.png';

export default function PrestaShop() {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
      ol {
        padding-left: 32px;
      }
      li {
        text-align: left;
      }
      p {
        text-align: left;
        padding-left: 16px;
      }
      a {
        font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
        text-decoration: none;
        color: ${colors.secondary};
        font-weight: 500;
        -webkit-text-decoration: underline #f1bfdf 2px;
        text-decoration: underline #f1bfdf 2px;
        text-underline-position: under;
      }
      aside ul {
        padding-left: 16px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='container d-flex flex-row'>
        <div className='position-relative'>
          <aside className='sidebar hidden'>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a
                  className='h4'
                  href=''
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/partner-portal');
                  }}
                >
                  Partner Portal
                </a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Βήμα 1: Είσοδος</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Βήμα 2: Χρήση του Partner Portal</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>2.1 Συμπλήρωση φόρμας</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>2.2 Ανεβάστε ένα αρχείο CSV</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Υποστήριξη</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <span className='h5 d-flex  align-items-center'>
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/form-completed');
                        }}
                      >
                        Πίσω
                      </a>
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
        </div>
        <div className='mobile-margin'>
          <h2>Partner Portal</h2>
          <h5 className='mt-5' id='1'>
            Βήμα 1: Είσοδος
          </h5>
          <p>
            Κάνετε είσοδο στο Portal πληκτρολογώντας το κινητό εγγραφής και πατώντας στο
            <a href='https://partner.boxnow.gr/login-code'>Box Now - Partner Portal </a>
            στην συνέχεια confirm.
          </p>

          <img className='mt-5 mb-5' src={t1} />

          <p>Στην συνέχεια πληκτρολογήστε τον 6ψήφιο κωδικό που λάβατε στο μήνυμα.</p>

          <img className='mt-5 mb-5' src={t2} />

          <h5 className='mt-5' id='2'>
            Βήμα 2: Χρήση του Partner Portal
          </h5>
          <p>Εμφανίζονται οι υπάρχουσες παραγγελίες στην αρχική οθόνη.</p>

          <img className='mt-5 mb-5' src={t3} />

          <p>
            Για να δημιουργήσετε μία παραγγελία επιλέξτε το κουμπί
            <span>Order from Warehouse</span>.
          </p>

          <img className='mt-5 mb-5' src={t4} />

          <p>Υπάρχουν δύο επιλογές.</p>
          <ol>
            <li>Συμπλήρωση φόρμας</li>
            <li>Ανέβασμα ενός CSV αρχείου</li>
          </ol>

          <p id='3'>
            <u>2.1 Συμπλήρωση φόρμας:</u>
          </p>
          <p>Απαιτούμενα πεδία (στοιχεία πελάτη)</p>

          <ul className='list-inside'>
            <li>Πλήρες όνομα (π.χ. Γιώργος Παπάς)</li>
            <li>Τηλεφωνικός αριθμός (π.χ.+306912345678)</li>
            <li>
              Email (π.χ. <a href='mailto: test@test.gr'>test@test.gr</a>)
            </li>
            <li>APM number (locker id)</li>
            <li>Μέθοδος πληρωμής (Prepaid ή Αντικαταβολή)</li>
            <li>
              Επιστροφές (πάντοτε <span className='bolder'>allowed</span>)
            </li>
          </ul>

          <p className='mt-3'>
            Επιλέγετε <span className='bolder'>Δημιουργία παραγγελίας</span>
          </p>

          <img className='mt-5 mb-5' src={t5} />

          <p>
            Με το που δημιουργείται μία παραγγελία, η εγγραφή θα εμφανιστεί στην αρχική σελίδα καθώς
            και θα σταλεί η ειδοποίηση για το voucher.
          </p>

          <img className='mt-5 mb-5' src={t6} />

          <p>
            Επιλέγοντας <span className='bolder'>Λάβε ετικέτα δέματος</span> εμφανίζεται το voucher.
          </p>

          <p className='mt-5' id='4'>
            <u>2.2 Ανεβάστε ένα αρχείο CSV:</u>
          </p>
          <p>Αρχικά κατεβάζετε το αρχείο του παραδείγματος CSV.</p>

          <img className='mt-5 mb-5' src={t7} />

          <p>
            Ανοίγετε το αρχείο CSV και καταχωρείτε τα στοιχεία των παραγγελιών, όπως βλέπετε
            παρακάτω:{' '}
          </p>

          <img className='mt-5 mb-5' src={t8} />

          <ul className='list-inside'>
            <li>
              <span className='bolder'>from_location</span>: ID Aποθήκης (constant value, σε
              περίπτωση μίας αποθήκης, αλλιώς επιλέξτε την αποθήκη που επιθυμείτε)
            </li>
            <li>
              <span className='bolder'>destination_location</span>: Locker ID (επιλεγμένο από τον
              πελάτη) π.χ. 187
            </li>
            <li>
              <span className='bolder'>customer_phone_number</span>: Τηλεφωνικός αριθμός πελάτη π.χ.
              +30 21 4 655 1234
            </li>
            <li>
              <span className='bolder'>customer_email</span>: Ηλεκτρονική διεύθυνση πελάτη π.χ.
              someone@example.com
            </li>
            <li>
              <span className='bolder'>customer_full_name</span>: Ονοματεπώνυμο του πελάτη π.χ. Test
              Test
            </li>
            <li>
              <span className='bolder'>number_of_parcels</span>: Αριθμός δεμάτων π.χ. 2
            </li>
            <li>
              <span className='bolder'>payment_mode</span>: cod (Αντικαταβολή) ή Prepaid
              (Προπληρωμή)
            </li>
            <li>
              <span className='bolder'>amount_to_be_collected</span>: π.χ. 1
            </li>
            <li>
              <span className='bolder'>price_currency</span>: Νόμισμα π.χ. EUR
            </li>
          </ul>

          <p className='mt-3'>
            Εφόσον οι στήλες έχουν συμπληρωθεί, αποθηκεύουμε το αρχείο CSV και το ανεβάζουμε
            επιλέγοντας το
            <u>ανέβασμα από τον υπολογιστή σας</u>
          </p>

          <p>
            <u className='bolder'>Προσοχή</u>: Το αρχείο πρέπει να έχει μόνο αυτή την μορφή. Κάθε
            άλλη θα επιφέρει σφάλμα.
          </p>

          <p>
            Η ιστοσελίδα εύρεσης των IDs θυρίδων για την εξυπηρέτηση πελατών είναι η:
            <a href='https://boxnow.gr/locker-finder'>BOX NOW Website - Locker Finder GR</a>{' '}
          </p>

          <h5 className='mt-5' id='5'>
            Βοήθεια:
          </h5>

          <p>
            Εάν υπάρχουν απορίες αναφορικά με τις λύσεις που προσφέρουμε ή ερωτήσεις για τον τρόπο
            διασύνδεσης παρακαλούμε απευθυνθείτε στην διεύθυνση ηλεκτρονικού ταχυδρομείου:
            <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>.
          </p>
        </div>
      </div>
    </Styles>
  );
}
