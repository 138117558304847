import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import cskart1 from '../../assets/cscart/cs-kart1.png';
import cskart2 from '../../assets/cscart/cs-kart2.png';
import cskart3 from '../../assets/cscart/cs-kart3.png';
import cskart4 from '../../assets/cscart/cs-kart4.png';
import cskart5 from '../../assets/cscart/cs-kart5.png';
import cskart6 from '../../assets/cscart/cs-kart6.png';
import cskart7 from '../../assets/cscart/cs-kart7.png';
import cskart8 from '../../assets/cscart/cs-kart8.png';
import cskart9 from '../../assets/cscart/cs-kart9.png';
import cskart10 from '../../assets/cscart/cs-kart10.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';

export const CsCart = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='d-flex flex-row'>
        <div className='position-relative'>
          <aside className='sidebar hidden'>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4' href='https://boxnow.gr/en/diy/eshops/plugins/cs-cart'>
                  Cs-Cart
                </a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Εγκατασταση / Ρύθμιση</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>ΔΗΜΙΟΥΡΓΙΑ VOUCHER </a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>ΕΚΤΥΠΩΣΗ VOUCHER </a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Βοήθεια</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex  align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/eshops');
                    }}
                  >
                    Πίσω
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Cs-Cart</h2>
          <div className='mt-5'>
            <span>Για τη λήψη και την υποστήριξη του plugin επικοινωνήστε με την:</span>
            <a href='https://wisebit.gr/#contact' target='_blank'>
              {' '}
              wisebit
            </a>
          </div>
          <div className='mt-5'>
            <span>
              Για περαιτέρω πληροφορίες παρακαλώ απευθυνθείτε στην διεύθυνση ηλεκτρονικού
              ταχυδρομείου:
              <a href='mailto: sales@wisebit.gr'>sales@wisebit.gr</a> ή τηλεφωνικά:{' '}
              <a href='tel: 215 215 0110'>215 215 0110</a>
            </span>
          </div>
          <h3 className='mt-5'>Οδηγιες Χρησης</h3>
          <h4 className='mt-5' id='1'>
            Εγκατασταση / Ρύθμιση
          </h4>

          <p>
            Μετα την εγκατασταση μεσα απο την οθονη Προσθετων πρεπει να καταχωρησετε τα στοιχεια
            χρηστη και υποκαταστηματος που εχετε λαβει απο την BOX NOW με τη συνδρομη σας στα
            αναλογα πεδια στο προσθετο .
          </p>

          <p>
            <span className='bolder'>Σημείωση</span>: στο Πεδίο Warehouses μπορείτε να εισάγετε
            πολλαπλά σημεία pickup με την μορφή : 4068 [Main Warehouse] όπου 4068 το id του pickup
            point που θα λάβετε απο την BOX NOW ενω εντός της αγκύλης βάζετε την ετικέτα με την
            οποία θα αναγνωρίζεται το pickup point κατα την δημιουργία του voucher.
          </p>

          <img className='mt-5 mb-5' src={cskart1} />

          <p>Επίσης στον τομεα Settings θα πρεπει να ορίσετε στις Ρυθμίσεις του προσθετου για:</p>
          <ol type='A'>
            <li>Τον τροπο Αντικαταβολής με BOX NOW απο τους ήδη ανοιγμένους στο κατάστημα</li>
            <li>
              Την κατάσταση παραγγελίας στην οποία θα λλάζει η παραγγελία οταν θα δημιουργείτε ένα
              Voucher
            </li>
            <li>Το πεδίο απο το οποίο θα αντλούνται οι σημειώσεις για το voucher</li>
            <li>
              Να επιλέξετε αν θα δημιουργούνται Αποστολές Αυτόματα με την εισαγωγή μιας παραγγελίας
              στο κάταστημα
            </li>
          </ol>

          <img className='mt-5 mb-5' src={cskart2} />

          <p>
            Τέλος στον τομεα Profile Fields Mapping του πρόσθετου πρεπει να χαρτογραφήσετε τα πεδία
            της διέυθυνσης αποστολής στο ταμείο δλδ να τα αντιστοιχήσετε στα πεδία ανάλογα του
            Voucher
          </p>

          <img className='mt-5 mb-5' src={cskart3} />

          <p>
            Χρήση Μέσα από μια παραγγελία θα πρέπει να δημιουργήσετε μια αποστολή με το συνηθησμένο
            τρόπο του CS-Cart πατώντας το λινκ ΔΗμιουργίας Λεπτομερούς Αποστολής
          </p>

          <img className='mt-5 mb-5' src={cskart4} />

          <p>
            Αυτο το λινκ θα σας ανοίξει ένα popup όπου για να δημιουργήσετε ένα voucher με την
            υπηρεσια της BOX NOW θα πρεπει να επιλέξετε τα εξης:
          </p>

          <img className='mt-5 mb-5' src={cskart5} />

          <ul className='list-inside'>
            <li>
              Μέθοδος Αποστολής : BOX NOW (θα πρεπει να την έχετε ανοίξει χειροκίνητα απο πρίν στις
              Μεθόδους Αποστολής)
            </li>
            <li>Tracking Number : Το αφήνετε κενό για να δημιουργηθεί αργότερα</li>
            <li>Carrier : BOX NOW</li>
            <li>
              Επιλέξτε το πεδίο “Create Shipments according to products quantity” μόνο εφόσον η
              παραγγελία έχει ένα προϊόν με περισσότερο του ενός τεμάχιου τα οποία αποτελούν το κάθε
              ένα, από ένα δέμα.
            </li>
            <li>
              Warehouse: το ανάλογο pickup point από το οποίο η BOX NOW θα παραλάβει το/δεματα.
            </li>
            <li>
              Επιλέξτε το πεδίο “Create Voucher “ εφόσον θέλετε να δημιουργησετε άμεσα το/τα
              voucher.
            </li>
            <li>
              Συμπληρωστε το πεδίο Comments εφόσον στις ρυθμίσεις έχετε επιλέξει να εμφανιζονται στο
              voucher οι σημειώσεις της Αποστολής.
            </li>
          </ul>
          <h4 className='mt-5' id='2'>
            ΔΗΜΙΟΥΡΓΙΑ VOUCHER
          </h4>
          <p className='mt-3'>
            Πατώντας Δημιουργία , θα δείτε την αποστολή για την παραγγελία σας.
          </p>

          <img className='mt-5 mb-5' src={cskart6} />

          <p>Επιλέγοντας από τις ρυθμίσεις στην αποστολή το BOX NOW: Create Voucher</p>

          <p>
            Θα δημιουργηθεί το voucher ενημερωνοντας σας στο πράσινο popup ενω θα δείτε τον αριθμό
            του και στήλη voucher .
          </p>

          <img className='mt-5 mb-5' src={cskart7} />

          <p>
            Ο αριθμός Voucher αποτελει λινκ προς τη σελίδα tracking της αποστολής μέσα στο κατάστημα
            σας
          </p>

          <img className='mt-5 mb-5' src={cskart8} />

          <p id='3'>
            Απο τις ρυθμίσεις της Αποστολής πλεον μπορείτε να τυπώσετε το voucher αλλά και να το
            σβήσετε, ενώ μπορείτε να ενημέρωσετε τον πελάτη με ένα mail για τον αριθμό Voucher
          </p>

          <img className='mt-5 mb-5' src={cskart9} />

          <p>
            Μέσα από την παραγγελία έχετε την δυνατότητα εφόσον σας ενημερώσει η BOX NOW και εφόσον
            δεν έχετε δημιουργήσει Voucher για την παραγγελία, να αλλάξετε το pickup point του
            πελάτη, πατώντας διπλα απο το locker ID.
          </p>

          <img className='mt-5 mb-5' src={cskart10} />

          <p>
            Συμπληρώστε το νεό Locker ID που θα σας δώσει η BOX NOW και αποθηκέυστε την παραγγελία.
          </p>

          <p>
            Συμπληρώστε το νεό Locker ID που θα σας δώσει η BOX NOW και αποθηκέυστε την παραγγελία.
            Η περιγραφή του νέου Delivery Point θα αλλάξει ενω ένα mail θα αποσταλεί και στον πελάτη
            σας για την αλλαγη αυτή.
          </p>

          <p className='bolder mt-5'>Σημειώσεις:</p>
          <ol>
            <li>Έλεγχος του προσθέτου με τα stage API keys.</li>
            <li>
              Επιλογή θυρίδας Stage περιβάλλοντος: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address:
              IEPA OΔOΣ 116, 10447
            </li>
            <li>
              Όταν ολοκληρώνεται μία νέα παραγγελία θα σας αποστέλλουμε αυτόματα ένα PDF αρχείο με
              την ετικέτα αποστολής.
            </li>
          </ol>

          <p className='bolder mt-5' id='4'>
            Βοήθεια
          </p>
          <p>
            Σε περίπτωση ερωτήσεων αναφορικά με τις λύσεις και τον τρόπο υλοποίησης του προσθέτου
            μας, παρακαλώ απευθυνθείτε στην διεύθυνση ηλεκτρονικού ταχυδρομείου:{' '}
            <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
