import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  ClickAwayListener,
  Container,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import BoxNowContainer from "components/BoxNowContainer";
import StepsForConnection from "components/StepsForConnection";
import { onBoardingFormSelector } from "features/onBoardingForm/onBoardingFormSlice";
import React, { useState } from "react";
import useTranslation from "translations/useTranslation";
import { colors } from "../theme/theme";
import { Styles as StylesFromMainPage } from "./MainPage";
import { useAppSelector } from "app/hooks";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const CopyToClipBoardButton = (props: { value: string }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const copyToClipBoard = (value: string) => {
    navigator.clipboard.writeText(value);
    handleTooltipOpen();
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title="Copied to clipboard"
        placement="right"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={open}
      >
        <IconButton onClick={() => copyToClipBoard(props.value)}>
          <ContentPasteIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export const FormCompleted: React.FC = () => {
  const { t } = useTranslation();
  const { stage, prod } = useAppSelector(onBoardingFormSelector);
  // const { stage, prod } = {
  //   stage: {
  //     message: "Keys generated for Stage",
  //     keys: {
  //       clientId: "bbc04baa-d73e-417f-923e-0eeaf46362fa",
  //       clientSecret:
  //         "1a9450fd4fa673c7b8ba1e911800478130868e5234084f6be4c3d408632e49ac",
  //     },
  //     partnerId: "1912",
  //   },
  //   prod: {
  //     message: "Keys generated for Prod",
  //     keys: {
  //       clientId: "72820452-6483-4fc1-86b8-3096cac54696",
  //       clientSecret:
  //         "08c1d24a9905bb26e23c32368fbae884a185065db452aa55171efe08a1a819a0",
  //     },
  //     partnerId: "2817",
  //   },
  // };

  const Styles = withTheme(styled.div`
    .formContainer {
      background-color: red;
    }

    .button__toForm {
      width: 280px;
      height: 60px;
      border-radius: 0px;
      margin-top: 1em;
      background-color: rgb(68, 214, 45);
      border: none;
    }
    .button__toForm:hover {
      cursor: pointer;
    }
    .next-typo {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};
      font-weight: 700;
      font-size: 1.25rem;
      color: white;
    }
    .main-title-typo {
      font-family: Rubik, sans-serif;
      font-weight: 500;
      font-size: 1.75rem;
      width: 100%;
      margin-top: 0px;
      color: rgb(38, 5, 99);
    }
    .span-title-typo {
      font-size: 1.25rem;
      font-weight: 500;
      color: rgb(38, 5, 99);
      font-family: Rubik, sans-serif;
    }

    .under-main-title-margin {
      font-family: Rubik, sans-serif;
      font-weight: 500;
      font-size: 1.2rem;
      color: rgb(125, 121, 134);
      margin-top: 1em;
      text-align: left;
      margin-left: 1em;
    }
    .paper-formC {
      margin-top: 2rem;
      padding: 8px;
      border-radius: 0;
    }

    .disclaimer-container {
      display: flex;
    }

    .disclaimer {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};

      margin-top: 2em;
      color: ${colors.secondary};
      font-size: 1.4rem;
    }

    .response-field {
      word-break: break-all;
    }
  `);
  return (
    <BoxNowContainer>
      <Styles>
        <Container>
          <Paper className="paper-formC">
            <Typography className="main-title-typo">{stage.message}</Typography>
            <Typography className="under-main-title-margin">
              <span className="span-title-typo">Client ID:</span>{" "}
              <span className="response-field">{stage.keys.clientId}</span>
              <CopyToClipBoardButton value={stage.keys.clientId} />
            </Typography>
            <Typography className="under-main-title-margin">
              <span className="span-title-typo">Client Secret:</span>{" "}
              <span className="response-field">{stage.keys.clientSecret}</span>
              <CopyToClipBoardButton value={stage.keys.clientSecret} />
            </Typography>
            <Typography className="under-main-title-margin">
              <span className="span-title-typo">Partner ID:</span>{" "}
              <span className="response-field">{stage.partnerId}</span>
              <CopyToClipBoardButton value={stage.partnerId} />
            </Typography>
          </Paper>
          <Paper className="paper-formC">
            <Typography className="main-title-typo">{prod.message}</Typography>
            <Typography className="under-main-title-margin">
              <span className="span-title-typo">Client ID:</span>{" "}
              <span className="response-field">{prod.keys.clientId}</span>
              <CopyToClipBoardButton value={prod.keys.clientId} />
            </Typography>
            <Typography className="under-main-title-margin">
              <span className="span-title-typo">Client Secret:</span>{" "}
              <span className="response-field">{prod.keys.clientSecret}</span>
              <CopyToClipBoardButton value={prod.keys.clientSecret} />
            </Typography>
            <Typography className="under-main-title-margin">
              <span className="span-title-typo">Partner ID:</span>{" "}
              <span className="response-field">{prod.partnerId}</span>
              <CopyToClipBoardButton value={prod.partnerId} />{" "}
            </Typography>
          </Paper>
        </Container>
        <Container className="disclaimer-container">
          <Typography className="disclaimer">
            {t("onboard.keys.disclaimer")}
          </Typography>
        </Container>
        <StylesFromMainPage>
          <StepsForConnection />
        </StylesFromMainPage>
      </Styles>
    </BoxNowContainer>
  );
};
