import React from "react";
import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Container, Theme, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { p2pSelector } from "../../features/p2pSlice";
import useTranslation from "../../translations/useTranslation";
import { colors } from "../../theme/theme";
import footer from "../../assets/footer.png";

const Styles = withTheme(styled.div`
  background-color: #51b931;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;

  .footer-row {
    padding: 0;
  }

  .first-div-column {
    display: flex;
    justify-content: space-between;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      flex-direction: column-reverse;
      align-items: center;
    }

    .form-typo {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};
      font-weight: 400;
      font-size: 1rem;
      color: ${colors.secondary};

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        text-align: center;
      }
    }

    .form-typo-center {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.8rem;

      color: ${colors.secondary};

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        text-align: center;
      }

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xs")} {
        color: ${colors.secondary};
        font-size: 0.6rem;
      }
    }

    .ad {
      display: flex;
      flex-direction: column;
      margin-left: 1em;

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        align-items: center;
        margin-left: 10px;
      }
    }

    .last-row-first {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        width: 80%;
        justify-content: center;
        margin-top: 1em;
      }
    }

    .last-row-second {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        width: 80%;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }
  }
`);

const SecondFooter: React.FC = () => {
  const { footerOptions } = useAppSelector(p2pSelector);

  const { t } = useTranslation();

  return (
    <Styles>
      <Container className="footer-row">
        <div className="first-div-column">
          <div className="last-row-first">
            <img src={footer} alt="BoxNow Logo" />
            <div className="ad">
              <Typography className="form-typo-center">
                {t("address.hq")}
              </Typography>
              <Typography className="form-typo-center">
                {t("commercial.registry")}
              </Typography>
            </div>
          </div>
          <div className="last-row-second">
            {footerOptions
              .filter(
                (item) =>
                  item.group === "Useful links" ||
                  item.group === "Χρήσιμοι Σύνδεσμοι"
              )
              .map((items) => (
                <Typography
                  key={items.title}
                  component="a"
                  gutterBottom
                  href={items.link}
                  target="_blank"
                  className="form-typo-center"
                >
                  {items.title}
                </Typography>
              ))}
          </div>
        </div>
      </Container>
    </Styles>
  );
};

export default SecondFooter;
