import { Fragment } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/system";
import { theme } from "./theme/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Form from "./pages/Form";
import { Eshops } from "./pages/Eshops";
import "bootstrap/dist/css/bootstrap.min.css";
import MainPage from "pages/MainPage";
import StartingPage from "pages/StartingPage";
import { Guide } from "pages/Guide";
import { FormCompleted } from "pages/FormCompleted";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Fragment>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<StartingPage />} />
              <Route path="/en/" element={<StartingPage />} />
              <Route path="/form" element={<Form />} />
              <Route path="/en/form" element={<Form />} />
              <Route path="/eshops" element={<Eshops />} />
              <Route path="/en/eshops" element={<Eshops />} />
              <Route path="/form-completed" element={<FormCompleted />} />
              <Route path="/en/form-completed" element={<FormCompleted />} />
              <Route path="/guide" element={<Guide />} />
              <Route path="/en/guide" element={<FormCompleted />} />
              {/* <Route path='/eshops/magento1' element={<MainPage />}></Route> */}
              <Route path="/eshops/opencart" element={<MainPage />} />
              <Route path="/eshops/cscart" element={<MainPage />} />
              <Route path="/eshops/prestashop" element={<MainPage />} />
              <Route path="/eshops/woocommerce" element={<MainPage />} />
              <Route path="/eshops/shopify" element={<MainPage />} />
              <Route path="/eshops/magento2" element={<MainPage />} />
              <Route path="/tailor-made" element={<MainPage />} />
              <Route path="/partner-portal" element={<MainPage />} />
              <Route path="/eshops/magento1" element={<MainPage />} />
              {/* <Route path='/choose-connection' element={<MainPage />}></Route> */}
              {/* <Route path='*' element={<Navigate to='/form' />} /> */}
            </Routes>
          </BrowserRouter>
        </Fragment>
      </ThemeProvider>
    </div>
  );
}

export default App;
